import {useSelector} from "react-redux";
import {useState} from "react";
import Web3 from "web3";
import ierc20_abi from "../abis/IERC20.json";
import multirewards_abi from "../abis/MultiRewards.json";
import {toWei} from "../utils/format";
import {
    Avatar, Button,
    FormControl,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField
} from "@mui/material";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import PercentageArc from "./PercentageArc";
import AnimatedPriceWidget from "./AnimatedPriceWidget";
import {NetworkButton} from "./Coineus";
import {CHAIN_IDS} from "../constants";
import CoineusLoading from "./CoineusLoading";

const updateTimeout = 10000;

export default function RewardDetails(props) {

    const {wallet} = useSelector(state => state.coineus);
    const {reward_token, network, farmContract} = props;
    const [amount, setAmount] = useState("");
    const [pendingTransaction, setPendingTransaction] = useState(false);

    const {
        rewardsRemaining,
        rewardsForDuration,
        potentialEarnings,
        earned,
        rewardData
    } = reward_token.data;

    const rewardPriceInUSD = wallet.prices[network]?.[reward_token.contract_address]?.inUSD;

    const addRewards = () => {
        const web3 = new Web3(window.provider);
        const token_contract = new web3.eth.Contract(ierc20_abi, reward_token.contract_address);
        const multirewards_contract = new web3.eth.Contract(multirewards_abi, farmContract);

        token_contract
            .methods
            .allowance(wallet.address, farmContract)
            .call((err, resp) => {

                if (err) return;

                if (parseInt(resp) < parseInt(toWei(amount, reward_token.decimals))) {
                    token_contract
                        .methods
                        .approve(farmContract, toWei(amount, reward_token.decimals))
                        .send({from: wallet.address})
                        .once('transactionHash', function (tx) {
                            setPendingTransaction(true)
                        })
                        .on('error', function (error) {
                            setPendingTransaction(false)
                        })
                        .then(function (receipt) {
                            setPendingTransaction(false)
                            add()
                        });
                } else {
                    add()
                }
            })

        const add = () => {
            multirewards_contract
                .methods
                .notifyRewardAmount(reward_token.contract_address, toWei(amount, reward_token.decimals))
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPendingTransaction(true)
                })
                .on('error', function (error) {
                    setPendingTransaction(false)
                })
                .then(function (receipt) {
                    setPendingTransaction(false);
                });
        }

    }

    if(earned <= 0 && wallet.address.toLowerCase() !== rewardData.rewardsDistributor?.toLowerCase()){
        return <></>;
    }

    return <Grid item xs={12}>
        <Paper>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={`https://coineus.app/assets/tokens/${network}/${reward_token.contract_address}/logo.png`}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={rewardsRemaining ? CoineusCryptoFormat(rewardsForDuration) + ` ${reward_token.symbol}` : 0 + ` ${reward_token.symbol}`}
                        secondary={rewardsRemaining ? CoineusUSDFormat(rewardsForDuration * rewardPriceInUSD) : CoineusUSDFormat(0)}
                    />
                    <div style={{marginTop: -15}}>
                        <PercentageArc
                            value={rewardsRemaining}
                            total={rewardsForDuration}
                            height={50}
                            label="% REMAINING"
                        />
                    </div>
                </ListItem>
                {
                    potentialEarnings > 0 && <ListItem>
                        <ListItemText
                            primary={<>
                                <AnimatedPriceWidget fixed={8} time={updateTimeout} price={potentialEarnings} />
                            </>}
                            secondary="Potential Rewards"
                            primaryTypographyProps={{fontFamily: "monospace"}}
                        />
                        <ListItemText
                            primary={CoineusUSDFormat(potentialEarnings * rewardPriceInUSD)}
                            secondary="Value"
                            primaryTypographyProps={{textAlign: "right"}}
                            secondaryTypographyProps={{textAlign: "right"}}
                        />
                    </ListItem>
                }

                {
                    earned > 0 && <ListItem>
                        <ListItemText
                            primary={<>
                                <AnimatedPriceWidget fixed={8} time={updateTimeout} price={earned} />
                            </>}
                            primaryTypographyProps={{fontFamily: "monospace"}}
                            secondary="Earned Rewards"

                        />
                        <ListItemText
                            primary={CoineusUSDFormat(earned * rewardPriceInUSD)}
                            secondary="Value"
                            primaryTypographyProps={{textAlign: "right"}}
                            secondaryTypographyProps={{textAlign: "right"}}

                        />
                    </ListItem>
                }

                {
                    wallet.address?.toLowerCase() === rewardData.rewardsDistributor?.toLowerCase() && <ListItem>
                        <FormControl fullWidth style={{marginBottom: 20, marginTop: 20}}>
                            <TextField
                                autoComplete="off"
                                type="number"
                                value={amount}
                                placeholder="Enter Amount"
                                onChange={(ev) => {
                                    setAmount(ev.target.value)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <NetworkButton network={CHAIN_IDS[network.toUpperCase()]}>
                                            <Button fullWidth variant="contained"
                                                    disabled={!amount}
                                                    onClick={addRewards}>ADD</Button>
                                        </NetworkButton>
                                    </InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </ListItem>
                }
            </List>
        </Paper>
        <CoineusLoading open={pendingTransaction} label="Transaction Pending"/>
    </Grid>
}