export const CHAIN_IDS  = {
    ETH: 1,
    BNB: 56,
    FUSE: 122,
    AVAX: 43114
}

export const CHAIN_IDS_TO_NAMES = {
    [CHAIN_IDS.ETH]: 'eth',
    [CHAIN_IDS.BNB]: 'bnb',
    [CHAIN_IDS.FUSE]: 'fuse',
    [CHAIN_IDS.AVAX]: 'avax',
}

export const CHAIN_IDS_TO_DISPLAY_NAMES = {
    [CHAIN_IDS.ETH]: 'ETH',
    [CHAIN_IDS.BNB]: 'BNB',
    [CHAIN_IDS.FUSE]: 'FUSE',
    [CHAIN_IDS.AVAX]: 'AVAX',
}

export const CHAIN_IDS_TO_LOGO = {
    [CHAIN_IDS.ETH]: 'https://coineus.app/assets/tokens/eth/logo.png',
    [CHAIN_IDS.BNB]: 'https://coineus.app/assets/tokens/bnb/logo.png',
    [CHAIN_IDS.FUSE]: 'https://coineus.app/assets/tokens/fuse/logo.png',
    [CHAIN_IDS.AVAX]: 'https://coineus.app/assets/tokens/avax/logo.png',
}