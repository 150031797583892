import store from "../redux/store";
import Web3 from "web3";
import unipair_abi from "../abis/IUniswapV2Pair.json";
import uinpairV3_abi from "../abis/UniswapV3Pair.json";

import {makeBatchRequest} from "./promisify";
import {updatePrices, updateTotals} from "../redux/slices/coineusSlice";
import uni_pair_abi from "../abis/IUniswapV2Pair.json";

export default function getPairPrices() {
    const _store = store.getState();
    const {pairs, rpc} = _store.coineus;

    getFUSE(pairs, rpc.fuse);
    getAVAX(pairs, rpc.avax);
    getBNB(pairs, rpc.bnb);
    getETH(pairs, rpc.eth);
}

function getFUSE(pairs, rpc) {

    //FUSE
    const web3 = new Web3(rpc);
    const fusd_fuse = new web3.eth.Contract(unipair_abi, pairs.fUSDFUSEpair);
    const ceus_fuse = new web3.eth.Contract(unipair_abi, pairs.CEUSFUSEpair);
    const volt_fuse = new web3.eth.Contract(unipair_abi, pairs.VOLTFUSEpair);
    const eth_fuse = new web3.eth.Contract(unipair_abi, pairs.ETHFUSEPair);
    const bnb_fusd = new web3.eth.Contract(unipair_abi, pairs.BNBfUSDPair);
    const btc_fuse = new web3.eth.Contract(unipair_abi, pairs.BTCFUSEPair);
    const ceus_lit = new web3.eth.Contract(unipair_abi, pairs.CEUSLITPair);
    const gd_fuse = new web3.eth.Contract(unipair_abi, pairs.GDFUSEPair);

    const wfuse_dgnx = new web3.eth.Contract(uni_pair_abi, "0x040f4fE1fd47Fdaa3a239DF374cF8bdf85bd3F61") //FUSE/DGNX LP

    const ceus_lit_contract = new web3.eth.Contract(uni_pair_abi, "0x87440F829E7ED808599749630555080917c4b3D9") //CEUS/LIT LP
    const ceus_fuse_contract = new web3.eth.Contract(uni_pair_abi, "0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b") //CEUS/FUSE LP
    const fuse_lit_contract = new web3.eth.Contract(uni_pair_abi, "0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc") //CEUS/FUSE LP
    const ceus_bbks_contract = new web3.eth.Contract(uni_pair_abi, "0x1306d7342eDfe9c3686aD044E47D8b8Fa2D6a7f0") //CEUS/BBKS LP
    const lady_ceus_contract = new web3.eth.Contract(uni_pair_abi, pairs.LADYCEUSPair) //CEUS/BBKS LP
    const lady_foo_contract = new web3.eth.Contract(uni_pair_abi, "0xc2155Fc4Bb709d18641c65c8676109DE41EdF84A")
    const fvksrg_fuse_contract = new web3.eth.Contract(uni_pair_abi, "0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD") //FVKSRG/FUSE LP
    const fvksrg_ceus_contract = new web3.eth.Contract(uni_pair_abi, "0xB6850233c408Bbf58c3B2beC945394114cB08Fd0") //FVKSRG/CEUS LP
    const dgnx_wfuse_contract = new web3.eth.Contract(uni_pair_abi, "0x040f4fE1fd47Fdaa3a239DF374cF8bdf85bd3F61") //WFUSE/DGNX LP
    const lady_wfuse_contract = new web3.eth.Contract(uni_pair_abi, "0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586") //WFUSE/LADY LP
    const foo_wfuse_contract = new web3.eth.Contract(uni_pair_abi, "0x4f47a2c360266230795CFBca80b7C89EdA52Cd52") //WFUSE/FOO LP

    const calls = [
        fusd_fuse.methods.getReserves().call,
        ceus_fuse.methods.getReserves().call,
        volt_fuse.methods.getReserves().call,
        eth_fuse.methods.getReserves().call,
        bnb_fusd.methods.getReserves().call,
        btc_fuse.methods.getReserves().call,
        ceus_lit.methods.getReserves().call,
        gd_fuse.methods.getReserves().call,
        wfuse_dgnx.methods.getReserves().call,

        ceus_lit_contract.methods.getReserves().call,
        ceus_lit_contract.methods.totalSupply().call,

        ceus_fuse_contract.methods.getReserves().call,
        ceus_fuse_contract.methods.totalSupply().call,

        fuse_lit_contract.methods.getReserves().call,
        fuse_lit_contract.methods.totalSupply().call,

        ceus_bbks_contract.methods.getReserves().call,
        ceus_bbks_contract.methods.totalSupply().call,

        lady_ceus_contract.methods.getReserves().call,
        lady_ceus_contract.methods.totalSupply().call,

        lady_foo_contract.methods.getReserves().call,
        lady_foo_contract.methods.totalSupply().call,

        fvksrg_fuse_contract.methods.getReserves().call,
        fvksrg_fuse_contract.methods.totalSupply().call,

        fvksrg_ceus_contract.methods.getReserves().call,
        fvksrg_ceus_contract.methods.totalSupply().call,

        dgnx_wfuse_contract.methods.getReserves().call,
        dgnx_wfuse_contract.methods.totalSupply().call,

        lady_wfuse_contract.methods.getReserves().call,
        lady_wfuse_contract.methods.totalSupply().call,

        foo_wfuse_contract.methods.getReserves().call,
        foo_wfuse_contract.methods.totalSupply().call,
    ]

    makeBatchRequest(web3, calls, "get_prices getFUSE()")
        .then(resp => {
            const [
                fusd_fuse_reserves,
                ceus_fuse_reserves,
                volt_fuse_reserves,
                eth_fuse_reserves,
                bnb_fusd_reserves,
                btc_fuse_reserves,
                ceus_lit_reserves,
                gd_fuse_reserves,
                wfuse_dgnx_reserves,

                ceus_lit_lp_reserves,
                ceus_lit_lp_totalSupply,

                ceus_wfuse_lp_reserves,
                ceus_wfuse_lp_totalSupply,

                fuse_lit_reserves,
                fuse_lit_totalSupply,

                ceus_bbks_reserves,
                ceus_bbks_totalSupply,

                lady_ceus_reserves,
                ceus_lady_totalSupply,

                lady_foo_reserves,
                lady_foo_totalSupply,

                fvksrg_fuse_reserves,
                fvksrg_fuse_totalSupply,

                fvksrg_ceus_reserves,
                fvksrg_ceus_totalSupply,

                dgnx_wfuse_reserves,
                dgnx_wfuse_totalSupply,

                lady_wfuse_reserves,
                lady_wfuse_totalSupply,

                foo_wfuse_reserves,
                foo_wfuse_totalSupply
            ] = resp

            //FUSE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: 'native',
                price: {
                    inUSD: fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inFuse: 1
                }
            }));

            //WFUSE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',
                price: {
                    inUSD: fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inFuse: 1
                }
            }));

            //FUSEONE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xadF951967082452c46eA853158e0838F0EB6fee5',
                price: {
                    inUSD: fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inFuse: 1
                }
            }));

            //FUSD
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xCE86a1cf3cFf48139598De6bf9B1dF2E0f79F86F',
                price: {
                    inUSD: (fusd_fuse_reserves.reserve0/fusd_fuse_reserves.reserve1) * (fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0),
                    inFuse: fusd_fuse_reserves.reserve0/fusd_fuse_reserves.reserve1
                }
            }));

            //CEUS
            const ceus_price_usd = ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_price_usd
                }
            }));

            //CEUS.e
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x31Db8DC955a281d2fefAB5709d1Dfd9603679222',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_price_usd
                }
            }));

            //CEUSONE
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xE27431FACb1F093BA164c9F86B3f484b39ad54CC',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_price_usd
                }
            }));

            //LIT
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: ceus_lit_reserves.reserve0/ceus_lit_reserves.reserve1 * (ceus_price_usd),
                    inBase: ceus_lit_reserves.reserve0/ceus_lit_reserves.reserve1
                }
            }));

            //VOLT
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4',
                price: {
                    inFuse: volt_fuse_reserves.reserve0/volt_fuse_reserves.reserve1,
                    inUSD: volt_fuse_reserves.reserve0/volt_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0
                }
            }));

            //ETH
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xa722c13135930332Eb3d749B2F0906559D2C5b99',
                price: {
                    inFuse: eth_fuse_reserves.reserve0/eth_fuse_reserves.reserve1,
                    inUSD: eth_fuse_reserves.reserve0/eth_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0
                }
            }));

            //BNB
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x6acb34b1Df86E254b544189Ec32Cf737e2482058',
                price: {
                    inFuse: bnb_fusd_reserves.reserve0/bnb_fusd_reserves.reserve1,
                    inUSD: bnb_fusd_reserves.reserve0/bnb_fusd_reserves.reserve1
                }
            }));

            //BTC
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x33284f95ccb7B948d9D352e1439561CF83d8d00d',
                price: {
                    inFuse: btc_fuse_reserves.reserve0/btc_fuse_reserves.reserve1,
                    inUSD: btc_fuse_reserves.reserve0/btc_fuse_reserves.reserve1/(10**10) * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0
                }
            }));

            //FVKSRG
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61',
                price: {
                    inFuse: fvksrg_fuse_reserves.reserve0/fvksrg_fuse_reserves.reserve1,
                    inUSD: fvksrg_fuse_reserves.reserve0/fvksrg_fuse_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inBase: fvksrg_ceus_reserves.reserve0/fvksrg_ceus_reserves.reserve1
                }
            }));

            //BBKS
            const ceus_bbks_0 = ceus_bbks_reserves.reserve0 / (10 ** 18);
            const ceus_bbks_1 = ceus_bbks_reserves.reserve1 / (10 ** 9);
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x6e18e35997aacBF017902e63ca789d74DF1b5A2b',
                price: {
                    inFuse: (ceus_bbks_0/ceus_bbks_1) * (ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1),
                    inUSD: (ceus_bbks_0/ceus_bbks_1) * (ceus_price_usd),
                    inBase: (ceus_bbks_0/ceus_bbks_1)
                }
            }));

            //Good Dollar
            const gd_fuse_r0 = gd_fuse_reserves.reserve0 / (10**18);
            const gd_fuse_r1 = gd_fuse_reserves.reserve1 / (10**2);
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x495d133B938596C9984d462F007B676bDc57eCEC',
                price: {
                    inFuse: gd_fuse_r0/gd_fuse_r1,
                    inUSD: gd_fuse_r0/gd_fuse_r1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0
                }
            }));

            //DGNX
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x13125265584D58cd45075e1efF47aEe083f4C1C6',
                price: {
                    inFuse: wfuse_dgnx_reserves.reserve0/wfuse_dgnx_reserves.reserve1,
                    inUSD: wfuse_dgnx_reserves.reserve0/wfuse_dgnx_reserves.reserve1 * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0,
                    inBase: wfuse_dgnx_reserves.reserve0/wfuse_dgnx_reserves.reserve1
                }
            }));

            const lady_ceus_reserve0 = lady_ceus_reserves.reserve0/(10**18);
            const lady_ceus_reserve1 = lady_ceus_reserves.reserve1/(10**9);
            const lady_ceus_price = lady_ceus_reserve0/lady_ceus_reserve1 * (ceus_price_usd);
            //Lady
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A',
                price: {
                    inFuse: ceus_fuse_reserves.reserve0/ceus_fuse_reserves.reserve1,
                    inUSD: lady_ceus_price,
                    inBase: lady_ceus_reserve0/lady_ceus_reserve1
                }
            }));

            //FOO
            const lady_foo_reserve0 = lady_foo_reserves.reserve0/(10**18);
            const lady_foo_reserve1 = lady_foo_reserves.reserve1/(10**9);
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9',
                price: {
                    inFuse: (lady_foo_reserve1/lady_foo_reserve0 * lady_ceus_price) / (fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0),
                    inUSD: lady_foo_reserve1/lady_foo_reserve0 * lady_ceus_price,
                    inBase: lady_foo_reserve1/lady_foo_reserve0
                }
            }));

            // CEUS/LIT LP
            let total = (ceus_lit_lp_reserves.reserve0 / (10**18)) * ceus_price_usd;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x87440F829E7ED808599749630555080917c4b3D9',
                price: {
                    // inUSD: 0
                    inUSD: (total*2) / (ceus_lit_lp_totalSupply / (10**18))
                }
            }));

            // FUSE/CEUS LP
            let total2 = (ceus_wfuse_lp_reserves.reserve0 / (10**18)) * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',
                price: {
                    // inUSD: 0
                    inUSD: (total2*2) / (ceus_wfuse_lp_totalSupply / (10**18))
                }
            }));

            // FUSE/CEUS LP
            let total3 = (fuse_lit_reserves.reserve0 / (10**18)) * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc',
                price: {
                    // inUSD: 0
                    inUSD: (total3*2) / (fuse_lit_totalSupply / (10**18))
                }
            }));

            // CEUS/BBKS LP
            let total4 = ceus_bbks_1 * ceus_bbks_0/ceus_bbks_1 * ceus_price_usd;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x1306d7342eDfe9c3686aD044E47D8b8Fa2D6a7f0',
                price: {
                    inUSD: (total4) / (ceus_bbks_totalSupply / (10**18))
                }
            }));

            // CEUS/lady LP
            let total5 = lady_ceus_reserve1 * lady_ceus_reserve0/lady_ceus_reserve1 * ceus_price_usd;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xcEAB8d0303924D3DB5B3a3eD178F055603398845',
                price: {
                    inUSD: (total5*2) / (ceus_lady_totalSupply / (10**18))
                }
            }));

            // FVKSRG/CEUS LP
            let total6 = (fvksrg_ceus_reserves.reserve0 / (10**18)) * ceus_price_usd;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xB6850233c408Bbf58c3B2beC945394114cB08Fd0',
                price: {
                    inUSD: (total6*2) / (fvksrg_ceus_totalSupply / (10**18))
                }
            }));

            // FVKSRG/FUSE LP
            let total7 = (fvksrg_fuse_reserves.reserve0 / (10**18)) * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD',
                price: {
                    inUSD: (total7*2) / (fvksrg_fuse_totalSupply / (10**18))
                }
            }));

            // DGNX/FUSE LP
            let total8 = (dgnx_wfuse_reserves.reserve0 / (10**18)) * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x040f4fE1fd47Fdaa3a239DF374cF8bdf85bd3F61',
                price: {
                    inUSD: (total8*2) / (dgnx_wfuse_totalSupply / (10**18))
                }
            }));

            // LADY/FUSE LP
            let total9 = (lady_wfuse_reserves.reserve0 / (10**18)) * (fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0);
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586',
                price: {
                    inUSD: (total9*2) / (lady_wfuse_totalSupply / (10**18))
                }
            }));

            // FOO/FUSE LP
            let total10 = (foo_wfuse_reserves.reserve0 / (10**18)) * fusd_fuse_reserves.reserve1/fusd_fuse_reserves.reserve0;
            store.dispatch(updatePrices({
                chain: 'fuse',
                contract_address: '0x4f47a2c360266230795CFBca80b7C89EdA52Cd52',
                price: {
                    inUSD: (total10*2) / (foo_wfuse_totalSupply / (10**18))
                }
            }));



            store.dispatch(updateTotals('fuse'));
        });
}

function getAVAX(pairs, rpc) {


    //AVAX
    const web3AVAX = new Web3(rpc);
    const usdc_avax = new web3AVAX.eth.Contract(unipair_abi, pairs.USDCAVAXpair);
    const dgnx_avax = new web3AVAX.eth.Contract(unipair_abi, pairs.DGNXAVAXpair);

    const callsAVAX = [
        usdc_avax.methods.getReserves().call,
        dgnx_avax.methods.getReserves().call,
    ]

    makeBatchRequest(web3AVAX, callsAVAX, "get_prices getAVAX()")
        .then(resp => {
            const [
                usdc_avax_reserves,
                dgnx_avax_reserves,
            ] = resp

            const avax_price = usdc_avax_reserves.reserve1/usdc_avax_reserves.reserve0 * (10**12)

            //AVAX
            store.dispatch(updatePrices({
                chain: 'avax',
                contract_address: 'native',
                price: {
                    inUSD: avax_price,
                    inAVAX: 1
                }
            }));

            //USDC
            store.dispatch(updatePrices({
                chain: 'avax',
                contract_address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
                price: {
                    inUSD: 1,
                    inAVAX: usdc_avax_reserves.reserve0/usdc_avax_reserves.reserve1 / (10**12)
                }
            }));

            //DGNX
            store.dispatch(updatePrices({
                chain: 'avax',
                contract_address: '0x51e48670098173025C477D9AA3f0efF7BF9f7812',
                price: {
                    inAVAX: dgnx_avax_reserves.reserve1/dgnx_avax_reserves.reserve0 || 0,
                    inUSD: dgnx_avax_reserves.reserve1/dgnx_avax_reserves.reserve0 * avax_price || 0
                }
            }));

            store.dispatch(updateTotals('avax'));

        });
}

function getBNB(pairs, rpc) {

    //BSC
    const web3BSC = new Web3(rpc);
    const bnb_busd = new web3BSC.eth.Contract(unipair_abi, pairs.BNBBUSDpair);
    const fuse_bnb = new web3BSC.eth.Contract(unipair_abi, pairs.FUSEBNBPair);

    const callsBNB = [
        bnb_busd.methods.getReserves().call,
        fuse_bnb.methods.getReserves().call,
    ]

    makeBatchRequest(web3BSC, callsBNB, "get_prices getBNB()")
        .then(resp => {
            const [
                bnb_busd_reserves,
                fuse_bnb_reserves,
            ] = resp

            const bnb_price = bnb_busd_reserves.reserve0/bnb_busd_reserves.reserve1

            //BNB
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: 'native',
                price: {
                    inUSD: bnb_price,
                    inBNB: 1
                }
            }));

            //FUSE
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',
                price: {
                    inUSD: (fuse_bnb_reserves.reserve1/fuse_bnb_reserves.reserve0) * bnb_price,
                    inBNB: 1
                }
            }));

            //BUSD
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
                price: {
                    inUSD: 1
                }
            }));

            //USDC
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
                price: {
                    inUSD: 1
                }
            }));

            //USDT
            store.dispatch(updatePrices({
                chain: 'bnb',
                contract_address: '0x55d398326f99059fF775485246999027B3197955',
                price: {
                    inUSD: 1
                }
            }));

            store.dispatch(updateTotals('bnb'));
        });

}

function getETH(pairs, rpc) {


    //ETH
    const web3ETH = new Web3(rpc);
    const eth_usdc = new web3ETH.eth.Contract(unipair_abi, pairs.ETHUSDCPair);
    const ceus_eth = new web3ETH.eth.Contract(uinpairV3_abi, pairs.ETHCEUSPair);
    const dgnx_eth = new web3ETH.eth.Contract(unipair_abi, '0x2024094C991608Cba556bA7Be16b604e232f220A');

    const callsETH = [
        eth_usdc.methods.getReserves().call,
        ceus_eth.methods.slot0().call,
        dgnx_eth.methods.getReserves().call,
    ]

    makeBatchRequest(web3ETH, callsETH, "get_prices getETH()")
        .then(resp => {

            const [
                eth_usdc_reserves,
                ceus_eth_slot0,
                dgnx_eth_reserves,
            ] = resp

            const eth_price = eth_usdc_reserves.reserve0/eth_usdc_reserves.reserve1*(10**12)

            //ETH
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: 'native',
                price: {
                    inUSD: eth_price,
                    inETH: 1
                }
            }));

            //USDC
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                price: {
                    inUSD: 1
                }
            }));

            //USDT
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
                price: {
                    inUSD: 1
                }
            }));

            //CEUS
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0x496443ea0c46Ea3aF7025F071EA3FBd5F1f1D560',
                price: {
                    inUSD: ((ceus_eth_slot0.sqrtPriceX96 / (2 ** 96)) ** 2) * eth_price,
                    inETH: ((ceus_eth_slot0.sqrtPriceX96 / (2 ** 96)) ** 2)
                }
            }));

            //DGNX
            store.dispatch(updatePrices({
                chain: 'eth',
                contract_address: '0x0000000000300dd8B0230efcfEf136eCdF6ABCDE',
                price: {
                    inUSD: (dgnx_eth_reserves.reserve1 / dgnx_eth_reserves.reserve0) * eth_price,
                    inETH: dgnx_eth_reserves.reserve1 / dgnx_eth_reserves.reserve0
                }
            }));

            store.dispatch(updateTotals('eth'));

        })
        .catch(console.log);

}

