import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AssetBackingOutlined = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M1.6 9v8c0 .55.42 1 .93 1H3V8h-.47c-.52 0-.93.45-.93 1Zm19.87-1H21v10h.47c.52 0 .93-.45.93-1V9c0-.55-.42-1-.93-1Zm-2.65-2.59c-.26-.26-.62-.41-.99-.41H17v-.83c0-.37-.15-.73-.41-.99l-.77-.77c-.26-.26-.62-.41-.99-.41H9.16c-.37 0-.73.15-.99.41l-.76.76c-.26.26-.41.62-.41.99v.83h-.83c-.37 0-.73.15-.99.41l-.77.77c-.26.26-.41.62-.41.99v11.57c0 .56.33 1.07.85 1.29l6.6 2.83c.18.07.36.11.55.11.19 0 .38-.04.55-.11l6.6-2.83c.52-.22.85-.73.85-1.29V7.17c0-.37-.15-.73-.41-.99l-.77-.77ZM9 4.41 9.41 4h5.17l.41.41V5h-6v-.59H9Zm9 13.93-6 2.57-6-2.57V7.41L6.41 7h11.17l.41.41v10.93H18Z"/>
                <path d="M15.6 12h-.7L10 15.89l3.28.43L11.4 19h.7l4.9-3.89-3.28-.43L15.6 12Z"/>
            </g>
        </SvgIcon>
    );
}


const AssetBackingFilled = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M1.6 9v8c0 .55.42 1 .93 1H3V8h-.47c-.52 0-.93.45-.93 1Zm19.87-1H21v10h.47c.52 0 .93-.45.93-1V9c0-.55-.42-1-.93-1Zm-2.65-2.59c-.26-.26-.62-.41-.99-.41H17v-.83c0-.37-.15-.73-.41-.99l-.77-.77c-.26-.26-.62-.41-.99-.41H9.16c-.37 0-.73.15-.99.41l-.76.76c-.26.26-.41.62-.41.99v.83h-.83c-.37 0-.73.15-.99.41l-.77.77c-.26.26-.41.62-.41.99v11.57c0 .56.33 1.07.85 1.29l6.6 2.83c.18.07.36.11.55.11.19 0 .38-.04.55-.11l6.6-2.83c.52-.22.85-.73.85-1.29V7.17c0-.37-.15-.73-.41-.99l-.77-.77ZM9 4.41 9.41 4h5.17l.41.41V5h-6v-.59H9ZM12.1 19h-.7l1.88-2.68-3.28-.43L14.9 12h.7l-1.88 2.68 3.28.43L12.1 19Z"/>
        </SvgIcon>
    );
}

const AssetBackingTextOutlined = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M1.6 9v8c0 .55.42 1 .93 1H3V8h-.47c-.52 0-.93.45-.93 1Zm19.87-1H21v10h.47c.52 0 .93-.45.93-1V9c0-.55-.42-1-.93-1Zm-2.65-2.59c-.26-.26-.62-.41-.99-.41H17v-.83c0-.37-.15-.73-.41-.99l-.77-.77c-.26-.26-.62-.41-.99-.41H9.16c-.37 0-.73.15-.99.41l-.76.76c-.26.26-.41.62-.41.99v.83h-.83c-.37 0-.72.14-.99.41l-.77.77c-.26.26-.41.62-.41.99v11.57c0 .56.33 1.07.85 1.29l6.6 2.83c.17.07.36.11.55.11.19 0 .38-.04.55-.11l6.6-2.83c.52-.22.85-.73.85-1.29V7.17c0-.37-.15-.73-.41-.99l-.77-.77ZM9 4.42l.41-.41h5.17l.41.41v.59h-6v-.59H9Zm9 13.93-6 2.57-6-2.57V7.41L6.41 7h11.17l.41.41v10.93l.01.01Z"/>
                <path d="M16.37 10.06h-4v6h4c.55 0 1-.45 1-1v-1c0-.55-.45-1-1-1 .55 0 1-.45 1-1v-1c0-.55-.45-1-1-1Zm-.5 4.5h-2v-.75h2v.75Zm0-2.25h-2v-.75h2v.75Zm-5.25-2.25h-3c-.55 0-1 .45-1 1v5h1.5v-1.5h2v1.5h1.5v-5c0-.55-.45-1-1-1Zm-.5 3h-2v-1.5h2v1.5Z"/>
            </g>
        </SvgIcon>
    );
}

const AssetBackingTextFilled = (props) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M1.6 9v8c0 .55.42 1 .93 1H3V8h-.47c-.52 0-.93.45-.93 1Zm19.87-1H21v10h.47c.52 0 .93-.45.93-1V9c0-.55-.42-1-.93-1Zm-5.59 3.56h-2v.75h2v-.75Zm0 2.25h-2v.75h2v-.75Zm-5.75-2.25h-2v1.5h2v-1.5Z"/>
                <path d="M18.82 5.41c-.26-.26-.62-.41-.99-.41H17v-.83c0-.37-.15-.73-.41-.99l-.77-.77c-.26-.26-.62-.41-.99-.41H9.16c-.37 0-.72.15-.99.41l-.76.76c-.26.26-.41.62-.41.99v.83h-.83c-.37 0-.72.14-.99.41l-.77.77c-.26.26-.41.62-.41.99v11.57c0 .56.33 1.07.85 1.29l6.6 2.83c.17.07.36.11.55.11.19 0 .38-.04.55-.11l6.6-2.83c.52-.22.85-.73.85-1.29V7.17c0-.37-.15-.73-.41-.99l-.77-.77Zm-7.2 10.66h-1.5v-1.5h-2v1.5h-1.5v-5c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v5ZM8.99 5.01v-.59l.41-.41h5.17l.41.41v.59h-6 .01Zm8.38 7.06c0 .55-.45 1-1 1 .55 0 1 .45 1 1v1c0 .55-.45 1-1 1h-4v-6h4c.55 0 1 .45 1 1v1Z"/>
            </g>
        </SvgIcon>
    );
}

export {
    AssetBackingOutlined,
    AssetBackingFilled,
    AssetBackingTextOutlined,
    AssetBackingTextFilled
};