import {
    AppBar,
    Avatar,
    Button,
    Container,
    CssBaseline,
    Drawer,
    Grid,
    IconButton,
    ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import theme from './theme';
import logo from './images/logo.svg';
import {
    HashRouter as Router, Routes, Route, Link as RouterLink
} from "react-router-dom";

import HomePage from "./views/HomePage";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Web3 from "web3";
import useWeb3Modal from "./hooks/useWeb3Modal";
import {
    resetWallet, updateWalletAddress, updateWalletChain, updateWalletProviderType,
} from "./redux/slices/coineusSlice";
import getPairPrices from "./utils/get_prices";
import Staking from "./views/Staking";
import CoineusPortfolio from "./views/CoineusPortfolio";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useDexScreener from "./hooks/useDexScreener";
import Stats from "./views/Stats";
import getUserBalances from "./utils/get_balances";
import EasyBridge from "./views/EasyBridge";
import Admin from "./views/Admin";
import Swap from "./views/Swap";
import Validator from "./views/validators/Validator";
import VoltageLiquidity from "./views/VoltageLiquidity";
import CEUSONEFaucet from "./views/Faucets/CEUSONEFacuet";
import ChainSelector from "./components/ChainSelector";
import Farm from "./views/Farm";
import Faucets from "./views/Faucets/Faucets";
import CoineusMenu from "./components/CoineusMenu";
import FarmDetails from "./views/FarmDetails";
import EarnDetails from "./views/EarnDetails";
import Earn from "./views/Earn";
import LiquidityDashboard from "./views/LiquidityDashboard";
import Tasks from "./views/Tasks";

export default function Coineus() {

    const dispatch = useDispatch();
    const {wallet} = useSelector(state => state.coineus);

    useDexScreener();

    const [menuOpen, setMenuOpen] = useState(false);
    const [web3Modal, setWeb3Modal] = useState(null);
    const web3ModalProvider = useWeb3Modal();

    useEffect(() => {
        if (window.ethereum) {
            connectProvider(window.ethereum)
        }
        changeWeb3Modal();
    }, []);

    async function changeWeb3Modal() {
        const web3mod = await web3ModalProvider.getWeb3Modal();
        if (web3mod.providerController.cachedProvider) {
            const provider = await web3mod.connect()
            await connectProvider(provider)
        }
        setWeb3Modal(web3mod);
    }

    async function connectWallet() {
        const provider = await web3Modal.connect();
        await connectProvider(provider)
    }

    const connectProvider = async (provider) => {
        window.provider = provider
        const web3 = new Web3(provider);
        subscribeProvider(provider)
        const accounts = await web3.eth.getAccounts();
        if (accounts && accounts.length) {
            //set wallet address
            //
            dispatch(updateWalletAddress(accounts[0]))
        }
        const chainId = parseInt(await web3.eth.net.getId())
        dispatch(updateWalletChain(chainId));
        dispatch(updateWalletProviderType(!!provider.bridge ? 'walletconnect' : 'injected'))
    }

    const subscribeProvider = (provider) => {
        provider.on("close", () => {
            clearWallet();
        });

        provider.on("accountsChanged", async (acc) => {
            if (window.provider) {
                dispatch(updateWalletAddress(acc[0]))
            }
        });

        provider.on("chainChanged", async (networkId) => {
            if (window.provider) {
                const chainId = parseInt(networkId)
                dispatch(updateWalletChain(chainId));
            }
        });
    }

    const clearWallet = async () => {
        const web3 = new Web3(window.provider);
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        dispatch(resetWallet())
        await web3Modal.clearCachedProvider();
        window.provider = null;
    }

    //updates pricing
    useEffect(() => {
        getPairPrices();
        const interval = setInterval(getPairPrices, 60000)
        return () => clearInterval(interval);
    }, [wallet.address]);

    //updates balances
    useEffect(() => {
        getUserBalances(wallet.address);
        const interval = setInterval(() => getUserBalances(wallet.address), 60000)
        return () => clearInterval(interval);
    }, [wallet.address]);

    return (<ThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
            <AppBar enableColorOnDark>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setMenuOpen(true)}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <RouterLink
                        to={`/`}
                        style={{textDecoration: 'none', display: 'flex'}}
                    >
                        <Avatar src={logo} height={40}/>
                    </RouterLink>
                    <Typography variant="h5">Coineus</Typography>
                    <div style={{flexGrow: 1}}/>
                    <ChainSelector />
                    {wallet.address ? <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            clearWallet();
                        }}
                        style={{marginLeft:10}}
                    >0X...{wallet.address?.substr(-6)}</Button> : <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        onClick={() => connectWallet()}>Connect</Button>}
                </Toolbar>
            </AppBar>
            <Grid container style={{marginTop: 64}}>
                <Grid item xs={12}>
                    <Routes>

                        <Route
                            path={`/`}
                            exact={true}
                            element={<Container maxWidth="xs"><HomePage/></Container>}
                        />
                        <Route
                            path={`/staking`}
                            exact={true}
                            element={<Container maxWidth="xs"><Staking/></Container>}
                        />
                        <Route
                            path={`/portfolio`}
                            exact={true}
                            element={<Container maxWidth="xs"><CoineusPortfolio/></Container>}
                        />
                        <Route
                            path={`/stats`}
                            exact={true}
                            element={<Container maxWidth="xs"><Stats/></Container>}
                        />
                        <Route
                            path={`/easybridge`}
                            exact={true}
                            element={<Container maxWidth="xs"><EasyBridge/></Container>}
                        />
                        <Route
                            path={`/admin`}
                            exact={true}
                            element={<Container maxWidth="xs"><Admin/></Container>}
                        />
                        <Route
                            path={`/swap`}
                            exact={true}
                            element={<Container maxWidth="xs"><Swap/></Container>}
                        />

                        <Route
                            path={`/fuse-validators`}
                            exact={true}
                            element={<Validator/>}
                        />

                        <Route
                            path={`/voltage-liquidity`}
                            exact={true}
                            element={<Container maxWidth="xs"><VoltageLiquidity/></Container>}
                        />

                        <Route
                            path={`/faucet`}
                            exact={true}
                            element={<Container maxWidth="xs"><Faucets /></Container>}
                        />

                        <Route
                            path={`/faucet/ceusone`}
                            exact={true}
                            element={<Container maxWidth="xs"><CEUSONEFaucet /></Container>}
                        />

                        <Route
                            path={`/earn`}
                            exact={true}
                            element={<Container maxWidth="xs"><Earn /></Container>}
                        />

                        <Route
                            path={`/earn/:network/:contract_address`}
                            exact={true}
                            element={<Container maxWidth="xs"><EarnDetails /></Container>}
                        />

                        <Route
                            path={`/farm`}
                            exact={true}
                            element={<Container maxWidth="xs"><Farm /></Container>}
                        />

                        <Route
                            path={`/farm/:network/:contract_address`}
                            exact={true}
                            element={<Container maxWidth="xs"><FarmDetails /></Container>}
                        />

                        <Route
                            path={`/liquidity-dashboard`}
                            exact={true}
                            element={<Container maxWidth="xs"><LiquidityDashboard /></Container>}
                        />

                        <Route
                            path={`/tasks`}
                            exact={true}
                            element={<Container maxWidth="xs"><Tasks /></Container>}
                        />
                    </Routes>
                </Grid>
            </Grid>

            <Drawer
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                PaperProps={{style: {minWidth: 240, backgroundColor: '#000', backgroundImage: 'none'}}}
                onClick={() => setMenuOpen(false)}
            >
                <IconButton onClick={() => setMenuOpen(false)} style={{alignSelf: 'end'}}>
                    <CloseIcon/>
                </IconButton>

                <CoineusMenu />

            </Drawer>
        </Router>
    </ThemeProvider>)
}