import {Grid} from "@mui/material";
import BurnSupply from "../components/BurntSupply";
import Web3 from "web3";
import coineus_abi from "../abis/coineus.json";
import {makeBatchRequest} from "../utils/promisify";
import {useEffect, useState} from "react";
import BurnSupplyFVKSRG from "../components/BurntSupplyFVKSRG";

const web3 = new Web3("https://rpc.fuse.io");
const ceus_contract = new web3.eth.Contract(coineus_abi, "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8");
const fvksrg_contract = new web3.eth.Contract(coineus_abi, "0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61");
const lit_contract = new web3.eth.Contract(coineus_abi, "0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F");

const calls = [
    ceus_contract.methods.totalSupply().call,
    ceus_contract.methods.balanceOf('0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b').call,
    ceus_contract.methods.balanceOf('0xb80267eA7fa368374ee4d4Bf10044778232AdEFe').call,
    ceus_contract.methods.balanceOf('0x87440f829e7ed808599749630555080917c4b3d9').call,
    ceus_contract.methods.balanceOf('0xE27431FACb1F093BA164c9F86B3f484b39ad54CC').call,

    fvksrg_contract.methods.totalSupply().call,
    fvksrg_contract.methods.balanceOf("0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61").call,

    lit_contract.methods.totalSupply().call
];

export default function Stats() {

    const [supply, setSupply] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [fund, setFund] = useState(0);
    const [litLP, setLitLP] = useState(0);
    const [ceusoneContract, setCeusoneContract] = useState(0);


    const [fvksrgSuppply, setFvksrgSupply] = useState(0);
    const [fvksrgDead, setFvksrgDead] = useState(0);
    const [litSuppply, setLitSupply] = useState(0);

    useEffect(() => {
        makeBatchRequest(web3, calls).then(resp => {
            let [
                ceus_supply,
                ceus_liquidity,
                ceus_fund,
                lit_lp,
                ceusone,

                fvksrg_supply,
                fvksrg_dead,

                lit_supply
            ] = resp;

            setSupply(ceus_supply / (10 ** 18));
            setLiquidity(ceus_liquidity / (10 ** 18));
            setFund(ceus_fund / (10 ** 18));
            setLitLP(lit_lp / (10 ** 18));
            setCeusoneContract(ceusone / (10 ** 18));

            setFvksrgSupply(fvksrg_supply / (10 **18))
            setFvksrgDead(fvksrg_dead / (10 **18))

            setLitSupply(lit_supply / (10 **18))
        })
    }, [])

    return (<Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
            <Grid item xs={12}>
                <BurnSupply
                    minted={2000000}
                    supply={supply}
                    liquidity={liquidity}
                    fund={fund}
                    litLP={litLP}
                    ceusone={ceusoneContract}
                />
            </Grid>
            <Grid item xs={12}>
                <BurnSupplyFVKSRG
                    dead={10000000 - litSuppply}
                    supply={litSuppply}
                    token_address="0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"
                    token_symbol="LIT"
                />
            </Grid>
            <Grid item xs={12}>
                <BurnSupplyFVKSRG
                    dead={fvksrgDead}
                    supply={fvksrgSuppply}
                    burnLabel="SOULS COLLECTED"
                    supplyLabel="SOULS CREATED"
                    token_address="0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"
                    token_symbol="FVKSRG"
                />
            </Grid>
        </Grid>
    )

}