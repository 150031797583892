import {configureStore} from '@reduxjs/toolkit';
import coineusReducer from "./slices/coineusSlice";
import {DEXScreenerApi} from "./services/dexscreener";

export default configureStore({
    reducer: {
        coineus: coineusReducer,
        [DEXScreenerApi.reducerPath]: DEXScreenerApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
        DEXScreenerApi.middleware,
    )
})
