import {Avatar, ListItem, ListItemAvatar, ListItemText, Paper} from "@mui/material";
import {CoineusUSDFormat, CoineusCryptoFormat} from "../utils/currency_format";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";

import TokenInfoDialog from "./TokenInfoDialog";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import axios from "axios";
import SparkChart from "./SparkChart";
import {BigNumber} from "bignumber.js";
import millify from "millify";

const useStyles = makeStyles((theme) => ({
    networkChip: {
        borderRadius: "50%",
        marginLeft: 30,
        position: 'absolute',
        top: 42,
        height: 16,
        width: 16,
        display: 'inline-block',
        backgroundSize: 'cover',
        '&.fuse': {
            backgroundImage: `url('https://coineus.app/assets/tokens/fuse/logo.png')`,
        },
        '&.avax': {
            backgroundImage: `url('https://coineus.app/assets/tokens/avax/logo.png')`,
        },
        '&.bnb': {
            backgroundImage: `url('https://coineus.app/assets/tokens/bnb/logo.png')`,
        },
        '&.eth': {
            backgroundImage: `url('https://coineus.app/assets/tokens/eth/logo.png')`,
        }
    }
}));

export default function TokenListItem(props) {

    const classes = useStyles();

    const {wallet, settings} = useSelector(state => state.coineus);

    const {
        contract_address,
        pair,
        invertPrice,
        name,
        network,
        price,
        rewards,
        rewardsClaim,
        native,
        wrap,
        secondaryAction,
        cnsDetails,
        srgDetails,
        detailsLabel,
        rewardsSymbol,
        rewardsRealised,
    } = props;

    const [open, setOpen] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [percentageChange, setPercentageChange] = useState(0);

    if(props.contract_address === '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9'){
        // console.log(props, wallet.balances[network])
    }

    useEffect(() => {
        if (!pair) return;
        axios.post(
            // "https://api.thegraph.com/subgraphs/name/voltfinance/voltage-exchange",
            "https://gateway-arbitrum.network.thegraph.com/api/bc91dc2c250c62e490cae0595ad30d67/subgraphs/id/4buFyoUT8Lay3T1DK9ctdMdcpkZMdi5EpCBWZCBTKvQd",
            {
                query: `
                    {
                      pair(id:"${pair.toLowerCase()}"){
                        token0 {
                          id
                        }
                        token1 {
                          id
                        }
                        pairHourData(
                          first:24, 
                          orderBy: hourStartUnix, 
                          orderDirection:desc,
                          where: {
                            hourStartUnix_gt: ${parseInt((new Date()).setHours(-24) / 1000)}
                          }
                        ){
                          hourStartUnix
                          reserve0
                          reserve1
                          reserveUSD
                        }
                      }
                    }
                    `
            }
        ).then(resp => {
            if (!resp.data.data.pair) {
                setChartData([
                    {
                        x: new Date().setHours(-24),
                        y: 1
                    },
                    {
                        x: new Date(),
                        y: 1
                    }
                ])
                return;
            }
            const {pairHourData} = resp.data.data.pair;

            const data = [];

            pairHourData.map(h => {

                data.push({
                    x: new Date(h.hourStartUnix * 1000),
                    y: invertPrice ? h.reserve1 / h.reserve0 : h.reserve0 / h.reserve1
                })

                return h;

            })
            setChartData(data)
        })
    }, [])

    useEffect(() => {
        if(network !== 'fuse')return;
        if(contract_address === '0xE27431FACb1F093BA164c9F86B3f484b39ad54CC')return;
        axios.post(
            // "https://api.thegraph.com/subgraphs/name/voltfinance/voltage-exchange-v2",
            "https://gateway-arbitrum.network.thegraph.com/api/bc91dc2c250c62e490cae0595ad30d67/subgraphs/id/B4BGk9itvmRXzzNRAzBWwQARHRt3ZvLz11aWNVsZPT4",
            {
                query: `
                        {
                          token(id:"${wrap?.toLowerCase() || contract_address.toLowerCase()}"){
                            id
                            name
                            dayData(
                              first:2
                              orderBy: date
                              orderDirection: desc
                            ){
                              date
                              priceUSD
                            }
                          }
                        }
                    `
            }
        ).then(resp => {
            const {token} = resp.data.data;
            if(!token)return;
            const {dayData} = token;
            if(dayData.length < 2)return;
            const day0 = dayData[0].priceUSD;
            const day1 = dayData[1].priceUSD;

            const change = ((day0 - day1) / day1) * 100;

            setPercentageChange(change);
        })
    }, [])

    if (wallet.balances[network] === undefined) {
        return <></>;
    }

    const getCNSEarned = () => {
        const {balance, deposits, transfersIn, transfersOut, withdrawals, fees} = cnsDetails;

        const bal = new BigNumber(0).plus(balance);

        const sub = new BigNumber(0).plus(deposits).plus(transfersIn).minus(withdrawals).minus(transfersOut).minus(fees);

        return bal.minus(sub).dividedBy(10**18);
    }

    const getSRGEarned = () => {
        const {balance, deposits, transfersIn, transfersOut, withdrawals, fees} = srgDetails;

        const bal = new BigNumber(0).plus(balance);

        const sub = new BigNumber(0).plus(deposits).plus(transfersIn).minus(withdrawals).minus(transfersOut).minus(fees);

        return bal.minus(sub).dividedBy(10**9);
    }

    const displayPrice = (price, pc) => {
        // const color = pc >= 0 ? "#00ff00" : "red";
        // return <>{CoineusUSDFormat(price)} <Typography variant="caption" style={{color, verticalAlign:'top', display: network==="fuse" ? '':'none'}}>{pc.toFixed(2)}%</Typography></>
        return CoineusUSDFormat(price);
    }

    if (wallet.balances[network][contract_address] < .01 && !settings.showZeroBalance) {
        if(wallet.staked[network][contract_address] === undefined || wallet.staked[network][contract_address] < .0000001) {
            return <></>;
        }
    }

    return <Paper style={{marginTop: 10, marginBottom: 5}}>
        <ListItem secondaryAction={secondaryAction}>
            <ListItemAvatar onClick={() => setOpen(true)}>
                <Avatar
                    src={`https://coineus.app/assets/tokens/${network}/${contract_address === 'native' ? '' : contract_address + '/'}logo.png`}/>
                {network && !native && <span className={clsx(classes.networkChip, network)}/>}
            </ListItemAvatar>

            <ListItemText
                primary={name}
                secondary={settings.visibility ? displayPrice(price?.inUSD, percentageChange) : '*****'}
                primaryTypographyProps={{fontWeight: 700}}
                style={{minWidth: 80, maxWidth: 80, flex: 1}}
            />

            <div style={{minWidth: 80, maxWidth: 80, flex: 1}} onClick={pair ? ()=>window.open(`https://dexscreener.com/${network}/${pair}`) : ()=>{}}>
                <SparkChart chartdata={chartData}/>
            </div>

            <ListItemText
                primary={settings.visibility ? wallet.balances[network][contract_address] > 1000000 ? millify(wallet.balances[network][contract_address]) : CoineusCryptoFormat(wallet.balances[network][contract_address]) : '*****'}
                secondary={settings.visibility ? CoineusUSDFormat(price?.inUSD * wallet.balances[network][contract_address]) : '*****'}
                primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                secondaryTypographyProps={{textAlign: 'right'}}
            />
        </ListItem>

        {
            wallet.staked[network][contract_address] > 0 && <>
                <ListItem style={{color: '#fbca00', paddingTop: 0}}>
                    <ListItemText
                        primary='Staked'
                        secondary="Value"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={settings.visibility ? `${CoineusCryptoFormat(wallet.staked[network]?.[contract_address])} ${name}` : '*****'}
                        secondary={settings.visibility ? CoineusUSDFormat(wallet.staked[network]?.[contract_address] * price?.inUSD) : '*****'}
                        primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                </ListItem>
            </>
        }

        {
            rewards !== undefined && <>
                <ListItem secondaryAction={rewardsClaim} style={{color: '#fbca00', paddingTop: 0}}>
                    <ListItemText
                        primary={settings.visibility ? `${CoineusCryptoFormat(rewardsRealised)} ${rewardsSymbol}` : '*****'}
                        primaryTypographyProps={{fontWeight: 700}}
                        secondary="Rewards All-time"
                    />
                    <ListItemText
                        primary={`${CoineusCryptoFormat(rewards)} ${rewardsSymbol}`}
                        secondary="Pending"
                        primaryTypographyProps={{textAlign: 'right', fontWeight: 700, paddingRight:1}}
                        secondaryTypographyProps={{textAlign: 'right', paddingRight:1}}
                    />
                </ListItem>
            </>
        }

        {
            cnsDetails && <>
                <ListItem style={{color: '#fbca00', paddingTop: 0}}>
                    <ListItemText
                        primary='Rewards All-time'
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={settings.visibility ? `${CoineusCryptoFormat(getCNSEarned())} ${detailsLabel}` : '*****'}
                        primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                    />
                </ListItem>
            </>
        }

        {
            srgDetails && <>
                <ListItem style={{color: '#fbca00', paddingTop: 0}}>
                    <ListItemText
                        primary='Rewards All-time'
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={`${CoineusCryptoFormat(getSRGEarned())} ${detailsLabel}`}
                        primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                    />
                </ListItem>
            </>
        }


        {/*TODO work on token details*/}
        <TokenInfoDialog
            contract_address={contract_address}
            wrap={wrap}
            network={network}
            open={open}
            close={() => setOpen(false)}
            pair={pair}
        />
    </Paper>
}