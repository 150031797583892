import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText
} from "@mui/material";

import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import {useSelector} from "react-redux";

export default function RewardsAllTime(props) {
    const {reward_token, network, rewards} = props;

    const {wallet} = useSelector(state => state.coineus);

    const rat = ((rewards[reward_token.contract_address] || 0) / (10**reward_token.decimals))

    return <>
        {
            rat > 0 && <ListItem>
                <ListItemAvatar style={{minWidth: 30}}>
                    <Avatar
                        src={`https://coineus.app/assets/tokens/${network}/${reward_token.contract_address}/logo.png`}
                        sx={{width: 24, height: 24}}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={reward_token.symbol}
                    style={{width: 50}}
                />
                <ListItemText
                    primary={CoineusCryptoFormat(rat)}
                    primaryTypographyProps={{textAlign: "right"}}
                    style={{width: 50}}
                />
                <ListItemText
                    primary={CoineusUSDFormat(rat * wallet.prices[network][reward_token.contract_address].inUSD)}
                    primaryTypographyProps={{textAlign: "right"}}
                    style={{width: 50}}
                />
            </ListItem>
        }
    </>
}