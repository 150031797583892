import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

export default function useWeb3Modal() {

    const getWeb3Modal = async () => {

        const providerOptions = {
            walletconnect: {
                display: {
                    name: "WalletConnect",
                    description: "Scan qrcode with your mobile wallet"
                },
                package: WalletConnectProvider,
                options: {
                    bridge: "https://bridge.walletconnect.org",
                    network: 'binance',
                    rpc: {
                        56: 'https://bsc-dataseed.binance.org/',
                        122: 'https://rpc.fuse.io',
                        43114: 'https://api.avax.network/ext/bc/C/rpc',
                        1: 'https://cloudflare-eth.com/'
                    },
                    qrcode: true
                },
            },
        };

        return new Web3Modal({
            disableInjectedProvider: false,
            cacheProvider: false, // optional
            providerOptions // required
        });
    }

    return {
        getWeb3Modal,
    }
}
