import {useSelector} from "react-redux";
import Web3 from "web3";
import farm_abi from "../abis/MultiRewards.json";
import {useEffect, useState} from "react";
import {makeBatchRequest} from "../utils/promisify";
import erc20_abi from "../abis/IERC20.json";

import earn_config from "../utils/earnContracts.json";

export default function useEarnInfo(farm_contract_address, network) {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const web3 = new Web3(rpc[network]);

    const farm_contract = new web3.eth.Contract(farm_abi, farm_contract_address);

    const [farmInfo, setFarmInfo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getFarmInfo() {
            if (!wallet.address) return;

            const rewardTokens = earn_config[farm_contract_address].reward_tokens;

            const calls = [
                farm_contract.methods.totalSupply().call,
                farm_contract.methods.stakingToken().call,
                farm_contract.methods.balanceOf(wallet.address).call,
            ]

            for(let i=0; i<rewardTokens.length; i++){
                const ca = rewardTokens[i].contract_address;
                calls.push(farm_contract.methods.rewardData(ca).call);
                calls.push(farm_contract.methods.earned(wallet.address, ca).call);
                calls.push(farm_contract.methods.getRewardForDuration(ca).call);
            }

            makeBatchRequest(web3, calls).then(resp => {
                const [
                    totalStaked,
                    stakingToken,
                    stakedAmount,
                ] = resp;

                let respIndex = 3;
                let totalValue = 0;
                let rewardsDuration = 0;
                for(let i=0; i<rewardTokens.length; i++){

                    rewardsDuration = resp[respIndex].rewardsDuration;

                    const secondsLeft = calcSecsLeft(resp[respIndex].periodFinish);
                    const rewardsRemaining = secondsLeft * resp[respIndex].rewardRate / (10 ** rewardTokens[i].decimals);
                    const potentialEarnings = rewardsRemaining * (stakedAmount / totalStaked);

                    totalValue += rewardsRemaining ? resp[respIndex+2] / (10 ** rewardTokens[i].decimals) * wallet.prices[network]?.[rewardTokens[i].contract_address]?.inUSD : 0;

                    rewardTokens[i].data = {
                        rewardData: resp[respIndex],
                        earned: resp[respIndex+1] / (10 ** rewardTokens[i].decimals),
                        rewardsForDuration: resp[respIndex+2] / (10 ** rewardTokens[i].decimals),
                        potentialEarnings,
                        rewardsRemaining
                    };
                    respIndex+=3;
                }
                const valueLP = totalStaked / (10 ** 18) * wallet.prices[network]?.[stakingToken]?.inUSD

                const stakingTokenContract = new web3.eth.Contract(erc20_abi, stakingToken);

                /**
                 * 2592000 = 30 days
                 * 604800 = 7 days
                 */
                let split = 52;
                if(parseInt(rewardsDuration) === 2592000){
                    split = 365/30;
                }

                const apy = (totalValue * split) / valueLP * 100;

                const stakingCalls = [
                    stakingTokenContract.methods.decimals().call,
                    stakingTokenContract.methods.balanceOf(wallet.address).call,
                ]

                makeBatchRequest(web3, stakingCalls).then(resp => {
                    const [
                        decimals,
                        stake_token_balance
                    ] = resp;

                    const tmp = {
                        token0: stakingToken,
                        totalStaked: totalStaked / (10 ** decimals),
                        stakingToken,
                        stakedAmount: stakedAmount / (10 ** decimals),
                        rewardTokens,
                        apy,
                        stake_token_balance: stake_token_balance / (10 ** decimals)
                    }

                    setFarmInfo(tmp);
                    setLoading(false);
                })
            })
        }

        getFarmInfo();
        const interval = setInterval(getFarmInfo, 10000)
        return () => clearInterval(interval);
    }, [farm_contract_address, wallet.address])

    return {farmInfo, loading};
}

const calcSecsLeft = (periodFinish) => {
    const left = parseInt(((periodFinish * 1000) - new Date().getTime()) / 1000);
    return left > 0 ? left : 0;
}