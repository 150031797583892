import {Avatar,  Grid, List, ListItem, ListItemAvatar, ListItemText, Paper} from "@mui/material";
import {useSelector} from "react-redux";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import {useEffect, useState} from "react";

export default function LiquidityDashboard() {

    const {wallet} = useSelector(state => state.coineus);

    const [ceusTVL, setCeusTVL] = useState(0);

    const lps = [
        '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b', //ceus/fuse
        '0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc', //lit/fuse
        '0x87440F829E7ED808599749630555080917c4b3D9', //ceus/lit
        '0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD', //fvksrg/fuse
        '0xB6850233c408Bbf58c3B2beC945394114cB08Fd0', //ceus/fvksrg

    ]

    useEffect(() => {
        let tvlTotal = 0;
        lps.map((lp) => {
            const info = wallet.lp.fuse[lp];
            if (!info) return lp;
            const tvl = info.reserve0 * 2 * wallet.prices.fuse[info.tokens.token0.contract].inUSD;
            tvlTotal += tvl;
            return lp;
        })
        setCeusTVL(tvlTotal);
    }, [wallet.lp.fuse]);

    return (<Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        <Grid item xs={12}>
            <Paper>
                <ListItem>
                    <ListItemText
                        primary={CoineusUSDFormat(ceusTVL)}
                        primaryTypographyProps={{fontWeight: 700, variant: 'h5', textAlign: 'center'}}
                        secondary="Total Value Locked"
                        secondaryTypographyProps={{textAlign: 'center'}}
                    />
                </ListItem>
            </Paper>
        </Grid>

        {
            lps.map((lp) => {
                const info = wallet.lp.fuse[lp];

                if (!info) return lp;

                return <Grid item xs={12}
                             key={lp}
                >
                    <Paper style={{padding: 1}}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <List dense>
                                    <ListItem>


                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{width: 32, height: 32}}
                                                src={`https://coineus.app/assets/tokens/fuse/${info.tokens.token0.contract}/logo.png`}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={CoineusCryptoFormat(info.reserve0)}
                                            primaryTypographyProps={{fontWeight: 700}}
                                            secondary={info.tokens.token0.symbol}
                                        />
                                        <ListItemText
                                            primary={CoineusUSDFormat(info.reserve0 * 2 * wallet.prices.fuse[info.tokens.token0.contract].inUSD)}
                                            primaryTypographyProps={{fontWeight: 700}}
                                            secondary="TVL"
                                        />

                                        <ListItemText
                                            primary={CoineusUSDFormat((info.reserve0 / info.reserve1) * wallet.prices.fuse[info.tokens.token0.contract].inUSD)}
                                            primaryTypographyProps={{fontWeight: 700}}
                                            secondary="USD"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{width: 32, height: 32}}
                                                src={`https://coineus.app/assets/tokens/fuse/${info.tokens.token1.contract}/logo.png`}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={CoineusCryptoFormat(info.reserve1)}
                                            primaryTypographyProps={{fontWeight: 700}}
                                            secondary={info.tokens.token1.symbol}
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            })
        }
    </Grid>)
}