import {useSelector} from "react-redux";
import {
    Button,
    Dialog,
    Grid,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    Divider, ListItemIcon,
} from "@mui/material";

import Web3 from "web3";
import consensus_abi from "../abis/consensus_abi.json";

import {useEffect, useState} from "react";

import BoltIcon from '@mui/icons-material/Bolt';

import {toWei} from "../utils/format";
import CoineusLoading from "../components/CoineusLoading";
import TokenListItem from "../components/TokenListItem";
import PriceScroll from "../components/PriceScroll";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import getUserBalances from "../utils/get_balances";
import LPTokenListItem from "../components/LPTokenListItem";
import CEUSONE from "../components/ONES/CEUSONE";
import LITAssetBacking from "../components/AssetBacking/LIT";
import {CHAIN_IDS_TO_NAMES} from "../constants";
import FUSEONE from "../components/ONES/FUSEONE";
import CEUSE from "../components/Swap/CEUSE";
import VisibilityIcon from '@mui/icons-material/Visibility';
import HideSourceIcon from '@mui/icons-material/HideSource';
import {useDispatch} from "react-redux";
import {updateSettingVisibility, updateSettingZeroBalance} from "../redux/slices/coineusSlice";
import WFUSEDialog from "../components/WFUSEDialog";
import FVKSRGAssetBacking from "../components/AssetBacking/FVKSRG";

export default function HomePage() {

    const dispatch = useDispatch();

    const {wallet, settings} = useSelector(state => state.coineus);

    const [pending, setPending] = useState(false);
    const [stakeOpen, setStakeOpen] = useState(false)
    const [chainTab, setChainTab] = useState('fuse');

    useEffect(() => {
        if (!wallet.chain) return;
        setChainTab(CHAIN_IDS_TO_NAMES[wallet.chain])
    }, [wallet.chain])

    return (<Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
            {/*<Grid item xs={12}>*/}
            {/*    <Paper style={{padding: 20, border: '1px solid #fbca00'}}>*/}
            {/*        <Typography color="primary" fontWeight={700}>New!</Typography>*/}
            {/*        <Typography>Stats page displaying Rewards, Supply, Coineus One Deposits, Burn and*/}
            {/*            more.</Typography>*/}
            {/*        <Link to="/stats" component={RouterLink} underline="hover">View Stats</Link>*/}
            {/*    </Paper>*/}
            {/*</Grid>*/}

            <PriceScroll/>

            <Grid item xs={12}>

                <Paper style={{marginTop: 10}}>
                    <ListItem>
                        <ListItemIcon onClick={()=>dispatch(updateSettingVisibility())}>
                            <VisibilityIcon color={settings.visibility ? "disabled" : ""} />
                        </ListItemIcon>
                        <ListItemIcon onClick={()=>dispatch(updateSettingZeroBalance())}>
                            <HideSourceIcon color={settings.showZeroBalance ? "disabled" : ""}/>
                        </ListItemIcon>
                        <ListItemText
                            primary={settings.visibility ? CoineusUSDFormat(
                                wallet.totals.fuse +
                                wallet.totals.avax +
                                wallet.totals.bnb +
                                wallet.totals.eth
                            ) : '*****'}
                            secondary="Total Wallet Value"
                            primaryTypographyProps={{fontWeight: 700, fontSize: '1.5rem', textAlign: 'right'}}
                            secondaryTypographyProps={{textAlign: 'right'}}
                        />
                    </ListItem>
                </Paper>


                <Tabs
                    value={chainTab}
                    onChange={(ev, val) => {
                        setChainTab(val)
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab id="fuse" value="fuse" label="FUSE"/>
                    <Tab id="eth" value="eth" label="ETH"/>
                    <Tab id="bnb" value="bnb" label="BNB"/>
                    <Tab id="avax" value="avax" label="AVAX"/>
                </Tabs>

                {
                    chainTab === 'fuse' && <>
                        <Paper style={{marginTop: 10}}>
                            <ListItem>
                                <ListItemText
                                    primary={settings.visibility ? CoineusUSDFormat(wallet.totals.fuse) : '*****'}
                                    secondary="Total FUSE Value"
                                    primaryTypographyProps={{fontWeight: 700, fontSize: '1.5rem', textAlign: 'right'}}
                                    secondaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                        </Paper>

                        {/*FUSE*/}
                        <TokenListItem
                            contract_address="native"
                            wrap="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                            network="fuse"
                            name="FUSE"
                            native
                            price={wallet.prices.fuse?.native}
                            pair="0x91520Fc2942Fd52949514f159aA4927b8850178d"
                            invertPrice={true}
                            secondaryAction={
                                <IconButton
                                    disabled={wallet.chain !== 122}
                                    edge="end"
                                    aria-label="stake"
                                    onClick={() => setStakeOpen(true)}
                                    color="primary"
                                >
                                    <BoltIcon/>
                                </IconButton>
                            }
                        />
                        <PowerStake
                            open={stakeOpen}
                            amount={wallet.balances.fuse.native}
                            close={() => setStakeOpen(false)}
                            onSubmit={() => {
                                setStakeOpen(false);
                                setPending(true);
                            }}
                            onSuccess={() => {
                                getUserBalances(wallet.address);
                                setPending(false);
                            }}
                            onError={(err) => {
                                setPending(false);
                            }}
                        />

                        {/*FUSEONE*/}
                        <TokenListItem
                            contract_address="0xadF951967082452c46eA853158e0838F0EB6fee5"
                            network="fuse"
                            name="FUSEONE"
                            price={wallet.prices.fuse?.["0xadF951967082452c46eA853158e0838F0EB6fee5"]}
                            secondaryAction={<FUSEONE onSuccess={() => getUserBalances(wallet.address)}/>}
                            cnsDetails={wallet.info.fuse?.["0xadF951967082452c46eA853158e0838F0EB6fee5"]}
                            detailsLabel="FUSEONE"
                        />

                        {/*CEUS*/}
                        <TokenListItem
                            contract_address="0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"
                            network="fuse"
                            name="CEUS"
                            price={wallet.prices.fuse?.["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]}
                            pair="0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b"
                        />

                        {/*CEUS.e*/}
                        {/*<TokenListItem*/}
                        {/*    contract_address="0x31Db8DC955a281d2fefAB5709d1Dfd9603679222"*/}
                        {/*    network="fuse"*/}
                        {/*    name="CEUS.e"*/}
                        {/*    price={wallet.prices.fuse?.["0x31Db8DC955a281d2fefAB5709d1Dfd9603679222"]}*/}
                        {/*    secondaryAction={<CEUSE onSuccess={() => getUserBalances(wallet.address)}/>}*/}
                        {/*    cnsDetails={wallet.info.fuse?.["0x31Db8DC955a281d2fefAB5709d1Dfd9603679222"]}*/}
                        {/*    detailsLabel="CEUS.e"*/}
                        {/*/>*/}

                        {/*CEUSONE*/}
                        <TokenListItem
                            contract_address="0xE27431FACb1F093BA164c9F86B3f484b39ad54CC"
                            network="fuse"
                            name="CEUSONE"
                            price={wallet.prices.fuse?.["0xE27431FACb1F093BA164c9F86B3f484b39ad54CC"]}
                            secondaryAction={<CEUSONE onSuccess={() => getUserBalances(wallet.address)}/>}
                            cnsDetails={wallet.info.fuse?.["0xE27431FACb1F093BA164c9F86B3f484b39ad54CC"]}
                            detailsLabel="CEUSONE"
                        />

                        {/*LIT*/}
                        <TokenListItem
                            contract_address="0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"
                            network="fuse"
                            name="LIT"
                            price={wallet.prices.fuse?.["0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"]}
                            pair="0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc"
                            secondaryAction={<LITAssetBacking/>}
                        />

                        {/*LADY*/}
                        {/*<TokenListItem*/}
                        {/*    contract_address="0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A"*/}
                        {/*    network="fuse"*/}
                        {/*    name="$LADY"*/}
                        {/*    price={wallet.prices.fuse?.["0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A"]}*/}
                        {/*    pair="0x64DEf29D3B9fA1ACD8002c2F59e056A08668d586"*/}
                        {/*/>*/}

                        {/*FOO*/}
                        <TokenListItem
                            contract_address="0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9"
                            network="fuse"
                            name="$FOO"
                            price={wallet.prices.fuse?.["0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9"]}
                            pair="0x4f47a2c360266230795CFBca80b7C89EdA52Cd52"
                        />

                        {/*FVKSRG*/}
                        <TokenListItem
                            contract_address="0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"
                            network="fuse"
                            name="FVKSRG"
                            price={wallet.prices.fuse?.["0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"]}
                            pair="0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD"
                            secondaryAction={<FVKSRGAssetBacking/>}
                        />

                        {/*fUSD*/}
                        <TokenListItem
                            contract_address="0xCE86a1cf3cFf48139598De6bf9B1dF2E0f79F86F"
                            network="fuse"
                            name="fUSDv3"
                            price={wallet.prices.fuse?.["0xCE86a1cf3cFf48139598De6bf9B1dF2E0f79F86F"]}
                            pair="0x4A877571Da2746C03025e15A548c4B607275dadC"
                        />

                        {/*VOLT*/}
                        <TokenListItem
                            contract_address="0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4"
                            network="fuse"
                            name="VOLT"
                            price={wallet.prices.fuse?.["0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4"]}
                            pair="0xa670b12f8485aa379e99cf097017785b6aca5968"
                        />

                        {/*WFUSE*/}
                        <TokenListItem
                            contract_address="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                            network="fuse"
                            name="WFUSE"
                            price={wallet.prices.fuse?.["0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"]}
                            secondaryAction={<WFUSEDialog onSuccess={() => getUserBalances(wallet.address)}/>}
                        />


                        {/*BTC*/}
                        <TokenListItem
                            contract_address="0x33284f95ccb7B948d9D352e1439561CF83d8d00d"
                            network="fuse"
                            name="WBTC"
                            price={wallet.prices.fuse?.["0x33284f95ccb7B948d9D352e1439561CF83d8d00d"]}
                            pair="0x5484150682bfb3d655faff28b3ac0295ca5a1222"
                            invertPrice={true}
                        />

                        {/*ETH*/}
                        <TokenListItem
                            contract_address="0xa722c13135930332Eb3d749B2F0906559D2C5b99"
                            network="fuse"
                            name="WETH"
                            price={wallet.prices.fuse?.["0xa722c13135930332Eb3d749B2F0906559D2C5b99"]}
                            pair="0x7fe1a61e4fe983d275cb5669072a9d1dee9bd45c"
                        />

                        {/*BNB*/}
                        <TokenListItem
                            contract_address="0x6acb34b1Df86E254b544189Ec32Cf737e2482058"
                            network="fuse"
                            name="WBNB"
                            price={wallet.prices.fuse?.["0x6acb34b1Df86E254b544189Ec32Cf737e2482058"]}
                            pair="0x0df48369504825c16d3fc6a74842aef3c91e90e6"
                        />

                        <Grid item xs={12}><Divider>LP TOKENS</Divider></Grid>

                        {/*CEUS/WFUSE*/}
                        <LPTokenListItem
                            contract_address="0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b"
                            token0="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                            token1="0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"
                            token0Symbol="WFUSE"
                            token1Symbol="CEUS"
                            network="fuse"
                            name="WFUSE/CEUS"
                            price={wallet.prices.fuse?.["0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b"]}
                        />

                        {/*FUSE/LIT*/}
                        <LPTokenListItem
                            contract_address="0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc"
                            token0="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                            token1="0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"
                            token0Symbol="WFUSE"
                            token1Symbol="LIT"
                            network="fuse"
                            name="WFUSE/LIT"
                            price={wallet.prices.fuse?.["0x1A77BAea67C346cE17486b078a3c3F53a99A9Fcc"]}
                        />

                        {/*CEUS/LIT*/}
                        <LPTokenListItem
                            contract_address="0x87440F829E7ED808599749630555080917c4b3D9"
                            token0="0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"
                            token1="0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"
                            token0Symbol="CEUS"
                            token1Symbol="LIT"
                            network="fuse"
                            name="CEUS/LIT"
                            price={wallet.prices.fuse?.["0x87440F829E7ED808599749630555080917c4b3D9"]}
                        />

                        {/*$FVKSRG/FUSE*/}
                        <LPTokenListItem
                            contract_address="0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD"
                            token0="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                            token1="0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"
                            token0Symbol="WFUSE"
                            token1Symbol="FVKSRG"
                            network="fuse"
                            name="WFUSE/FVKSRG"
                            price={wallet.prices.fuse?.["0xA9c312E6DAC0EC56C6A8CB338e9121c6349D9beD"]}
                        />

                        {/*$FVKSRG/CEUS*/}
                        <LPTokenListItem
                            contract_address="0xB6850233c408Bbf58c3B2beC945394114cB08Fd0"
                            token0="0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"
                            token1="0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"
                            token0Symbol="CEUS"
                            token1Symbol="FVKSRG"
                            network="fuse"
                            name="CEUS/FVKSRG"
                            price={wallet.prices.fuse?.["0xB6850233c408Bbf58c3B2beC945394114cB08Fd0"]}
                        />

                        {/*WFUSE/FOO*/}
                        <LPTokenListItem
                            contract_address="0x4f47a2c360266230795CFBca80b7C89EdA52Cd52"
                            token0="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                            token1="0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9"
                            token0Symbol="WFUSE"
                            token1Symbol="FOO"
                            network="fuse"
                            name="WFUSE/FOO"
                            price={wallet.prices.fuse?.["0x4f47a2c360266230795CFBca80b7C89EdA52Cd52"]}
                        />


                    </>
                }


                {
                    chainTab === 'bnb' && <>
                        <Paper style={{marginTop: 10}}>
                            <ListItem>
                                <ListItemText
                                    primary={settings.visibility ? CoineusUSDFormat(wallet.totals.bnb) : '*****'}
                                    secondary="Total BNB Value"
                                    primaryTypographyProps={{fontWeight: 700, fontSize: '1.5rem', textAlign: 'right'}}
                                    secondaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                        </Paper>

                        {/*BNB*/}
                        <TokenListItem
                            contract_address="native"
                            network="bnb"
                            name="BNB"
                            native
                            price={wallet.prices.bnb?.native}
                            wrap="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                        />

                        {/*FUSE*/}
                        <TokenListItem
                            contract_address="0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3"
                            network="bnb"
                            name="FUSE"
                            price={wallet.prices.bnb?.["0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3"]}
                        />

                        {/*BUSD*/}
                        <TokenListItem
                            contract_address="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
                            name="BUSD"
                            network="bnb"
                            price={wallet.prices.bnb?.["0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"]}
                        />

                        {/*USDC*/}
                        <TokenListItem
                            contract_address="0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"
                            name="USDC"
                            network="bnb"
                            price={wallet.prices.bnb?.["0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"]}
                        />

                        {/*USDT*/}
                        <TokenListItem
                            contract_address="0x55d398326f99059fF775485246999027B3197955"
                            name="USDT"
                            network="bnb"
                            price={wallet.prices.bnb?.["0x55d398326f99059fF775485246999027B3197955"]}
                        />
                    </>
                }


                {
                    chainTab === 'avax' && <>
                        <Paper style={{marginTop: 10}}>
                            <ListItem>
                                <ListItemText
                                    primary={settings.visibility ? CoineusUSDFormat(wallet.totals.avax) : '*****'}
                                    secondary="Total AVAX Value"
                                    primaryTypographyProps={{fontWeight: 700, fontSize: '1.5rem', textAlign: 'right'}}
                                    secondaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                        </Paper>

                        <TokenListItem
                            contract_address="native"
                            network="avax"
                            name="AVAX"
                            native
                            price={wallet.prices.avax?.native}
                        />

                        {/*USDC*/}
                        <TokenListItem
                            contract_address="0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
                            network="avax"
                            name="USDC"
                            price={wallet.prices.avax?.["0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"]}
                        />

                        {/*DGNX*/}
                        <TokenListItem
                            contract_address="0x51e48670098173025C477D9AA3f0efF7BF9f7812"
                            network="avax"
                            name="DGNX"
                            price={wallet.prices.avax?.["0x51e48670098173025C477D9AA3f0efF7BF9f7812"]}
                        />

                    </>
                }


                {
                    chainTab === 'eth' && <>
                        <Paper style={{marginTop: 10}}>
                            <ListItem>
                                <ListItemText
                                    primary={settings.visibility ? CoineusUSDFormat(wallet.totals.eth) : '*****'}
                                    secondary="Total ETH Value"
                                    primaryTypographyProps={{fontWeight: 700, fontSize: '1.5rem', textAlign: 'right'}}
                                    secondaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                        </Paper>

                        {/*ETH*/}
                        <TokenListItem
                            contract_address="native"
                            network="eth"
                            name="ETH"
                            native
                            price={wallet.prices.eth?.native}
                            wrap="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
                        />

                        {/*CEUS*/}
                        <TokenListItem
                            contract_address="0x496443ea0c46Ea3aF7025F071EA3FBd5F1f1D560"
                            network="eth"
                            name="CEUS"
                            price={wallet.prices.eth?.["0x496443ea0c46Ea3aF7025F071EA3FBd5F1f1D560"]}
                        />

                        {/*DGNX*/}
                        <TokenListItem
                            contract_address="0x0000000000300dd8B0230efcfEf136eCdF6ABCDE"
                            network="eth"
                            name="DGNX"
                            price={wallet.prices.eth?.["0x0000000000300dd8B0230efcfEf136eCdF6ABCDE"]}
                        />

                        {/*USDC*/}
                        <TokenListItem
                            contract_address="0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
                            network="eth"
                            name="USDC"
                            price={wallet.prices.eth?.["0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"]}
                        />

                        {/*USDT*/}
                        <TokenListItem
                            contract_address="0xdAC17F958D2ee523a2206206994597C13D831ec7"
                            network="eth"
                            name="USDT"
                            price={wallet.prices.eth?.["0xdAC17F958D2ee523a2206206994597C13D831ec7"]}
                        />
                    </>
                }
            </Grid>
            <CoineusLoading open={pending} label="Transaction Pending"/>
        </Grid>
    );
}

function PowerStake(props) {

    const {wallet} = useSelector(state => state.coineus);
    const {open, close, amount, onSubmit, onSuccess, onError} = props;

    const delegate = () => {

        if (!wallet.address) return;

        const web3 = new Web3(window.provider);
        const connectedContract = new web3.eth.Contract(consensus_abi, "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79")//fuse consensus address

        connectedContract.methods
            .delegate("0x53e2033F86C1a54bEbAE893DC8C9fAe179b3Faa2")//validator address
            .send({
                from: wallet.address,
                value: toWei(amount - .1),
                gasPrice: 10000000000
            })
            .once('transactionHash', function (tx) {
                onSubmit();
            })
            .on('error', function (error) {
                onError(error)
            })
            .then(function (receipt) {
                onSuccess()
            })
            .catch((error) => {
                onError(error)
            });
    }

    return <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
            <Typography>Stake <b>{CoineusCryptoFormat(amount - .1)}</b> FUSE</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>cancel</Button>
            <Button onClick={delegate} variant="contained">Stake</Button>
        </DialogActions>
    </Dialog>
}