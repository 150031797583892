import {Avatar, ListItem, ListItemAvatar, ListItemText, Paper} from "@mui/material";
import {CoineusUSDFormat, CoineusCryptoFormat} from "../utils/currency_format";

import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";


export default function LPTokenListItem(props) {


    const {wallet, settings} = useSelector(state => state.coineus);

    const {contract_address, token0, token1, token0Symbol, token1Symbol, name, network, price, secondaryAction} = props;

    const [lpRewards, setLpRewards] = useState({});

    useEffect(() => {
        axios.post(
            // "https://api.thegraph.com/subgraphs/name/voltfinance/voltage-exchange-v2",
            "https://gateway-arbitrum.network.thegraph.com/api/bc91dc2c250c62e490cae0595ad30d67/subgraphs/id/B4BGk9itvmRXzzNRAzBWwQARHRt3ZvLz11aWNVsZPT4",

            {
                query: `
                        {
                          pairDayDatas(first:2, where:{
                            pair_:{
                              id:"${contract_address.toLowerCase()}"
                            }
                          }
                            orderBy:date
                            orderDirection: desc
                          ){
                            date
                            pair {
                              id
                            }
                            volumeUSD
                            reserveUSD
                            untrackedVolumeUSD
                          }
                        }
                    `
            }
        ).then(resp => {

            const volume = parseFloat(resp.data.data.pairDayDatas[0].volumeUSD) || resp.data.data.pairDayDatas[0].untrackedVolumeUSD || 0;
            const liquidity = resp.data.data.pairDayDatas[0].reserveUSD || 0;
            const voltage_fee = 0.3;

            const fee_share = volume * voltage_fee / 100;

            const yearly_fees = fee_share * 365;

            const apr = (yearly_fees/liquidity)*100;

            setLpRewards({
                volume,
                liquidity,
                voltage_fee,
                fee_share,
                yearly_fees,
                apr
            });
        })
    }, [])

    if (wallet.balances[network] === undefined) {
        return <></>;
    }

    if (wallet.lp[network][contract_address] === undefined) {
        return <></>;
    }

    if (wallet.balances[network][contract_address] < .01) {
        if(wallet.staked[network][contract_address] === undefined || wallet.staked[network][contract_address] < .0000001) {
            return <></>;
        }
    }

    return <Paper style={{marginTop: 10, marginBottom: 5}}>
        <ListItem secondaryAction={secondaryAction}>
            <ListItemAvatar>
                <Avatar
                    style={{marginLeft:-5}}
                    src={`https://coineus.app/assets/tokens/${network}/${token0}/logo.png`}/>
                <Avatar
                    style={{marginTop: -40, marginLeft:20, marginRight: 20}}
                    src={`https://coineus.app/assets/tokens/${network}/${token1}/logo.png`}/>
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondary={CoineusUSDFormat(price?.inUSD)}
                primaryTypographyProps={{fontWeight: 700}}
            />
            <ListItemText
                primary={settings.visibility ? CoineusCryptoFormat(wallet.balances[network][contract_address]) : '*****'}
                secondary={settings.visibility ? CoineusUSDFormat(price?.inUSD * wallet.balances[network][contract_address]) : '*****'}
                primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                secondaryTypographyProps={{textAlign: 'right'}}
            />
        </ListItem>

        {
            wallet.staked[network][contract_address] > 0 && <>
                <ListItem style={{color: '#fbca00', paddingTop: 0}}>
                    <ListItemText
                        primary='Staked'
                        secondary="Value"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={settings.visibility ? `${CoineusCryptoFormat(wallet.staked[network]?.[contract_address])} ${name}` : '*****'}
                        secondary={settings.visibility ? CoineusUSDFormat(wallet.staked[network]?.[contract_address] * price?.inUSD) : '*****'}
                        primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                </ListItem>
            </>
        }

        {
            settings.visibility && <>
                <ListItem>
                <ListItemText
                    primary={CoineusCryptoFormat(wallet.lp[network][contract_address].token0)}
                    secondary={token0Symbol}
                    primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                    secondaryTypographyProps={{textAlign: 'right'}}
                />
                <ListItemText
                    primary={CoineusCryptoFormat(wallet.lp[network][contract_address].token1)}
                    secondary={token1Symbol}
                    primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                    secondaryTypographyProps={{textAlign: 'right'}}
                />
                <ListItemText
                    primary={wallet.lp[network][contract_address].share.toFixed(2) + " %"}
                    secondary="Pool Share"
                    primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                    secondaryTypographyProps={{textAlign: 'right'}}
                />
            </ListItem>


                <ListItem>
                    <ListItemText
                        primary={CoineusUSDFormat(lpRewards.volume)}
                        secondary="24hr Volume"
                        primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                    <ListItemText
                        primary={CoineusUSDFormat(lpRewards.liquidity)}
                        secondary="Liquidity"
                        primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                    <ListItemText
                        primary={parseFloat(lpRewards.apr).toFixed(2)+"%"}
                        secondary="APR"
                        primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                </ListItem>
            </>
        }


        {/*TODO work on token details*/}
        {/*<TokenInfoDialog*/}
        {/*    contract_address={contract_address}*/}
        {/*    wrap={wrap}*/}
        {/*    network={network}*/}
        {/*    open={open}*/}
        {/*    close={() => setOpen(false)}/>*/}
    </Paper>
}