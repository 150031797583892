import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    wallet: {
        address: undefined,
        chain: undefined,
        type: undefined,

        info: {
            avax: {},
            fuse: {},
            bnb: {},
            eth: {}
        },

        lp: {
            avax: {},
            fuse: {},
            bnb: {},
            eth: {}
        },

        balances: {
            avax: {},
            fuse: {},
            bnb: {},
            eth: {}
        },

        staked: {
            avax: {},
            fuse: {},
            bnb: {},
            eth: {}
        },

        prices: {
            avax: {},
            fuse: {},
            bnb: {},
            eth: {}
        },

        totals: {
            avax: 0,
            fuse: 0,
            bnb: 0,
            eth: 0
        }
    },
    pairs: {
        fUSDcontract: '0xCE86a1cf3cFf48139598De6bf9B1dF2E0f79F86F',
        fUSDFUSEpair: '0x4A877571Da2746C03025e15A548c4B607275dadC',
        CEUSFUSEpair: '0x3b46eFdd411cc0Db43f1BE7853727e18Cce8b85b',
        VOLTFUSEpair: '0xa670b12f8485aa379e99cf097017785b6aca5968',
        FSBFUSEpair: '0x3df8938e749dd3a2b21fa4dd073366cb2359158a',
        CEUSLITPair: '0x87440f829e7ed808599749630555080917c4b3d9',
        GDFUSEPair: '0xa02ed9Fe9e3351FE2cd1F588B23973C1542dCbCC',
        BBKSCEUSPair: '0x1306d7342edfe9c3686ad044e47d8b8fa2d6a7f0',
        LADYCEUSPair: '0xceab8d0303924d3db5b3a3ed178f055603398845',

        FUSEBNBPair: '0x6483F166b9E4310A165a55FEa04F867499aded06',
        ETHFUSEPair: '0x7fe1a61e4fe983d275cb5669072a9d1dee9bd45c',
        BNBfUSDPair: '0x0df48369504825c16d3fc6a74842aef3c91e90e6',
        BTCFUSEPair: '0x5484150682bfb3d655faff28b3ac0295ca5a1222',

        USDCAVAXpair: '0xf4003f4efbe8691b60249e6afbd307abe7758adb',
        DGNXAVAXpair: '0xbcabb94006400ed84c3699728d6ecbaa06665c89',

        BNBBUSDpair: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
        BNBCEUSPair: '0x65fa1057787db3cd4a62a8c53334a9f9f785731d',

        ETHUSDCPair: '0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc',
        ETHSRGPair: '0xcD682EF09d07668d49A8103ddD65Ff54AebFbfDe',

        ETHFEGPair: '0x60ef1e0bf9218cdc1769a43c4b0b111ed38bb418',
        ETHCEUSPair: '0x0156b91b81BCD161e7D54037B53720D9010F7A6C',
        BNBFEGPair: '0xf53a1d602281b1ce2a92a1763364d2981269a72c'
    },
    rpc: {
        fuse: 'https://rpc.fuse.io/',
        avax: 'https://api.avax.network/ext/bc/C/rpc',
        bnb: 'https://bsc-dataseed1.binance.org/',
        eth: 'https://cloudflare-eth.com/'
    },
    tokens : {},
    settings: retrieveSettingsLocal()
};

const coineusSlice = createSlice({
    name: 'coineus',
    initialState,
    reducers: {

        updateWalletChain(state, action) {
            state.wallet.chain = action.payload
        },

        updateWalletAddress(state, action) {
            state.wallet.address = action.payload
        },

        updateWalletProviderType(state, action) {
            state.wallet.type = action.payload
        },

        updateInfo(state, action) {
            if(!state.wallet.info[action.payload.chain][action.payload.contract_address]){
                state.wallet.info[action.payload.chain][action.payload.contract_address] = {};
            }
            state.wallet.info[action.payload.chain][action.payload.contract_address] = action.payload.info;
        },

        updateLPInfo(state, action) {
            if(!state.wallet.lp[action.payload.chain][action.payload.contract_address]){
                state.wallet.lp[action.payload.chain][action.payload.contract_address] = {};
            }
            state.wallet.lp[action.payload.chain][action.payload.contract_address] = action.payload.lp;
        },

        updateBalances(state, action) {
            if(!state.wallet.balances[action.payload.chain][action.payload.contract_address]){
                state.wallet.balances[action.payload.chain][action.payload.contract_address] = 0;
            }
            state.wallet.balances[action.payload.chain][action.payload.contract_address] = action.payload.balance;
        },

        updateStaked(state, action) {
            if(!state.wallet.staked[action.payload.chain][action.payload.contract_address]){
                state.wallet.staked[action.payload.chain][action.payload.contract_address] = 0;
            }
            state.wallet.staked[action.payload.chain][action.payload.contract_address] = action.payload.staked;
        },

        updatePrices(state, action) {
            if(!state.wallet.prices[action.payload.chain][action.payload.contract_address]){
                state.wallet.prices[action.payload.chain][action.payload.contract_address] = {};
            }
            state.wallet.prices[action.payload.chain][action.payload.contract_address] = action.payload.price;
        },

        updateTotals(state, action) {
            let total = 0;
            Object.entries(state.wallet.balances[action.payload]).map(([contract_address,balance]) => {
                if(state.wallet.prices[action.payload][contract_address]){
                    total += balance*state.wallet.prices[action.payload][contract_address].inUSD;
                }
                return [contract_address,balance];
            })
            Object.entries(state.wallet.staked[action.payload]).map(([contract_address,balance]) => {
                if(state.wallet.prices[action.payload][contract_address]){
                    total += balance*state.wallet.prices[action.payload][contract_address].inUSD;
                }
                return [contract_address,balance];
            })

            state.wallet.totals[action.payload] = total;
        },

        resetWallet(state) {
            state.wallet.chain = undefined;
            state.wallet.address = undefined;
            state.wallet.type = undefined;
        },

        updateTokens(state, action) {
            state.tokens = JSON.parse(JSON.stringify(action.payload));
        },

        updateSettingVisibility(state) {
            state.settings.visibility = !state.settings.visibility;
            storeSettingsLocal(state.settings);
        },

        updateSettingZeroBalance(state) {
            state.settings.showZeroBalance = !state.settings.showZeroBalance;
            storeSettingsLocal(state.settings);
        }

    }
});

export const {
    updateWalletChain,
    updateWalletAddress,
    updateWalletProviderType,

    updateInfo,
    updateLPInfo,
    updateBalances,
    updateStaked,
    updatePrices,
    updateTotals,

    resetWallet,

    updateTokens,

    updateSettingVisibility,
    updateSettingZeroBalance
} = coineusSlice.actions;

export default coineusSlice.reducer;

function storeSettingsLocal(settings) {
    localStorage.setItem('coineus_settings', JSON.stringify(settings));
}
function retrieveSettingsLocal() {
    const settings = localStorage.getItem('coineus_settings');

    return settings ? JSON.parse(localStorage.getItem('coineus_settings')) : {
        visibility: true,
        showZeroBalance: true
    }
}