import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Grid
} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {makeBatchRequest} from "../../utils/promisify";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import Web3 from "web3";

import consensus_abi from "../../abis/consensus_abi.json";
import {CoineusCryptoFormat} from "../../utils/currency_format";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import CoineusLoading from "../../components/CoineusLoading";

const consensusAddress = "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79";
const web3 = new Web3("https://rpc.fuse.io");
const consensus = new web3.eth.Contract(consensus_abi, consensusAddress);

export default function Validator() {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [validators, setValidators] = useState({});
    const [pending, setPending] = useState(true);
    const [pendingTX, setPendingTX] = useState(false);

    const getConsensus = () => {

        if (!wallet.address) return;

        const calls = [
            consensus.methods.getValidators().call,
            consensus.methods.jailedValidators().call
        ];

        makeBatchRequest(web3, calls).then(resp => {

            const [
                validators,
                jailed_validators
            ] = resp;

            const promises = [];

            const list = validators.concat(jailed_validators);

            list.map(v => {
                promises.push(getValidatorInfo(v));return v;
            })

            const vs = [];
            Promise.all(promises).then(resp => {
                resp.map(v => {
                    v.id = v.validator_address;
                    vs.push(v);
                    return v;
                });

                setValidators(vs);
                setPending(false);
            })

        })

    }

    const getValidatorInfo = (validator) => {

        return new Promise(resolve => {
            const calls = [
                consensus.methods.isJailed(validator).call,
                consensus.methods.isValidator(validator).call,
                consensus.methods.isPendingValidator(validator).call,
                consensus.methods.getStrikes(validator).call,
                consensus.methods.validatorFee(validator).call,
                consensus.methods.stakeAmount(validator).call,
                consensus.methods.delegators(validator).call,
            ];

            makeBatchRequest(web3, calls).then(resp => {

                const [
                    is_jailed,
                    is_validator,
                    is_pending_validator,
                    strikes,
                    validator_fee,
                    staked_amount,

                    delegators
                ] = resp;

                getDelegatorInfo(validator, delegators).then(resp => {

                    resolve({
                        validator_address: validator,
                        is_jailed,
                        is_validator,
                        is_pending_validator,
                        strikes: strikes / 1,
                        validator_fee: validator_fee / (10 ** 18),
                        staked_amount: staked_amount / (10 ** 18),
                        delegators: resp
                    });

                });
            })
        })
    }

    const getDelegatorInfo = (validator, delegators) => {

        return new Promise(resolve => {

            const calls = [];
            const balances = [];

            delegators.map(d => {
                if (!d) return d;
                calls.push(consensus.methods.delegatedAmount(d, validator).call); return d;
            });

            makeBatchRequest(web3, calls).then(resp => {
                let index = 0;
                delegators.map(d => {
                    if (!d) return d;
                    balances.push({
                        id: d,
                        address: d,
                        amount: resp[index] / 10 ** 18
                    });
                    index++; return d;
                });
                resolve(balances);
            });

        })
    }

    useEffect(() => {
        getConsensus();
        const interval = setInterval(getConsensus, 60000 * 10)
        return () => clearInterval(interval);
    }, [rpc.fuse, wallet.address]);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
                <Grid item xs={12}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'staked_amount', sort: 'desc'}],
                            },
                        }}
                        disableRowSelectionOnClick
                        loading={pending}
                        slots={{
                            toolbar: () => (<GridToolbarContainer>
                                <GridToolbarExport/>
                            </GridToolbarContainer>)
                        }}
                        columns={[
                            {field: 'validator_address', headerName: 'Validator', flex: 1, minWidth: 380},
                            {
                                field: 'staked_amount',
                                headerName: 'Staked',
                                flex: 1,
                                align: 'right',
                                valueFormatter: (params) => {
                                    return `${CoineusCryptoFormat(params.value)}`;
                                }
                            },
                            {
                                field: 'validator_fee',
                                headerName: 'Fee',
                                align: 'right',
                                valueFormatter: (params) => {
                                    return `${params.value * 100}%`;
                                }
                            },
                            {
                                field: 'delegators_count',
                                headerName: 'Delegators',
                                align: 'right',
                                renderCell: (params) => (
                                    <DelegatorDialog delegators={params.row.delegators}/>

                                )
                            },
                            {
                                field: 'is_validator',
                                headerName: 'Active',
                                renderCell: (params) => {
                                    return params.value ? <CheckBoxIcon style={{color: 'green'}}/> :
                                        <CancelIcon style={{color: 'red'}}/>;
                                }
                            },
                            {
                                field: 'is_pending_validator',
                                headerName: 'Pending',
                                renderCell: (params) => {
                                    return params.value ? <CheckBoxIcon style={{color: 'green'}}/> :
                                        <CancelIcon style={{color: 'red'}}/>;
                                }
                            },
                            {
                                field: 'is_jailed',
                                headerName: 'Jailed',
                                renderCell: (params) => {
                                    return params.value ?
                                        (
                                            params.row.validator_address === wallet.address ?
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setPendingTX(true);
                                                        const web3 = new Web3(window.provider);
                                                        const contract = new web3.eth.Contract(consensus_abi, "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79");
                                                        contract.methods.unJail()
                                                            .send({
                                                                from: wallet.address,
                                                                gasPrice: 10000000000
                                                            })
                                                            .once('transactionHash', function (tx) {
                                                                setPendingTX(true);
                                                            })
                                                            .on('error', function (error) {
                                                                setPendingTX(false);
                                                            })
                                                            .then(function (receipt) {
                                                                setPendingTX(false);
                                                            })
                                                            .catch((error) => {
                                                                setPendingTX(false);
                                                            });
                                                    }}>UNJAIL</Button> :
                                                <CheckBoxIcon
                                                    style={{color: 'green'}}
                                                />
                                        ) :
                                        <CancelIcon style={{color: 'red'}}/>;
                                }
                            },
                            {field: 'strikes', headerName: 'Strikes', align: 'right'}
                        ]}
                        autoHeight={true}
                        rows={validators}
                        pageSizeOptions={[5, 10, 25, 100]}
                    />
                </Grid>
            </Grid>
            <CoineusLoading open={pendingTX} label="Transaction Pending"/>
        </Container>
    )
}

function DelegatorDialog(props) {

    const {delegators} = props;

    const [open, setOpen] = useState(false);

    return <>
        <Button onClick={() => setOpen(true)}>{delegators.length}</Button>

        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'amount', sort: 'desc'}],
                                },
                            }}
                            disableRowSelectionOnClick
                            autoHeight={true}
                            rows={delegators}
                            columns={[
                                {field: 'address', headerName: 'Address', flex: 1, minWidth: 400},
                                {
                                    field: 'amount',
                                    headerName: 'Amount',
                                    headerAlign: 'right',
                                    align: 'right',
                                    minWidth: 100,
                                    valueFormatter: (params) => {
                                        return `${CoineusCryptoFormat(params.value)}`;
                                    }
                                }
                            ]}
                            pageSizeOptions={[5, 10, 25]}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
}