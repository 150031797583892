import {
    Alert,
    Avatar, Button,
    Dialog,
    DialogContent,
    Grid, IconButton, List,
    ListItem,
    ListItemAvatar,
    ListItemText, Snackbar,
    Typography
} from "@mui/material";
import {useGetTokenQuery} from "../redux/services/dexscreener";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import {copyTextToClipboard} from "../utils";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import millify from "millify";
import {useState} from "react";
import token_list from "../utils/tokenList.json";

const useStyles = makeStyles((theme) => ({
    networkChip: {
        borderRadius: "50%",
        marginLeft: 30,
        position: 'absolute',
        top: 42,
        height: 16,
        width: 16,
        display: 'inline-block',
        backgroundSize: 'cover',
        '&.fuse': {
            backgroundImage: `url('https://coineus.app/assets/tokens/fuse/logo.png')`,
        },
        '&.avax': {
            backgroundImage: `url('https://coineus.app/assets/tokens/avax/logo.png')`,
        },
        '&.bnb': {
            backgroundImage: `url('https://coineus.app/assets/tokens/bnb/logo.png')`,
        },
        '&.eth': {
            backgroundImage: `url('https://coineus.app/assets/tokens/eth/logo.png')`,
        }
    },
    negative: {
        color: 'red'
    },
    positive: {
        color: 'green'
    }
}));

export default function TokenInfoDialog(props) {

    const {contract_address, network, open, close, wrap, pair} = props;

    const {data, status} = useGetTokenQuery(wrap || contract_address, {
        skip: !open
    });

    function findPair(pairs) {

        const pair_address = token_list.tokens[network][contract_address]?.dexscreenerPair || undefined;

        if (pair_address) {
            let p = pairs[0];
            pairs.map(pair => {
                if (pair_address.toLowerCase() === pair.pairAddress.toLowerCase()) {
                    p = pair;
                }
                return pair;
            })
            return p;
        }

        return pairs[0];
    }

    return <Dialog open={open} fullScreen>
        <DialogContent>
            <Button onClick={close} style={{position: 'absolute', top: 15, right: 15, zIndex: 99999}}>Close</Button>
            <Grid container spacing={2}>
                {
                    status === 'fulfilled'
                    && data.pairs
                    && data.pairs.length !== 0
                    && <>
                        <PairDetails
                            contract_address={contract_address}
                            data={findPair(data.pairs)}
                            network={network}
                            pair={pair}
                        />
                        <Markets data={data.pairs}/>
                    </>
                }
                {
                    status === 'fulfilled'
                    && data.pairs
                    && !data.pairs.length
                    && <Grid item xs={12}><Typography>No pairs found</Typography></Grid>
                }
            </Grid>
        </DialogContent>
    </Dialog>
}

function PairDetails(props) {

    const classes = useStyles();

    const [snackbarOpen, setSnackBarOpen] = useState(false);

    const {data, network, contract_address} = props;

    const getColorClass = (value) => {
        if (value === 0) return '';

        return value > 0 ? classes.positive : classes.negative
    }

    let {name, symbol} = data.baseToken;

    if (contract_address === 'native') {
        name = name.replace('Wrapped ', '')
        symbol = symbol.replace('W', '')
    }

    return <Grid container>
        <Grid item xs={12}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar
                        src={`https://coineus.app/assets/tokens/${network}/${contract_address === 'native' ? '' : contract_address + '/'}/logo.png`}/>
                    {data.chainId && <span className={clsx(classes.networkChip, data.chainId)}/>}
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    secondary={symbol}
                />
            </ListItem>
        </Grid>
        {
            contract_address !== 'native' && <Grid item xs={12}>
                <ListItem
                    secondaryAction={
                        <IconButton edge="end" aria-label="comments"
                                    onClick={() => copyTextToClipboard(contract_address, () => setSnackBarOpen(true))}>
                            <ContentCopyIcon/>
                        </IconButton>
                    }>
                    <ListItemText
                        primary={contract_address}
                        secondary="Contract Address"
                        primaryTypographyProps={{fontSize: '.75rem'}}
                    />
                </ListItem>
            </Grid>
        }

        <Grid item xs={12}>
            <ListItem>
                <ListItemText
                    primary={CoineusUSDFormat(data.fdv)}
                    secondary="Fully Diluted Value"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={CoineusUSDFormat(data.priceUsd)}
                    secondary="Price in USD"
                />
                <ListItemText
                    primary={CoineusCryptoFormat(data.priceNative)}
                    secondary={`Price in ${data.quoteToken.symbol}`}
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={'$' + millify(data.liquidity?.usd || 0)}
                    secondary="Liquidity"
                />
                <ListItemText
                    primary={millify(data.liquidity?.base || 0)}
                    secondary={data.baseToken.symbol}
                />
                <ListItemText
                    primary={millify(data.liquidity?.quote || 0)}
                    secondary={data.quoteToken.symbol}
                />
            </ListItem>

            <Typography style={{paddingLeft: 15, marginTop: 10, fontWeight: 700}}>Price Change</Typography>
            <ListItem>
                <ListItemText
                    primary={data.priceChange.m5 + "%"}
                    secondary="5 mins"
                    className={getColorClass(data.priceChange.m5)}
                />
                <ListItemText
                    primary={data.priceChange.h1 + "%"}
                    secondary="1 hr"
                    className={getColorClass(data.priceChange.h1)}
                />
                <ListItemText
                    primary={data.priceChange.h6 + "%"}
                    secondary="6 hrs"
                    className={getColorClass(data.priceChange.h6)}
                />
                <ListItemText
                    primary={data.priceChange.h24 + "%"}
                    secondary="24 hrs"
                    className={getColorClass(data.priceChange.h24)}
                />
            </ListItem>

            <Typography style={{paddingLeft: 15, marginTop: 10, fontWeight: 700}}>Volume</Typography>
            <ListItem>
                <ListItemText
                    primary={'$' + millify(data.volume.m5)}
                    secondary="5 mins"
                />
                <ListItemText
                    primary={'$' + millify(data.volume.h1)}
                    secondary="1 hr"
                />
                <ListItemText
                    primary={'$' + millify(data.volume.h6)}
                    secondary="6 hrs"
                />
                <ListItemText
                    primary={'$' + millify(data.volume.h24)}
                    secondary="24 hrs"
                />
            </ListItem>

            <Typography style={{paddingLeft: 15, marginTop: 10, fontWeight: 700}}>Transactions (Buys/Sells)</Typography>
            <ListItem>
                <ListItemText
                    primary={`${data.txns.m5.buys}/${data.txns.m5.sells}`}
                    secondary="5 mins"
                />
                <ListItemText
                    primary={`${data.txns.h1.buys}/${data.txns.h1.sells}`}
                    secondary="1 hr"
                />
                <ListItemText
                    primary={`${data.txns.h6.buys}/${data.txns.h6.sells}`}
                    secondary="6 hrs"
                />
                <ListItemText
                    primary={`${data.txns.h24.buys}/${data.txns.h24.sells}`}
                    secondary="24 hrs"
                />
            </ListItem>
        </Grid>
        <Snackbar
            open={snackbarOpen}
            onClose={() => setSnackBarOpen(false)}
            autoHideDuration={5000}
        >
            <Alert severity="success" sx={{width: '100%'}} variant="filled">Contract Address copied to clipboard</Alert>
        </Snackbar>
    </Grid>
}

function Markets(props) {

    const {data} = props;

    const getColorClass = (value) => {
        if (value === 0) return '';

        return value > 0 ? 'green' : 'red'
    }

    const getDexLabel = (id) => {
        switch (id) {
            case "voltagefinance":
                return "Voltage Finance";
            case "elkfinance":
                return "Elk Finance";
            case "sushiswap":
                return "Sushi Swap";
            case "traderjoe":
                return "Trader Joe";
            case "pangolin":
                return "Pangolin";
            case "swapsicle":
                return "Swapsicle";
            case "yetiswap":
                return "Yeti Swap";
            case "pancakeswap":
                return "Pancake Swap";
            case "uniswap":
                return "Uniswap";
            default:
                return "Unknown DEX";
        }
    }

    return <Grid item xs={12}>
        <Typography style={{paddingLeft: 15, marginTop: 10, fontWeight: 700}}>Markets</Typography>
        <List>

            {
                data.map(pair => {
                    return <ListItem key={pair.pairAddress}>
                        <ListItemText
                            primary={<><span
                                style={{fontWeight: 300}}>{pair.baseToken.symbol}/</span>{pair.quoteToken.symbol}</>}
                            primaryTypographyProps={{fontWeight: 700}}
                            secondary={`${getDexLabel(pair.dexId)} - $${millify(pair.liquidity?.usd || 0)}`}
                        />
                        <ListItemText
                            primary={CoineusUSDFormat(pair.priceUsd)}
                            secondary={pair.priceChange.h24 + "%"}
                            primaryTypographyProps={{textAlign: "right"}}
                            secondaryTypographyProps={{textAlign: "right", color: getColorClass(pair.priceChange.h24)}}
                        />
                    </ListItem>
                })
            }

        </List>
    </Grid>
}