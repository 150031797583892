import * as React from "react";
import {ArcSeries, FlexibleWidthXYPlot} from "react-vis";
import {Paper, Typography, ListItem, ListItemIcon, ListItemText, Skeleton, Grid, Avatar} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    Autorenew,
    Whatshot,
    AccountBalance
} from "@mui/icons-material";
import {CoineusCryptoFormat} from "../utils/currency_format";

const useStyles = makeStyles((theme) => ({
    xyplot: {
        margin: '0 auto',
    },
}));

function BurnSupply(props) {
    const classes = useStyles();

    const {minted, supply, liquidity, fund, litLP, ceusone} = props;

    const divider = 360 * .0075 * (Math.PI / 180);
    const aBurned = 360 * ((minted - supply) / minted) * (Math.PI / 180);

    const aLiquidity = 360 * (liquidity / supply) * (Math.PI / 180);
    const aLitLP = 360 * (litLP / supply) * (Math.PI / 180);
    const aFund = 360 * (fund / supply) * (Math.PI / 180);
    const aCeusone = 360 * (ceusone / supply) * (Math.PI / 180);

    const angles = {

        burnedStart: 0,
        burnedEnd: aBurned,

        liquidityStart: aBurned + divider,
        liquidityEnd: aBurned + aLiquidity,

        litLPStart: aBurned + aLiquidity + divider,
        litLPEnd: aBurned + aLiquidity + aLitLP,

        fundStart: aBurned + aLiquidity + aLitLP + divider,
        fundEnd: aBurned + aLiquidity + aLitLP + aFund,

        ceusoneStart: aBurned + aLiquidity + aLitLP + aFund + divider,
        ceusoneEnd: aBurned + aLiquidity + aLitLP + aFund + aCeusone,

        supplyStart:  aBurned + divider,
        supplyEnd: 360 * (Math.PI / 180) - divider,
    }


    const colors = {
        supply: '#737373',
        burned: '#fa5700',
        fund: '#1c6fe0',
        liquidity: '#fbca00',
        litlp: '#DDDDDD',
        ceusone: '#c5a700',
        eth: '#5F7AE3'
    }

    if (
        supply === undefined ||
        liquidity === undefined
    ) {
        return <Paper style={{padding: 20}}>
            <Skeleton variant="circular" height={180} width={180} style={{margin: '0 auto'}}/>
        </Paper>;
    }

    return (
        <Paper style={{padding: 20}}>
            <FlexibleWidthXYPlot
                xDomain={[-5, 5]}
                yDomain={[-5, 5]}
                height={250}
                margin={{left: 0, right: 0, top: 0, bottom: 0}}
                className={classes.xyplot}
            >
                <ArcSeries
                    radiusDomain={[0, 10]}
                    center={{x: 0, y: 0}}
                    data={[
                        {
                            angle0: angles.supplyStart,
                            angle: angles.supplyEnd,
                            opacity: 1,
                            radius: 6.75,
                            radius0: 6,
                            color: colors.supply,
                        },
                        {
                            angle0: angles.burnedStart,
                            angle: angles.burnedEnd,
                            opacity: 1,
                            radius: 6.75,
                            radius0: 6,
                            color: colors.burned,
                        },
                        {
                            angle0: angles.liquidityStart,
                            angle: angles.liquidityEnd,
                            opacity: 1,
                            radius: 7.5,
                            radius0: 7.25,
                            color: colors.liquidity,
                        },
                        {
                            angle0: angles.fundStart,
                            angle: angles.fundEnd,
                            opacity: 1,
                            radius: 7.5,
                            radius0: 7.25,
                            color: colors.fund,
                        },
                        {
                            angle0: angles.litLPStart,
                            angle: angles.litLPEnd,
                            opacity: 1,
                            radius: 7.5,
                            radius0: 7.25,
                            color: colors.litlp,
                        },
                        {
                            angle0: angles.ceusoneStart,
                            angle: angles.ceusoneEnd,
                            opacity: 1,
                            radius: 7.5,
                            radius0: 7.25,
                            color: colors.ceusone,
                        }
                    ]}
                    colorType={'literal'}
                />
            </FlexibleWidthXYPlot>

            <Typography
                style={{
                    height: 0,
                    width: '100%',
                    textAlign: 'center',
                    top: -180,
                    position: 'relative',
                    fontSize: 26,
                    fontWeight: '700'
                }}
            >
                {(((minted - supply) / minted) * 100).toFixed(2)}
                <span
                    style={{
                        fontSize: 12,
                        fontWeight: '400',
                        marginTop: -10,
                        display: 'block',
                    }}
                >
          % BURNED
        </span>
            </Typography>

            <Typography
                style={{
                    height: 0,
                    width: '100%',
                    textAlign: 'center',
                    top: -130,
                    position: 'relative',
                    fontSize: 26,
                    fontWeight: '700',
                    color: colors.ceusone
                }}
            >
                {((ceusone / supply) * 100).toFixed(2)}
                <span
                    style={{
                        fontSize: 12,
                        fontWeight: '400',
                        marginTop: -10,
                        display: 'block',
                    }}
                >
          % DEPOSITED
        </span>
            </Typography>

            <Grid container>

                <Grid item xs={6}>
                    <StatListItem icon={<Autorenew style={{color: colors.supply}}/>} color={colors.supply}
                                  value={supply}
                                  label="SUPPLY"/>
                </Grid>
                <Grid item xs={6}>
                    <StatListItem icon={<Whatshot style={{color: colors.burned}}/>} color={colors.burned}
                                  value={minted - supply}
                                  label="BURNED"/>
                </Grid>
                <Grid item xs={6}>
                    <StatListItem icon={<Avatar
                        src={`https://coineus.app/assets/tokens/fuse/0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8/logo.png`} sx={{ width: 24, height: 24 }}/>}
                                  color={colors.liquidity} value={liquidity}
                                  label="CEUS LP"/>
                </Grid>
                <Grid item xs={6}>
                    <StatListItem icon={<Avatar
                        src={`https://coineus.app/assets/tokens/fuse/0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F/logo.png`} sx={{ width: 24, height: 24 }}/>} color={colors.litlp} value={litLP}
                                  label="LIT LP"/>
                </Grid>
                <Grid item xs={6}>
                    <StatListItem icon={<AccountBalance style={{color: colors.fund}}/>} color={colors.fund} value={fund}
                                  label="FUND"/>
                </Grid>
                <Grid item xs={6}>
                    <StatListItem icon={<Avatar
                        src={`https://coineus.app/assets/tokens/fuse/0xE27431FACb1F093BA164c9F86B3f484b39ad54CC/logo.png`} sx={{ width: 24, height: 24 }}/>} color={colors.ceusone} value={ceusone}
                                  label="CEUSONE"/>
                </Grid>
            </Grid>

        </Paper>
    );
}

export default BurnSupply;

function StatListItem(props) {
    const {color, value, label, icon} = props;

    return <ListItem disablePadding>
        <ListItemIcon style={{paddingLeft: 15}}>
            {icon}
        </ListItemIcon>
        <ListItemText
            primary={CoineusCryptoFormat(value)}
            secondary={label}
            primaryTypographyProps={{fontWeight: 700, style: {color}}}
            secondaryTypographyProps={{fontSize: '.75rem', style: {color}}}
        />
    </ListItem>;
}
