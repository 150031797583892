import {createTheme} from "@mui/material";


const theme = createTheme({
    typography: {
        fontFamily: `'Quicksand', sans-serif`
    },
    palette: {
        mode: 'dark',
        background: {
            default: "#000"
        },
        primary: {
            main: '#fbca00'
        }
    }
});

export default theme;