import {useSelector} from "react-redux";
import Web3 from "web3";
import farm_abi from "../abis/MultiRewards.json";
import {useEffect, useState} from "react";
import {makeBatchRequest} from "../utils/promisify";

import farm_config from "../utils/farmContracts.json";

export default function useTaskRewardsInfo(TASK_REWARDS_CONTRACT) {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const web3 = new Web3(rpc.fuse);

    const task_rewards_contract = new web3.eth.Contract(farm_abi, TASK_REWARDS_CONTRACT);

    const [taskRewardsInfo, setTaskInfo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getTaskInfo() {
            if (!wallet.address) return;

            const rewardTokens = farm_config[TASK_REWARDS_CONTRACT].reward_tokens;

            const calls = [
                task_rewards_contract.methods.totalSupply().call,
                task_rewards_contract.methods.balanceOf(wallet.address).call
            ]

            for(let i=0; i<rewardTokens.length; i++){
                const ca = rewardTokens[i].contract_address;
                calls.push(task_rewards_contract.methods.rewardData(ca).call);
                calls.push(task_rewards_contract.methods.earned(wallet.address, ca).call);
                calls.push(task_rewards_contract.methods.getRewardForDuration(ca).call);
            }

            makeBatchRequest(web3, calls).then(resp => {

                const [
                        totalStaked,
                        stakedAmount
                    ] = resp;

                let respIndex = 2;

                for(let i=0; i<rewardTokens.length; i++){
                    const secondsLeft = calcSecsLeft(resp[respIndex].periodFinish);
                    const rewardsRemaining = secondsLeft * resp[respIndex].rewardRate / (10 ** rewardTokens[i].decimals);
                    const potentialEarnings = rewardsRemaining * (stakedAmount / totalStaked);
                    rewardTokens[i].data = {
                        rewardData: resp[respIndex],
                        earned: resp[respIndex+1] / (10 ** rewardTokens[i].decimals),
                        rewardsForDuration: resp[respIndex+2] / (10 ** rewardTokens[i].decimals),
                        potentialEarnings,
                        rewardsRemaining
                    };
                    respIndex+=3;
                }

                const tmp = {
                    stakedAmount: stakedAmount / (10 ** 18),
                    rewardTokens,
                }

                setTaskInfo(tmp);
                setLoading(false);
            })
        }

        getTaskInfo();
        const interval = setInterval(getTaskInfo, 10000)
        return () => clearInterval(interval);
    }, [TASK_REWARDS_CONTRACT, wallet.address])

    return {taskRewardsInfo, loading};
}

const calcSecsLeft = (periodFinish) => {
    const left = parseInt(((periodFinish * 1000) - new Date().getTime()) / 1000);
    return left > 0 ? left : 0;
}