import {Whatshot} from '@mui/icons-material';
import { AssetBackingOutlined } from "../icons/AssetBackingIcon";
import {
    Avatar,
    Button, Container,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField
} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import CoineusLoading from "../CoineusLoading";
import ASSET_BACKING_ABI from "../../abis/Coineus/ASSET_BACKING.json";
import Web3 from "web3";
import {makeBatchRequest} from "../../utils/promisify";
import {toWei} from "../../utils/format";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../../utils/currency_format";
import {NetworkButton} from "../Coineus";
import {CHAIN_IDS} from "../../constants";
import IERC20_ABI from "../../abis/IERC20.json";
import getUserBalances from "../../utils/get_balances";

const FVKSRG_AB_CONTRACT = "0x007f233638d0E75E373Bb95e6c16Ba91B34fBAfA";


export default function FVKSRGAssetBacking(props) {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [open, setOpen] = useState(false);
    const [pending, setPending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [amount, setAmount] = useState("");
    const [data, setData] = useState({});

    const getBackingInfo = () => {
        if (!open) return;

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(ASSET_BACKING_ABI, FVKSRG_AB_CONTRACT);

        const calls = [
            contract.methods.getBackingPerToken().call,
            contract.methods.getEligibleSupply().call,
            contract.methods.getONEWRAPBalance().call,
            contract.methods.MAIN_TOKEN().call,
            contract.methods.ONEWRAP().call,
            contract.methods.UNWRAPPED().call,
        ]

        makeBatchRequest(web3, calls).then(resp => {
            const [backingPerToken, eligibleSupply, ONEWRAPBalance, MAIN_TOKEN, ONEWRAP, UNWRAPPED] = resp;

            const contract_main = new web3.eth.Contract(IERC20_ABI, MAIN_TOKEN)
            const contract_onewrap = new web3.eth.Contract(IERC20_ABI, ONEWRAP)
            const contract_unwrapped = new web3.eth.Contract(IERC20_ABI, UNWRAPPED)
            const calls2 = [
                contract_main.methods.name().call,
                contract_main.methods.symbol().call,
                contract_main.methods.decimals().call,
                contract_onewrap.methods.name().call,
                contract_onewrap.methods.symbol().call,
                contract_onewrap.methods.decimals().call,
                contract.methods.getBackingAmount(toWei(wallet.balances.fuse[MAIN_TOKEN])).call,
                contract_unwrapped.methods.balanceOf(FVKSRG_AB_CONTRACT).call
            ]

            makeBatchRequest(web3, calls2).then(resp => {
                const [main_name, main_symbol, main_decimals, onewrap_name, onewrap_symbol, onewrap_decimals, userTotalBacking, unwrappedBalance] = resp;

                setData({
                    userTotalBacking: userTotalBacking / (10 ** 18),
                    backingPerToken: backingPerToken / (10 ** 18),
                    eligibleSupply: eligibleSupply / (10 ** 18),
                    ONEWRAPBalance: ONEWRAPBalance / (10 ** 18),
                    MAIN_TOKEN,
                    ONEWRAP,
                    main_name,
                    main_symbol,
                    main_decimals,
                    onewrap_name,
                    onewrap_symbol,
                    onewrap_decimals,
                    market_factor: wallet.prices.fuse?.[MAIN_TOKEN]?.inBase / (backingPerToken / (10 ** 18)),
                    unwrappedBalance: unwrappedBalance / (10 ** 18)
                });
                setLoading(false);
            })
        })

    }

    useEffect(() => {
        getBackingInfo();
        const interval = setInterval(getBackingInfo, 30000)
        return () => clearInterval(interval);
    }, [open, wallet.prices]);

    const killForBacking = () => {
        if (wallet.address) {

            const web3 = new Web3(window.provider);
            const main_contract = new web3.eth.Contract(IERC20_ABI, data.MAIN_TOKEN);
            const contract = new web3.eth.Contract(ASSET_BACKING_ABI, FVKSRG_AB_CONTRACT);

            setPending(true);
            main_contract.methods
                .allowance(wallet.address, FVKSRG_AB_CONTRACT)
                .call((err, resp) => {

                    if (err) return;

                    if (resp < toWei(amount)) {

                        main_contract
                            .methods
                            .approve(FVKSRG_AB_CONTRACT, toWei(amount))
                            .send({from: wallet.address})
                            .once('transactionHash', function (tx) {
                                setPending(true)
                            })
                            .on('error', function (error) {
                                setPending(false)
                            })
                            .then(function (receipt) {
                                setPending(false)
                                kill()
                            });
                    } else {
                        kill()
                    }

                })

            const kill = () => {
                contract.methods.killForBacking(toWei(amount))
                    .send({from: wallet.address})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        getBackingInfo();
                        getUserBalances();
                        setAmount(0);
                        setPending(false);
                    });
            }

        }
    }

    const depositCEUStoCEUSONE = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(ASSET_BACKING_ABI, FVKSRG_AB_CONTRACT);

            contract.methods.depositUNWRAPPEDtoONEWRAP()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getBackingInfo();
                    getUserBalances();
                    setPending(false);
                });
        }

    }

    const ceus_price_usd = wallet.prices.fuse?.[data.ONEWRAP]?.inUSD;
    const lit_price_usd = wallet.prices.fuse?.[data.MAIN_TOKEN]?.inUSD;
    const lit_price_ceus = wallet.prices.fuse?.[data.MAIN_TOKEN]?.inBase;

    if (open && loading) return <CoineusLoading open={loading} label="Loading Data"/>

    return <>

        <IconButton
            disabled={(wallet.chain !== 122)}
            edge="end"
            onClick={() => setOpen(true)}
            color="primary"
        >
            <AssetBackingOutlined/>
        </IconButton>

        <Dialog open={open} fullScreen={window.innerWidth <= 400}>
            <Paper style={{height: '100%'}}>
                <Container maxWidth="xs">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            src={`https://coineus.app/assets/tokens/fuse/${data.MAIN_TOKEN}/logo.png`}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(data.eligibleSupply)} ${data.main_symbol}`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="Eligible Supply"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar/>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(wallet.balances.fuse[data.MAIN_TOKEN])} ${data.main_symbol} (${CoineusUSDFormat(wallet.balances.fuse[data.MAIN_TOKEN] * lit_price_usd)})`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="User Balance"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            src={`https://coineus.app/assets/tokens/fuse/${data.ONEWRAP}/logo.png`}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(data.ONEWRAPBalance)} ${data.onewrap_symbol}`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="Total Backing"
                                    />
                                </ListItem>

                                {
                                    data.unwrappedBalance > 0 &&
                                    <ListItem>
                                        <ListItemAvatar/>
                                        <ListItemText
                                            primary={`${CoineusCryptoFormat(data.unwrappedBalance)} CEUS`}
                                            primaryTypographyProps={{fontWeight: 700}}
                                            secondary="Pending Deposit"
                                        />
                                        <NetworkButton network={CHAIN_IDS.FUSE}>
                                            <Button onClick={depositCEUStoCEUSONE}>
                                                Deposit
                                            </Button>
                                        </NetworkButton>
                                    </ListItem>
                                }
                                <ListItem>
                                    <ListItemAvatar/>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(data.userTotalBacking)} ${data.onewrap_symbol} (${CoineusUSDFormat(data.userTotalBacking * ceus_price_usd)})`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="User Total Backing"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar/>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(lit_price_ceus)} ${data.onewrap_symbol}`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="Market Price"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar/>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(data.backingPerToken)} ${data.onewrap_symbol}`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="Backing Per Token"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar/>
                                    <ListItemText
                                        primary={`${data.market_factor?.toFixed(2)}x`}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        secondary="Market-to-Backing Factor"
                                    />
                                </ListItem>


                                <ListItem>
                                    <FormControl fullWidth style={{marginBottom: 20}}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            value={amount}
                                            placeholder="Enter Amount"
                                            onChange={(ev) => {
                                                setAmount(ev.target.value)
                                            }}
                                        />
                                    </FormControl>
                                </ListItem>

                                <ListItem>
                                    <ListItemText
                                        primary={`${amount} ${data.main_symbol}`}
                                        secondary={CoineusUSDFormat(amount * lit_price_usd)}
                                        primaryTypographyProps={{fontWeight: 700}}
                                        style={{flex: 1, maxWidth: '40%'}}
                                    />
                                    <ListItemAvatar style={{flex: 1, maxWidth: 0}}>
                                        <Avatar style={{backgroundColor: 'orange', margin: 0}}><Whatshot/></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${CoineusCryptoFormat(amount * data.backingPerToken)} ${data.onewrap_symbol}`}
                                        secondary={CoineusUSDFormat(amount * data.backingPerToken * ceus_price_usd)}
                                        primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                                        secondaryTypographyProps={{textAlign: 'right'}}
                                        style={{flex: 1}}
                                    />
                                </ListItem>


                                <ListItem style={{marginTop: 40}}>
                                    <NetworkButton network={CHAIN_IDS.FUSE}>
                                        <Button variant="contained" fullWidth onClick={killForBacking}>Get
                                            Backing</Button>
                                    </NetworkButton>
                                </ListItem>
                                <ListItem>
                                    <NetworkButton network={CHAIN_IDS.FUSE}>
                                        <Button variant="contained" fullWidth
                                                onClick={() => setOpen(false)}>Cancel</Button>
                                    </NetworkButton>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </Dialog>

        <CoineusLoading open={pending} label="Transaction Pending"/>

    </>

}