import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText
} from "@mui/material";

import AnimatedPriceWidget from "./AnimatedPriceWidget";

const updateTimeout = 10000;

export default function RewardsSummary(props) {
    const {reward_token, network} = props;

    const {
        earned
    } = reward_token.data;

    if(earned <= 0) return <></>;

    return <>
        {
            earned > 0 && <ListItem>
                <ListItemAvatar style={{minWidth: 30}}>
                    <Avatar
                        src={`https://coineus.app/assets/tokens/${network}/${reward_token.contract_address}/logo.png`}
                        sx={{width: 24, height: 24}}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={reward_token.symbol}
                    primaryTypographyProps={{variant: 'body2'}}
                />
                <ListItemText
                    primary={<AnimatedPriceWidget fixed={8} time={updateTimeout} price={earned}/>}
                    primaryTypographyProps={{variant: 'body2', textAlign: "right", fontFamily: 'monospace'}}
                />
            </ListItem>
        }
    </>
}