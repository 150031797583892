import React from 'react';
import ReactDOM from 'react-dom';
import Coineus from "./Coineus";
import {Provider} from "react-redux";
import store from "./redux/store";
const TWEEN = require('@tweenjs/tween.js')

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Coineus/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

function animate() {
    requestAnimationFrame(animate)
    TWEEN.update()
}

animate();