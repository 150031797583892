import {useSelector} from "react-redux";
import {
    Button,
    Dialog,
    Grid,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    IconButton,
    List,
    Tabs,
    Tab,
    Box,
    FormControl,
    TextField, InputAdornment,
} from "@mui/material";

import Web3 from "web3";
import coineus_abi from '../../abis/ceus_abi.json';
import coineusone_abi from '../../abis/Coineus/CEUSONE.json';

import {useState} from "react";

import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';

import {toWei} from "../../utils/format";
import CoineusLoading from "../CoineusLoading";
import {CoineusCryptoFormat} from "../../utils/currency_format";

const CEUS_CONTRACT_ADDRESS = "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"
const CEUSONE_CONTRACT_ADDRESS = "0xE27431FACb1F093BA164c9F86B3f484b39ad54CC"

export default function CEUSONE(props) {

    const {wallet} = useSelector(state => state.coineus);

    const {onSuccess} = props;

    const [open, setOpen] = useState(false);
    const [pending, setPending] = useState(false);
    const [amount, setAmount] = useState("");
    const [action, setAction] = useState('deposit');

    const deposit = async () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const coineus_contract = new web3.eth.Contract(coineus_abi, CEUS_CONTRACT_ADDRESS);
            const contract = new web3.eth.Contract(coineusone_abi, CEUSONE_CONTRACT_ADDRESS);


            coineus_contract
                .methods
                .allowance(wallet.address, CEUSONE_CONTRACT_ADDRESS)
                .call((err, resp) => {

                    if (err) return;

                    if ((resp / (10 ** 18)) < amount) {
                        coineus_contract
                            .methods
                            .approve(CEUSONE_CONTRACT_ADDRESS, toWei(2000000))
                            .send({from: wallet.address, gasPrice: 10000000000})
                            .once('transactionHash', function (tx) {
                                setPending(true)
                            })
                            .on('error', function (error) {
                                setPending(false)
                            })
                            .then(function (receipt) {
                                setPending(false)
                                deposit()
                            });
                    } else {
                        deposit()
                    }
                })

            const deposit = () => {
                contract.methods.deposit(toWei(amount))
                    .send({from: wallet.address, gasPrice: 10000000000})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        onSuccess();
                        setAmount('');
                        setOpen(false);
                        setPending(false);
                    });
            }
        }
    }

    const withdraw = async () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(coineusone_abi, CEUSONE_CONTRACT_ADDRESS);


            contract
                .methods
                .allowance(wallet.address, CEUSONE_CONTRACT_ADDRESS)
                .call((err, resp) => {

                    if (err) return;

                    if ((resp / (10 ** 18)) < amount) {
                        contract
                            .methods
                            .approve(CEUSONE_CONTRACT_ADDRESS, toWei(2000000))
                            .send({from: wallet.address, gasPrice: 10000000000})
                            .once('transactionHash', function (tx) {
                                setPending(true)
                            })
                            .on('error', function (error) {
                                setPending(false)
                            })
                            .then(function (receipt) {
                                setPending(false)
                                withdraw()
                            });
                    } else {
                        withdraw()
                    }
                })

            const withdraw = () => {
                contract.methods.withdraw(toWei(amount))
                    .send({from: wallet.address, gasPrice: 10000000000})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        onSuccess();
                        setAmount('');
                        setOpen(false);
                        setPending(false);
                    });
            }
        }
    }

    return <>

        <IconButton
            disabled={
                wallet.chain !== 122
            }
            edge="end"
            onClick={() => setOpen(true)}
            color="primary"
        >
            <SwapVerticalCircleIcon/>
        </IconButton>

        <Dialog open={open} fullWidth maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Tabs
                            variant="fullWidth"
                            value={action}
                            onChange={(ev, val) => {
                                setAction(val)
                                setAmount('')
                            }}
                        >
                            <Tab id="deposit" value="deposit" label="Deposit"/>
                            <Tab id="withdrawal" value="withdrawal" label="Withdrawal"/>
                        </Tabs>
                        {
                            action === "deposit" && <Box style={{padding: 20}}>
                                <List>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemText
                                            secondary="Balance"
                                        />
                                        <ListItemText
                                            secondary={`${CoineusCryptoFormat(wallet.balances.fuse[CEUS_CONTRACT_ADDRESS])} CEUS`}
                                            secondaryTypographyProps={{textAlign: 'right'}}
                                        />
                                    </ListItem>
                                </List>
                                <FormControl fullWidth style={{marginBottom: 20}}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        value={amount}
                                        placeholder="Enter Amount"
                                        onChange={(ev) => {
                                            setAmount(ev.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Button variant="contained" onClick={() => {
                                                    setAmount(wallet.balances.fuse[CEUS_CONTRACT_ADDRESS])
                                                }
                                                }>MAX</Button>
                                            </InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <Button fullWidth variant="contained"
                                        disabled={!amount || amount > wallet.balances.fuse[CEUS_CONTRACT_ADDRESS]}
                                        onClick={deposit}>Deposit</Button>
                            </Box>
                        }

                        {
                            action === "withdrawal" && <Box style={{padding: 20}}>
                                <List>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemText
                                            secondary="Balance"
                                        />
                                        <ListItemText
                                            secondary={`${CoineusCryptoFormat(wallet.balances.fuse[CEUSONE_CONTRACT_ADDRESS])} CEUSONE`}
                                            secondaryTypographyProps={{textAlign: 'right'}}
                                        />
                                    </ListItem>
                                </List>
                                <FormControl fullWidth style={{marginBottom: 20}}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        value={amount}
                                        placeholder="Enter Amount"
                                        onChange={(ev) => {
                                            if (ev.target.value > wallet.balances.fuse[CEUSONE_CONTRACT_ADDRESS]) return;
                                            setAmount(ev.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Button variant="contained" onClick={() => {
                                                    setAmount(wallet.balances.fuse[CEUSONE_CONTRACT_ADDRESS])
                                                }
                                                }>MAX</Button>
                                            </InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <Button fullWidth variant="contained"
                                        disabled={!amount || amount > wallet.balances.fuse[CEUSONE_CONTRACT_ADDRESS]}
                                        onClick={withdraw}>Withdrawal</Button>
                            </Box>
                        }
                        <Box style={{padding: 20, paddingTop: 0}}>

                            <Button fullWidth
                                    onClick={() => setOpen(false)}>Cancel</Button>
                        </Box>

                        <Box style={{padding: 20, paddingTop: 0, textAlign: 'center'}}>
                            <Typography variant="caption" color="primary">1% Fee on Deposits and
                                Withdrawals</Typography>
                        </Box>

                    </Paper>
                </Grid>

            </Grid>
        </Dialog>

        <CoineusLoading open={pending} label="Transaction Pending"/>
    </>
}
