import {
    Alert,
    Avatar, Button,
    Grid, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Paper, Skeleton, Snackbar,
} from "@mui/material";
import LightningTasks from "../components/LightningTasks";
import farm_abi from "../abis/MultiRewards.json";
import {useSelector} from "react-redux";
import Web3 from "web3";
import {useEffect, useState} from "react";
import RewardsSummary from "../components/RewardsSummary";
import {NetworkButton} from "../components/Coineus";
import {CHAIN_IDS} from "../constants";
import RewardsAllTime from "../components/RewardsAllTime";
import {CoineusCryptoFormat} from "../utils/currency_format";
import getUserBalances from "../utils/get_balances";
import CoineusLoading from "../components/CoineusLoading";
import useTaskRewardsInfo from "../hooks/useTaskRewardsInfo";

const rewardsAddress = '0x184BbAc8764b24b0Ae4e5E504BEa7580B2E0E28f';

export default function Tasks() {
    const {wallet, rpc} = useSelector(state => state.coineus);
    const [rewards, setRewards] = useState({});

    const [pending, setPending] = useState(false);
    const [snackBarError, setSnackBarError] = useState(null);


    const {taskRewardsInfo, loading} = useTaskRewardsInfo(rewardsAddress);

    // get rewards all-time
    useEffect(() => {

        if (!wallet.address) return;

        const web3 = new Web3(rpc.fuse);
        const farm_contract = new web3.eth.Contract(farm_abi, rewardsAddress);

        farm_contract
            .getPastEvents('RewardPaid', {
                fromBlock: 23985459,
                filter: {user: [wallet.address]}
            })
            .then(events => {
                let r = {};
                events.map(ev => {
                    if (!r[ev.returnValues.rewardsToken]) {
                        r[ev.returnValues.rewardsToken] = 0;
                    }
                    r[ev.returnValues.rewardsToken] += (ev.returnValues.reward / 1);
                    return ev;
                })
                setRewards(r)
            })

    }, [wallet.address]);

    if (loading || !taskRewardsInfo) {
        return <Grid item xs={12}>
            <Paper style={{padding: 20}}>
                <Skeleton/>
                <Skeleton/>
            </Paper>
        </Grid>
    }

    const getReward = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const farm_contract = new web3.eth.Contract(farm_abi, rewardsAddress);
            farm_contract.methods.getReward()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setSnackBarError(error.receipt.error);
                    setPending(false)
                })
                .then(function (receipt) {
                    getUserBalances(wallet.address);
                    setPending(false);
                });
        }
    }

    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        {
            taskRewardsInfo.stakedAmount > 0 && <Grid item xs={12}>
                <Paper>
                    <List dense>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    src={`https://coineus.app/assets/tokens/fuse/logo.png`}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={CoineusCryptoFormat(taskRewardsInfo.stakedAmount)}
                                secondary="FUSE Donated to Tasks"/>
                        </ListItem>

                        {
                            taskRewardsInfo.rewardTokens.map(rt => {
                                return <RewardsSummary
                                    key={rt.contract_address}
                                    reward_token={rt}
                                    network='fuse'
                                />
                            })
                        }
                        <ListItem>
                            <NetworkButton network={CHAIN_IDS.FUSE}>
                                <Button
                                    onClick={() => getReward()}>Claim Rewards</Button>
                            </NetworkButton>
                        </ListItem>

                        <ListSubheader>Rewards Claimed All-time</ListSubheader>
                        {
                            taskRewardsInfo.rewardTokens.map(rt => {
                                return <RewardsAllTime
                                    key={rt.contract_address}
                                    reward_token={rt}
                                    network='fuse'
                                    rewards={rewards}
                                />
                            })
                        }
                    </List>
                </Paper>
            </Grid>
        }

        {/*BUY ADD TO CEUS LP*/}
        <LightningTasks
            contract_address="0x4dD6A8a23e44CdF00b456Bcc7f4C6a1bf9b94b06"
            description="Buys CEUS and adds to LP"
        />

        {/*BUY ADD TO LIT LP*/}
        <LightningTasks
            contract_address="0x11E01ce20Bb0414c6a41A401Cc51Ab770e713Df0"
            description="Buys LIT and adds to LP"
        />

        {/*BUY ADD TO FVKSRG LP*/}
        <LightningTasks
            contract_address="0xbA9F590C024e90bcc51Ac419075644EAF8EB7AA8"
            description="Buys FVKSRG and adds to LP"
        />

        {/*BUY ADD TO FOO LP*/}
        <LightningTasks
            contract_address="0xF0befF28675Ec7a8A68F641fd5dfD6C9C25aCd9d"
            description="Buys FOO and adds to LP"
        />


        <Snackbar
            open={snackBarError != null}
            onClose={() => setSnackBarError(null)}
            autoHideDuration={10000}
        >
            <Alert severity="error" sx={{width: '100%'}} variant="filled">{snackBarError}</Alert>
        </Snackbar>
        <CoineusLoading open={pending} label="Transaction Pending"/>

    </Grid>
}
