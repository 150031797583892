import {Link as RouterLink} from "react-router-dom";
import {
    Avatar,
    Grid, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Skeleton
} from "@mui/material";
import {CoineusUSDFormat} from "../utils/currency_format";
import useEarnInfo from "../hooks/useEarnInfo";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useSelector} from "react-redux";

export default function Earn() {

    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>

        {/*FVKSRG*/}
        <EarnSummary
            contract_address="0xAEC89F494E9afd1978590d2cD5Dcc4067bB8789D"
            network="fuse"
        />

        {/*FOO*/}
        <EarnSummary
            contract_address="0x225b81A6aCaF6448d74e30190375b12970D5Fa30"
            network="fuse"
        />

    </Grid>
}

function EarnSummary(props) {

    const {contract_address, network} = props;

    const {farmInfo, loading} = useEarnInfo(contract_address, network);

    const {wallet} = useSelector(state => state.coineus);

    if(loading){
        return <Grid item xs={12}>
            <Paper style={{padding:20}}>
                <Skeleton />
                <Skeleton />
            </Paper>
        </Grid>
    }

    const pricePerToken = wallet.prices[network][farmInfo.stakingToken].inUSD;

    return <Grid item xs={12}>
        <Paper style={{
            borderLeft: farmInfo.stakedAmount > 0 ? '2px solid #fbca00' : '2px solid black'
        }}>
            <List>
                <ListItem
                    secondaryAction={
                        <IconButton
                            style={{right: -10}}
                            color="primary"
                            component={RouterLink} to={`/earn/${network}/${contract_address}`}
                        ><NavigateNextIcon/></IconButton>
                    }
                >
                    <ListItemAvatar style={{marginRight: 10}}>
                        <Avatar src={`https://coineus.app/assets/tokens/${network}/${farmInfo.token0}/logo.png`}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={CoineusUSDFormat(farmInfo.totalStaked * pricePerToken)}
                        secondary="Total Value Staked"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={farmInfo.apy.toFixed(2) + "%"}
                        secondary="Est. APY"
                        primaryTypographyProps={{textAlign:'right', fontWeight: 700}}
                        secondaryTypographyProps={{textAlign:'right'}}
                    />
                </ListItem>
            </List>
        </Paper>
    </Grid>
}