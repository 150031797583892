import {Alert, Button, Divider, Grid, ListItem, ListItemText, Paper, TextField} from "@mui/material";

import faucet_abi from '../../abis/Coineus/CEUSONEFaucet.json';
import {useSelector} from "react-redux";
import Web3 from "web3";
import {useEffect, useState} from "react";
import {makeBatchRequest} from "../../utils/promisify";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../../utils/currency_format";
import {formatWeiToNumber, toWei} from "../../utils/format";
import CoineusLoading from "../../components/CoineusLoading";
import coineus_abi from "../../abis/ceus_abi.json";
import {CHAIN_IDS} from "../../constants";
import {NetworkButton} from "../../components/Coineus";

const FAUCET_ADDRESS = '0x3E4a3A2459A2Fa02544E792D728917C708872922';
const CEUSONE_ADDRESS = '0xE27431FACb1F093BA164c9F86B3f484b39ad54CC';
const LIT_ADDRESS = '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F';

export default function CEUSONEFaucet() {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [pending, setPending] = useState(false);
    const [faucetData, setFaucetData] = useState(undefined);
    const [userFaucetData, setUserFaucetData] = useState(undefined);
    const [disabled, setDisabled] = useState(false);
    const [disabledReason, setDisabledReason] = useState(undefined);

    //ADMIN
    const [_amountToIncreaseBy, _setAmountToIncreaseBy] = useState(0);
    const [_maxAmountToIncreaseTo, _setMaxAmountToIncreaseTo] = useState(0);
    const [_minAmountForFastpass, _setMinAmountForFastpass] = useState(0);
    const [_minAmountToClaim, _setMinAmountToClaim] = useState(0);
    const [_timeBetweenClaims, _setTimeBetweenClaims] = useState(0);
    const [_depositAmount, _setDepositAmount] = useState(0);

    const web3FUSE = new Web3(rpc.fuse);
    const faucet_contract = new web3FUSE.eth.Contract(faucet_abi, FAUCET_ADDRESS);

    const getInfo = () => {
        const calls = [
            faucet_contract.methods.getClaimableAmount().call,
            faucet_contract.methods.getStats().call,
            faucet_contract.methods.getLockedAmount().call,
            faucet_contract.methods.getClaimPaused().call,
            faucet_contract.methods.getMinAmountToClaim().call,
            faucet_contract.methods.getMinAmountForFastpass().call,
            faucet_contract.methods.getAmountToIncreaseBy().call,
            faucet_contract.methods.getMaxAmountToIncreaseTo().call,
            faucet_contract.methods.getTimeBetweenClaims().call,

        ]

        makeBatchRequest(web3FUSE, calls).then(resp => {
            let [claimable, stats, lockedAmount,

                paused, minBalance, fastpassAmount, amountToIncreaseBy, maxAmountToIncreaseTo, timeBetweenClaims] = resp;

            setFaucetData({
                claimable, stats, lockedAmount,

                paused, minBalance, fastpassAmount
            })

            _setAmountToIncreaseBy(formatWeiToNumber(amountToIncreaseBy))
            _setMaxAmountToIncreaseTo(formatWeiToNumber(maxAmountToIncreaseTo))
            _setMinAmountForFastpass(formatWeiToNumber(fastpassAmount))
            _setMinAmountToClaim(formatWeiToNumber(minBalance))
            _setTimeBetweenClaims(timeBetweenClaims / 60 / 60 / 24)

        })
    }

    const getUserInfo = () => {

        if (!wallet.address) {
            return;
        }

        const calls = [faucet_contract.methods.getStatsByAddress(wallet.address).call]

        makeBatchRequest(web3FUSE, calls).then(resp => {
            let [stats] = resp;

            setUserFaucetData({
                stats
            })

        })
    }

    //update disabled status
    useEffect(() => {

        if (!wallet.address) {
            setDisabled(true)
            setDisabledReason(`Connect wallet to claim`)
            return;
        }

        if (wallet.chain !== 122) {
            setDisabled(true)
            setDisabledReason(`Connect wallet to Fuse Network`)
            return;
        }

        if (faucetData && faucetData.paused) {

            setDisabled(true);
            setDisabledReason(`Claiming is paused`);
            return;
        }

        if (faucetData && wallet.balances.fuse[LIT_ADDRESS] < (faucetData.minBalance / (10 ** 18))) {

            setDisabled(true);
            setDisabledReason(`You need to hold at least ${(faucetData.minBalance / (10 ** 18))} LIT to claim`);
            return;
        }

        const now = (new Date()).getTime();
        if (userFaucetData && userFaucetData.stats && userFaucetData.stats.timeCanClaim > parseInt(now / 1000)) {
            setDisabled(true);
            setDisabledReason(`You can claim again at ${new Date(userFaucetData.stats.timeCanClaim * 1000).toLocaleString()}`);
            return true;
        }

        setDisabled(false);
        setDisabledReason(undefined);

    }, [wallet.address, wallet.chain, faucetData, userFaucetData])

    useEffect(() => {
        getInfo();
        const interval = setInterval(getInfo, 30000)
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getUserInfo();
        const interval = setInterval(getUserInfo, 30000)
        return () => clearInterval(interval);
    }, []);

    const claim = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods
                .claim()
                .send({
                    from: wallet.address, gasPrice: 10000000000
                })
                .once('transactionHash', function (tx) {
                    setPending(true)
                    console.log(tx)
                })
                .on('error', function (error) {
                    setPending(false)
                    console.log(error);
                })
                .then(function (receipt) {
                    getInfo();
                    getUserInfo();
                    setPending(false);
                });

        }
    }

    //ADMIN
    const toggleFaucet = (state) => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods.setClaimPaused(state)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo()
                    setPending(false);
                });
        }
    }

    const setAmountToIncreaseBy = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods.setAmountToIncreaseBy(_amountToIncreaseBy)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo()
                    setPending(false);
                });
        }
    }

    const setMaxAmountToIncreaseTo = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods.setMaxAmountToIncreaseTo(_maxAmountToIncreaseTo)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo()
                    setPending(false);
                });
        }
    }

    const setMinAmountForFastpass = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods.setMinAmountForFastpass(_minAmountForFastpass)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo()
                    setPending(false);
                });
        }
    }

    const setMinAmountToClaim = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods.setMinAmountToClaim(_minAmountToClaim)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo()
                    setPending(false);
                });
        }
    }

    const setTimeBetweenClaims = () => {
        if (wallet.address) {
            setPending(true)
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            contract.methods.setTimeBetweenClaims(_timeBetweenClaims)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo()
                    setPending(false);
                });
        }
    }

    const deposit = () => {

        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const ceusone_contract = new web3.eth.Contract(coineus_abi, CEUSONE_ADDRESS);
            const contract = new web3.eth.Contract(faucet_abi, FAUCET_ADDRESS);

            ceusone_contract
                .methods
                .allowance(wallet.address, FAUCET_ADDRESS)
                .call((err, resp) => {

                    if (err) return;

                    if ((resp / (10 ** 18)) < _depositAmount) {
                        ceusone_contract
                            .methods
                            .approve(FAUCET_ADDRESS, toWei(_depositAmount))
                            .send({from: wallet.address})
                            .once('transactionHash', function (tx) {
                                setPending(true)
                            })
                            .on('error', function (error) {
                                setPending(false)
                            })
                            .then(function (receipt) {
                                setPending(false)
                                deposit()
                            });
                    } else {
                        deposit()
                    }
                })

            const deposit = () => {
                contract.methods.deposit(toWei(_depositAmount))
                    .send({from: wallet.address})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        getInfo()
                        setPending(false);
                    });
            }
        }
    }

    return (<Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        {wallet.address?.toLowerCase() === '0xb80267ea7fa368374ee4d4bf10044778232adefe' && faucetData !== undefined &&
            <Grid item xs={12}>
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <NetworkButton network={CHAIN_IDS.FUSE}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={!faucetData.paused}
                                    onClick={() => toggleFaucet(false)}>Open Faucet</Button>
                            </NetworkButton>
                        </Grid>
                        <Grid item xs={6}>
                            <NetworkButton network={CHAIN_IDS.FUSE}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={faucetData.paused}
                                    onClick={() => toggleFaucet(true)}>Close Faucet</Button>
                            </NetworkButton>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Amount To Increase By"
                                value={_amountToIncreaseBy}
                                onChange={ev => _setAmountToIncreaseBy(ev.target.value)}
                                InputProps={{
                                    endAdornment: <NetworkButton network={CHAIN_IDS.FUSE}><Button
                                        variant="contained"
                                        onClick={setAmountToIncreaseBy}>submit</Button></NetworkButton>
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Max Amount To Increase To"
                                value={_maxAmountToIncreaseTo}
                                onChange={ev => _setMaxAmountToIncreaseTo(ev.target.value)}
                                InputProps={{
                                    endAdornment: <NetworkButton network={CHAIN_IDS.FUSE}><Button
                                        variant="contained"
                                        onClick={setMaxAmountToIncreaseTo}>submit</Button></NetworkButton>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Min Amount To Claim"
                                value={_minAmountToClaim}
                                onChange={ev => _setMinAmountToClaim(ev.target.value)}
                                InputProps={{
                                    endAdornment: <NetworkButton network={CHAIN_IDS.FUSE}><Button
                                        variant="contained"
                                        onClick={setMinAmountToClaim}>submit</Button></NetworkButton>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Min Amount For Fastpass"
                                value={_minAmountForFastpass}
                                onChange={ev => _setMinAmountForFastpass(ev.target.value)}
                                InputProps={{
                                    endAdornment: <NetworkButton network={CHAIN_IDS.FUSE}><Button
                                        variant="contained"
                                        onClick={setMinAmountForFastpass}>submit</Button></NetworkButton>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Time Between Claims in Days"
                                value={_timeBetweenClaims}
                                onChange={ev => _setTimeBetweenClaims(ev.target.value)}
                                InputProps={{
                                    endAdornment: <NetworkButton network={CHAIN_IDS.FUSE}><Button
                                        variant="contained"
                                        onClick={setTimeBetweenClaims}>submit</Button></NetworkButton>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Deposit"
                                value={_depositAmount}
                                onChange={ev => _setDepositAmount(ev.target.value)}
                                InputProps={{
                                    endAdornment: <NetworkButton network={CHAIN_IDS.FUSE}><Button
                                        variant="contained"
                                        onClick={deposit}>submit</Button></NetworkButton>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>}

        {faucetData !== undefined && <Grid item xs={12}>
            <Grid item xs={12}>
                <Paper style={{color: '#000', backgroundColor: '#fbca00'}}>
                    <ListItem>
                        <ListItemText
                            primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.claimable))} CEUSONE` || '--'}
                            secondary={`Claimable Amount (≈${CoineusUSDFormat(formatWeiToNumber(faucetData.claimable) * wallet.prices.fuse[CEUSONE_ADDRESS]?.inUSD)})`}
                            primaryTypographyProps={{fontWeight: 700, fontSize: '1.75rem', textAlign: 'right'}}
                            secondaryTypographyProps={{color: '#000', textAlign: 'right'}}
                        />
                    </ListItem>
                </Paper>

                <NetworkButton network={CHAIN_IDS.FUSE}>
                    <Button
                        variant="contained"
                        fullWidth
                        style={{marginTop: 20}}
                        disabled={disabled}
                        onClick={claim}
                    >CLAIM</Button>
                </NetworkButton>
            </Grid>
        </Grid>}

        {disabled && <Grid item xs={12}>
            <Alert severity="error" variant="filled">{disabledReason}</Alert>
        </Grid>}

        {faucetData !== undefined && <Grid item xs={12}>
            <Divider style={{marginBottom: 10}}>OVERALL FAUCET STATS</Divider>
            <Paper>
                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.stats.claimedAllTime))} CEUSONE`}
                        secondary="Claimed All-Time"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={`${CoineusUSDFormat(formatWeiToNumber(faucetData.stats.claimedAllTime) * wallet.prices.fuse[CEUSONE_ADDRESS]?.inUSD)}`}
                        secondary="Today's Value"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={faucetData.stats.numberOfClaimsAllTime}
                        secondary="Number of Claims"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.stats.averageClaim))} CEUSONE`}
                        secondary="Average Claimed"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.stats.highestClaimed))} CEUSONE`}
                        secondary="Highest Amount Claimed All-Time"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={faucetData.stats.highestClaimedBy}
                        secondary="Highest Amount Claimed By"
                        primaryTypographyProps={{fontWeight: 700, fontSize: '.75rem'}}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.lockedAmount))} CEUSONE (${CoineusUSDFormat(formatWeiToNumber(faucetData.lockedAmount) * wallet.prices.fuse[CEUSONE_ADDRESS]?.inUSD)})`}
                        secondary="Amount Locked"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.minBalance))} LIT (${CoineusUSDFormat(formatWeiToNumber(faucetData.minBalance) * wallet.prices.fuse[LIT_ADDRESS]?.inUSD)})`}
                        secondary="Hold To Claim"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(faucetData.fastpassAmount))} LIT (${CoineusUSDFormat(formatWeiToNumber(faucetData.fastpassAmount) * wallet.prices.fuse[LIT_ADDRESS]?.inUSD)})`}
                        secondary="Hold For Fastpass"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>
            </Paper>
        </Grid>}

        {userFaucetData !== undefined && <Grid item xs={12}>
            <Divider style={{marginBottom: 10}}>MY FAUCET STATS</Divider>
            <Paper>
                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(userFaucetData.stats.claimedAllTime))} CEUSONE`}
                        secondary="Claimed All-Time"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={`${CoineusUSDFormat(formatWeiToNumber(userFaucetData.stats.claimedAllTime) * wallet.prices.fuse[CEUSONE_ADDRESS]?.inUSD)}`}
                        secondary="Today's Value"
                        primaryTypographyProps={{fontWeight: 700}}
                    />

                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={userFaucetData.stats.numberOfClaimsAllTime}
                        secondary="Number of Claims"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(userFaucetData.stats.averageClaim))} CEUSONE`}
                        secondary="Average Claimed"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(formatWeiToNumber(userFaucetData.stats.highestClaimed))} CEUSONE`}
                        secondary="Highest Amount Claimed All-Time"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>

                <ListItem>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(wallet.balances.fuse[LIT_ADDRESS])} LIT (${CoineusUSDFormat(wallet.balances.fuse[LIT_ADDRESS] * wallet.prices.fuse[LIT_ADDRESS]?.inUSD)})`}
                        secondary="My LIT Balance"
                        primaryTypographyProps={{fontWeight: 700}}
                    />
                </ListItem>
            </Paper>
        </Grid>}
        <CoineusLoading open={pending} label="Transaction Pending"/>

    </Grid>)
}