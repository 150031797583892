import {
    Avatar,
    Grid, List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper, Tab, Tabs,
} from "@mui/material";
import Web3 from "web3";
import coineus_abi from '../abis/coineus.json';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {makeBatchRequest} from "../utils/promisify";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import {formatWeiToNumber} from "../utils/format";
import consensus_abi from "../abis/consensus_abi.json";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";

const coineus_fund_address = "0xb80267eA7fa368374ee4d4Bf10044778232AdEFe";
const assurance_fund_address = "0x09501F8Ca9476d0c516E121FB4dCE4B7b2301d51";
const coineus_validator_address = "0x53e2033F86C1a54bEbAE893DC8C9fAe179b3Faa2";
const consensusAddress = "0x3014ca10b91cb3D0AD85fEf7A3Cb95BCAc9c0f79";

const useStyles = makeStyles((theme) => ({
    networkChip: {
        borderRadius: "50%",
        marginLeft: 30,
        position: 'absolute',
        top: 42,
        height: 16,
        width: 16,
        display: 'inline-block',
        backgroundSize: 'cover',
        '&.fuse': {
            backgroundImage: `url('https://coineus.app/assets/tokens/fuse/logo.png')`,
        },
        '&.avax': {
            backgroundImage: `url('https://coineus.app/assets/tokens/avax/logo.png')`,
        },
        '&.bnb': {
            backgroundImage: `url('https://coineus.app/assets/tokens/bnb/logo.png')`,
        },
        '&.eth': {
            backgroundImage: `url('https://coineus.app/assets/tokens/eth/logo.png')`,
        }
    }
}));

export default function CoineusPortfolio() {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [data, setData] = useState({});
    const [chainTab, setChainTab] = useState('fuse');

    const [fuseData, setFuseData] = useState({});

    useEffect(() => {

        const web3FUSE = new Web3(rpc.fuse);
        const consensusContract = new web3FUSE.eth.Contract(consensus_abi, consensusAddress);
        const ceus_contract = new web3FUSE.eth.Contract(coineus_abi, "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8");
        const ceusone_contract = new web3FUSE.eth.Contract(coineus_abi, "0xE27431FACb1F093BA164c9F86B3f484b39ad54CC");
        const lit_contract = new web3FUSE.eth.Contract(coineus_abi, '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F');


        const callsF = [
            web3FUSE.eth.getBalance(coineus_fund_address),
            web3FUSE.eth.getBalance(assurance_fund_address),
            consensusContract.methods.delegatedAmount(coineus_fund_address, coineus_validator_address).call,
            consensusContract.methods.delegatedAmount(assurance_fund_address, coineus_validator_address).call,
            ceus_contract.methods.balanceOf(coineus_fund_address).call,
            ceusone_contract.methods.balanceOf(coineus_fund_address).call,
            lit_contract.methods.balanceOf(coineus_fund_address).call,
        ];

        makeBatchRequest(web3FUSE, callsF).then(resp => {
            let [
                coineus_fund_balance,
                assurance_fund_balance,
                coineus_fund_staked,
                assurance_fund_staked,
                ceus_fund_balance,
                ceusone_fund_balance,
                lit_fund_balance
            ] = resp;

            coineus_fund_balance = coineus_fund_balance / (10 ** 18);
            assurance_fund_balance = assurance_fund_balance / (10 ** 18);
            coineus_fund_staked = coineus_fund_staked / (10 ** 18);
            assurance_fund_staked = assurance_fund_staked / (10 ** 18);
            ceus_fund_balance = ceus_fund_balance / (10 ** 18);
            ceusone_fund_balance = ceusone_fund_balance / (10 ** 18);
            lit_fund_balance = lit_fund_balance / (10 ** 18);

            setFuseData(
                {
                    coineus_fund_balance,
                    assurance_fund_balance,
                    coineus_fund_staked,
                    assurance_fund_staked,
                    ceus_fund_balance,
                    ceusone_fund_balance,
                    lit_fund_balance
                }
            )
        })

        const web3AVAX = new Web3(rpc.avax);
        const dgnx_contract = new web3AVAX.eth.Contract(coineus_abi, '0x51e48670098173025C477D9AA3f0efF7BF9f7812');

        const calls = [
            web3AVAX.eth.getBalance(coineus_fund_address),
            dgnx_contract.methods.balanceOf(coineus_fund_address).call,
        ];

        makeBatchRequest(web3AVAX, calls).then(resp => {
            let [
                coineus_avax_balance,
                coineus_fund_dgnx_balance,
            ] = resp;

            setData({
                coineus_avax_balance: coineus_avax_balance / (10 ** 18),
                coineus_fund_dgnx_balance
            })

        });
    }, []);

    const totalValue =
        (
            (
                fuseData.coineus_fund_balance +
                fuseData.coineus_fund_staked +
                fuseData.assurance_fund_balance +
                fuseData.assurance_fund_staked
            ) * wallet.prices.fuse?.native?.inUSD
        ) + //FUSE
        ((fuseData.ceus_fund_balance) * wallet.prices.fuse?.["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]?.inUSD) + //CEUS
        ((fuseData.ceusone_fund_balance) * wallet.prices.fuse?.["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]?.inUSD) + //CEUSONE
        ((fuseData.lit_fund_balance) * wallet.prices.fuse?.["0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"]?.inUSD) + //LIT

        (data.coineus_avax_balance * wallet.prices.avax.native?.inUSD) + //AVAX
        formatWeiToNumber(data.coineus_fund_dgnx_balance) * wallet.prices.avax?.['0x51e48670098173025C477D9AA3f0efF7BF9f7812']?.inUSD//DGNX



    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        <Grid item xs={12}>
            <Paper style={{color: '#000', backgroundColor: '#fbca00'}}>
                <ListItem>
                    <ListItemText
                        primary={CoineusUSDFormat(totalValue)}
                        secondary="Total Fund Value"
                        primaryTypographyProps={{fontWeight: 700, fontSize: '2.125rem', textAlign: 'right'}}
                        secondaryTypographyProps={{color: '#000', textAlign: 'right'}}
                    />
                </ListItem>
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <Tabs
                value={chainTab}
                onChange={(ev, val) => {
                    setChainTab(val)
                }}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab id="fuse" value="fuse" label="FUSE"/>
                <Tab id="avax" value="avax" label="AVAX"/>
            </Tabs>
        </Grid>

        {
            chainTab === 'fuse' && <>

                <TokenStatsSection data={{
                    symbol: "FUSE",
                    label: "Fund Holdings",
                    logo: "https://coineus.app/assets/tokens/fuse/logo.png",
                    usdValue: CoineusUSDFormat((
                        fuseData.coineus_fund_balance +
                        fuseData.coineus_fund_staked +
                        fuseData.assurance_fund_balance +
                        fuseData.assurance_fund_staked
                    ) * wallet.prices.fuse?.native?.inUSD),
                    balance: CoineusCryptoFormat(fuseData.coineus_fund_balance + fuseData.assurance_fund_balance),
                    secondaryBalance: CoineusCryptoFormat(fuseData.coineus_fund_staked + fuseData.assurance_fund_staked),
                    secondarySymbol: 'FUSE',
                    secondaryLabel: 'Staked',
                    network: 'fuse',
                    native: true
                }}/>

                <TokenStatsSection data={{
                    symbol: "CEUS",
                    label: "Fund Holdings",
                    logo: "https://coineus.app/assets/tokens/fuse/0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8/logo.png",
                    usdValue: CoineusUSDFormat((
                        fuseData.ceus_fund_balance
                    ) * wallet.prices.fuse?.["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]?.inUSD),
                    balance: CoineusCryptoFormat(fuseData.ceus_fund_balance),
                    network: 'fuse'
                }}/>

                <TokenStatsSection data={{
                    symbol: "CEUSONE",
                    label: "Fund Holdings",
                    logo: "https://coineus.app/assets/tokens/fuse/0xE27431FACb1F093BA164c9F86B3f484b39ad54CC/logo.png",
                    usdValue: CoineusUSDFormat((
                        fuseData.ceusone_fund_balance
                    ) * wallet.prices.fuse?.["0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"]?.inUSD),
                    balance: CoineusCryptoFormat(fuseData.ceusone_fund_balance),
                    network: 'fuse'
                }}/>

                <TokenStatsSection data={{
                    symbol: "LIT",
                    label: "Fund Holdings",
                    logo: "https://coineus.app/assets/tokens/fuse/0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F/logo.png",
                    usdValue: CoineusUSDFormat((
                        fuseData.lit_fund_balance
                    ) * wallet.prices.fuse?.["0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"]?.inUSD),
                    balance: CoineusCryptoFormat(fuseData.lit_fund_balance),
                    network: 'fuse'
                }}/>

            </>
        }

        {
            chainTab === 'avax' && <>
                <TokenStatsSection data={{
                    symbol: "AVAX",
                    label: "Fund Holdings",
                    logo: "https://coineus.app/assets/tokens/avax/logo.png",
                    usdValue: CoineusUSDFormat(data.coineus_avax_balance * wallet.prices.avax.native?.inUSD),
                    balance: CoineusCryptoFormat(data.coineus_avax_balance),
                    network: 'avax',
                    native: true
                }}/>

                <TokenStatsSection data={{
                    symbol: "DGNX",
                    label: "Fund Holdings",
                    logo: "https://coineus.app/assets/tokens/avax/0x51e48670098173025C477D9AA3f0efF7BF9f7812/logo.png",
                    usdValue: CoineusUSDFormat((data.coineus_fund_dgnx_balance / 10 ** 18) * wallet.prices.avax?.['0x51e48670098173025C477D9AA3f0efF7BF9f7812']?.inUSD),
                    balance: CoineusCryptoFormat((data.coineus_fund_dgnx_balance / 10 ** 18)),
                    network: 'avax'
                }}/>

            </>
        }

    </Grid>
}

function TokenStatsSection(props) {

    const classes = useStyles();

    const {
        native,
        symbol,
        label,
        logo,
        usdValue,
        balance,
        secondaryBalance,
        secondarySymbol,
        secondaryLabel,
        network
    } = props.data;

    return <Grid item xs={12}>
        <Paper>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={logo}/>
                        {!native && <span className={clsx(classes.networkChip, network)}/>}
                    </ListItemAvatar>
                    <ListItemText
                        primary={symbol}
                        primaryTypographyProps={{fontWeight: 700}}
                        secondary={label}
                    />
                    <ListItemText
                        primary={usdValue}
                        secondary="USD Value"
                        primaryTypographyProps={{fontWeight: 700, textAlign: 'right'}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                </ListItem>
                {
                    balance !== undefined && <ListItem>
                        <ListItemText
                            primary={`${balance} ${symbol}`}
                            primaryTypographyProps={{fontWeight: 700}}
                            secondary="Wallet"
                        />
                        {
                            secondaryBalance !== undefined && <ListItemText
                                primary={`${secondaryBalance} ${secondarySymbol}`}
                                primaryTypographyProps={{fontWeight: 700}}
                                secondary={secondaryLabel}
                            />
                        }
                    </ListItem>
                }
            </List>
        </Paper>
    </Grid>
}