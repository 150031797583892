import {
    Avatar,
    Button,
    Divider,
    FormControl,
    Grid,
    InputAdornment, Link,
    ListItem, ListItemAvatar,
    ListItemText,
    Paper,
    TextField, Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import TokenListItem from "../components/TokenListItem";
import CoineusLoading from "../components/CoineusLoading";
import router_abi from '../abis/IPancakeRouter.json';
import uni_abi from '../abis/IUniswapV2Pair.json';
import easy_bridge_abi from '../abis/EasyBridge.json';
import Web3 from "web3";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import getUserBalances from "../utils/get_balances";
import {toWei} from "../utils/format";
import {CHAIN_IDS} from "../constants";
import {NetworkButton} from "../components/Coineus";

const pairAddress = '0x6483f166b9e4310a165a55fea04f867499aded06';
const routerAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
const easyBridgeAddress = '0xb19769df421b8D9B21CCfAB7d0c5f58385Fa2eCB';

export default function EasyBridge() {

    const {wallet, rpc} = useSelector(state => state.coineus);
    const [amount, setAmount] = useState("");
    const [pending, setPending] = useState(false);

    const [reserves, setReserves] = useState([0, 0])
    const [fuseOut, setFuseOut] = useState(0);

    const [amtRecieved, setAmtRecieved] = useState(undefined);
    const [txHash, setTxHash] = useState(undefined);
    const [txCost, setTxCost] = useState(undefined);

    const setMax = () => {
        setAmount(wallet.balances.bnb.native - .003);
    }

    const getFuseAmt = () => {

        if (!amount || !reserves[0] || !reserves[1]) {
            setFuseOut(undefined)
            return;
        }
        const web3 = new Web3(rpc.bnb);

        const contract = new web3.eth.Contract(router_abi, routerAddress);

        contract.methods.quote(toWei(amount), reserves[1], reserves[0]).call((err, resp) => {
            setFuseOut(resp / (10 ** 18) * .98);
        })
    }

    useEffect(getFuseAmt, [amount, reserves, rpc.bnb]);

    const getReserves = () => {
        const web3 = new Web3(rpc.bnb);
        const contract = new web3.eth.Contract(uni_abi, pairAddress);

        contract.methods.getReserves().call((err, resp) => {
            setReserves([resp.reserve0, resp.reserve1]);
        })
    }

    useEffect(() => {
        getReserves();
        const interval = setInterval(getReserves, 10000)
        return () => clearInterval(interval);
    }, []);

    const bridge = useCallback(async () => {

        const web3 = new Web3(window.provider);
        const easyBridge = new web3.eth.Contract(easy_bridge_abi, easyBridgeAddress);

        const tx = easyBridge.methods.easyBridge(toWei(fuseOut));
        const gas = await tx.estimateGas({
            from: wallet.address,
            value: web3.utils.toBN(amount * (10 ** 18))
        });


        const gasPrice = await web3.eth.getGasPrice();
        const data = tx.encodeABI();
        const txData = {
            from: wallet.address,
            value: web3.utils.toBN(amount * (10 ** 18)),
            data,
            gas,
            gasPrice
        }

        tx.send(txData)
            .once('transactionHash', function (tx) {
                setPending(true);
                setTxHash(tx);
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                getUserBalances(wallet.address);
                setAmtRecieved(receipt.events.EasyBridged.returnValues.value / (10 ** 18));
                setTxCost(receipt.gasUsed * (receipt.effectiveGasPrice / (10 ** 18)));
                setPending(false);
            });
    }, [amount, fuseOut, wallet.address]);

    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        <Grid item xs={12}>
            <Paper style={{padding: 20}}>
                <FormControl fullWidth>
                    <TextField
                        autoComplete="off"
                        type="number"
                        value={amount}
                        placeholder="Enter Amount"
                        onChange={(ev) => {
                            setAmount(ev.target.value)
                        }}
                        InputProps={{
                            inputProps: {
                                style: {textAlign: "right"},
                            },
                            startAdornment: <Avatar style={{marginRight: 10}} sx={{width: 24, height: 24}}
                                                    src={`https://coineus.app/assets/tokens/bnb/logo.png`}/>,
                            endAdornment: <InputAdornment position="end">
                                <Button variant="contained" onClick={setMax}>MAX</Button>
                            </InputAdornment>,
                        }}
                    />
                </FormControl>
                <ListItem disablePadding style={{marginTop: 10}}>
                    <ListItemAvatar>
                        <Avatar style={{marginLeft: 15}} sx={{width: 24, height: 24}}
                                src={`https://coineus.app/assets/tokens/fuse/logo.png`}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${CoineusCryptoFormat(fuseOut)} FUSE`}
                        secondary="Estimated Out"
                        primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                        secondaryTypographyProps={{textAlign: 'right'}}
                    />
                </ListItem>
                <NetworkButton network={CHAIN_IDS.BNB}>
                    <Button
                        variant="contained"
                        fullWidth
                        style={{marginTop: 10}}
                        onClick={bridge}
                        disabled={!wallet.address || amount > wallet.balances.bnb.native || toWei(amount) <= 0}
                    >BRIDGE</Button>
                </NetworkButton>
            </Paper>
            <Divider style={{marginTop: 10}}>SMART CHAIN</Divider>
            <TokenListItem
                contract_address="native"
                network="bnb"
                name="BNB"
                native
                price={wallet.prices.bnb?.native}
                wrap="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
            />

            <Divider style={{marginTop: 10}}>FUSE NETWORK</Divider>
            <TokenListItem
                contract_address="native"
                wrap="0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
                network="fuse"
                name="FUSE"
                native
                price={wallet.prices.fuse?.native}
            />

            {
                amtRecieved !== undefined && <>
                    <Divider style={{marginTop: 40, marginBottom: 10}}>RECEIPT</Divider>
                    <Paper
                        style={{
                            backgroundColor: 'white',
                            color: "#000",
                            padding: 20,
                            fontFamily: 'monospace'
                        }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace'}}>Bridged</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                                    {`${CoineusCryptoFormat(amtRecieved)} FUSE`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: 20}}/>


                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace'}}>TxFee</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                                    {`${CoineusCryptoFormat(txCost)} BNB`}
                                </Typography>
                            </Grid>


                            <Grid item xs={6}/>
                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                                    {`${CoineusUSDFormat(txCost * wallet.prices.bnb?.native?.inUSD)} USD`}
                                </Typography>
                            </Grid>


                            <Grid item xs={12} style={{marginTop: 20}}/>

                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace'}}>TxHash</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>

                                    <Link
                                        style={{color: "#000", textDecorationColor: '#000'}}
                                        href={`https://bscscan.com/tx/${txHash}`}
                                        target="_blank">{`0x..${txHash.substr(-12)}`}</Link>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{marginTop: 20}}>
                                <Typography style={{fontFamily: 'monospace'}}>FUSE Balance update
                                    pending...</Typography>
                            </Grid>
                        </Grid>

                    </Paper>
                </>
            }
        </Grid>
        <CoineusLoading open={pending} label="Transaction Pending"/>
    </Grid>
}