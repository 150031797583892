import {
    Avatar,
    Button, Divider,
    Fab,
    Grid,
    List,
    ListItem,
    ListItemAvatar, ListItemText,
    Paper,
    Slider,
    Typography
} from "@mui/material";
import SwapInput from "../components/SwapInput";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {CoineusCryptoFormat} from "../utils/currency_format";
import Web3 from "web3";
import router_abi from "../abis/IPancakeRouter.json";
import ierc20_abi from "../abis/IERC20.json";
import CoineusLoading from "../components/CoineusLoading";
import getUserBalances from "../utils/get_balances";
import TokenList from "../components/Swap/TokenList";
import {toWei} from "../utils/format";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {NetworkButton} from "../components/Coineus";
import {CHAIN_IDS} from "../constants";
import TransactionReceipt from "../components/Swap/TransactionReceipt";


const routerAddress = '0xE3F85aAd0c8DD7337427B9dF5d0fB741d65EEEB5';

const defaultFuse = {
    name: 'Fuse',
    symbol: 'FUSE',
    contract_address: 'native',
    decimals: 18
}

const defaultCeus = {
    name: 'Coineus',
    symbol: 'CEUS',
    contract_address: '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8',
    decimals: 18
}

const WFUSE_CONTRACT = '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629';
const CEUS_CONTRACT = '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8';
const LIT_CONTRACT = '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F';
const FVKSRG_CONTRACT = '0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61';
const LADY_CONTRACT = '0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A';
const FOO_CONTRACT = '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9';

const PATHS = {
    FUSE_CEUS: [
        [
            WFUSE_CONTRACT,
            CEUS_CONTRACT
        ],
        [
            WFUSE_CONTRACT,
            LIT_CONTRACT,
            CEUS_CONTRACT
        ],
        [
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT,
            CEUS_CONTRACT
        ],
        [
            WFUSE_CONTRACT,
            LADY_CONTRACT,
            CEUS_CONTRACT
        ]
    ],
    FUSE_LIT: [
        [
            WFUSE_CONTRACT,
            LIT_CONTRACT
        ],
        [
            WFUSE_CONTRACT,
            CEUS_CONTRACT,
            LIT_CONTRACT
        ]
    ],
    FUSE_LADY: [
        [
            WFUSE_CONTRACT,
            LADY_CONTRACT
        ],
        [
            WFUSE_CONTRACT,
            CEUS_CONTRACT,
            LADY_CONTRACT
        ]
    ],
    FUSE_FVKSRG: [
        [
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT
        ],
        [
            WFUSE_CONTRACT,
            CEUS_CONTRACT,
            FVKSRG_CONTRACT
        ]
    ],
    FUSE_FOO: [[
        WFUSE_CONTRACT,
        FOO_CONTRACT
    ],[
        WFUSE_CONTRACT,
        CEUS_CONTRACT,
        LADY_CONTRACT,
        FOO_CONTRACT
    ]],

    FVKSRG_FUSE: [
        [
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT
        ],
        [
            FVKSRG_CONTRACT,
            CEUS_CONTRACT,
            WFUSE_CONTRACT
        ]
    ],
    FVKSRG_CEUS: [
        [
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT,
            CEUS_CONTRACT
        ],
        [
            FVKSRG_CONTRACT,
            CEUS_CONTRACT
        ]
    ],
    FVKSRG_LIT: [
        [
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT,
            LIT_CONTRACT
        ],
        [
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT,
            CEUS_CONTRACT,
            LIT_CONTRACT
        ],
        [
            FVKSRG_CONTRACT,
            CEUS_CONTRACT,
            LIT_CONTRACT
        ]
    ],
    FVKSRG_LADY: [
        [
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT,
            LADY_CONTRACT
        ],
        [
            FVKSRG_CONTRACT,
            CEUS_CONTRACT,
            LADY_CONTRACT
        ],
        [
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT,
            CEUS_CONTRACT,
            LADY_CONTRACT
        ]
    ],
    FVKSRG_FOO: [[
        FVKSRG_CONTRACT,
        WFUSE_CONTRACT,
        CEUS_CONTRACT,
        LADY_CONTRACT,
        FOO_CONTRACT
    ]],

    CEUS_FUSE: [
        [
            CEUS_CONTRACT,
            WFUSE_CONTRACT
        ],
        [
            CEUS_CONTRACT,
            LIT_CONTRACT,
            WFUSE_CONTRACT
        ],
        [
            CEUS_CONTRACT,
            FVKSRG_CONTRACT,
            WFUSE_CONTRACT
        ],
        [
            CEUS_CONTRACT,
            LADY_CONTRACT,
            WFUSE_CONTRACT
        ]
    ],
    CEUS_LIT: [
        [
            CEUS_CONTRACT,
            LIT_CONTRACT
        ],
        [
            CEUS_CONTRACT,
            WFUSE_CONTRACT,
            LIT_CONTRACT
        ]
    ],
    CEUS_LADY: [
        [
            CEUS_CONTRACT,
            LADY_CONTRACT
        ],
        [
            CEUS_CONTRACT,
            WFUSE_CONTRACT,
            LADY_CONTRACT
        ]
    ],
    CEUS_FVKSRG: [
        [
            CEUS_CONTRACT,
            FVKSRG_CONTRACT
        ],
        [
            CEUS_CONTRACT,
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT
        ]
    ],
    CEUS_FOO: [[
        CEUS_CONTRACT,
        LADY_CONTRACT,
        FOO_CONTRACT
    ]],

    LIT_FUSE: [
        [
            LIT_CONTRACT,
            WFUSE_CONTRACT
        ],
        [
            LIT_CONTRACT,
            CEUS_CONTRACT,
            WFUSE_CONTRACT
        ]
    ],
    LIT_CEUS: [
        [
            LIT_CONTRACT,
            CEUS_CONTRACT
        ],
        [
            LIT_CONTRACT,
            WFUSE_CONTRACT,
            CEUS_CONTRACT
        ]
    ],
    LIT_FVKSRG: [
        [
            LIT_CONTRACT,
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT
        ],
        [
            LIT_CONTRACT,
            CEUS_CONTRACT,
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT
        ],
        [
            LIT_CONTRACT,
            CEUS_CONTRACT,
            FVKSRG_CONTRACT
        ]
    ],
    LIT_LADY: [[
        LIT_CONTRACT,
        CEUS_CONTRACT,
        LADY_CONTRACT
    ]],
    LIT_FOO: [[
        LIT_CONTRACT,
        CEUS_CONTRACT,
        LADY_CONTRACT,
        FOO_CONTRACT
    ]],

    LADY_FUSE: [
        [
            LADY_CONTRACT,
            WFUSE_CONTRACT
        ],
        [
            LADY_CONTRACT,
            CEUS_CONTRACT,
            WFUSE_CONTRACT
        ]
    ],
    LADY_CEUS: [
        [
            LADY_CONTRACT,
            CEUS_CONTRACT
        ],
        [
            LADY_CONTRACT,
            WFUSE_CONTRACT,
            CEUS_CONTRACT
        ]
    ],
    LADY_FVKSRG: [
        [
            LADY_CONTRACT,
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT
        ],
        [
            LADY_CONTRACT,
            CEUS_CONTRACT,
            FVKSRG_CONTRACT
        ],
        [
            LADY_CONTRACT,
            CEUS_CONTRACT,
            WFUSE_CONTRACT,
            FVKSRG_CONTRACT
        ]
    ],
    LADY_LIT: [[
        LADY_CONTRACT,
        CEUS_CONTRACT,
        LIT_CONTRACT
    ]],
    LADY_FOO: [[
        LADY_CONTRACT,
        FOO_CONTRACT
    ]],

    FOO_FUSE: [[
        FOO_CONTRACT,
        WFUSE_CONTRACT
    ],[
        FOO_CONTRACT,
        LADY_CONTRACT,
        CEUS_CONTRACT,
        WFUSE_CONTRACT
    ]],
    FOO_FVKSRG: [[
        FOO_CONTRACT,
        LADY_CONTRACT,
        CEUS_CONTRACT,
        WFUSE_CONTRACT,
        FVKSRG_CONTRACT
    ]],
    FOO_CEUS: [[
        FOO_CONTRACT,
        LADY_CONTRACT,
        CEUS_CONTRACT
    ]],
    FOO_LIT: [[
        FOO_CONTRACT,
        LADY_CONTRACT,
        CEUS_CONTRACT,
        LIT_CONTRACT
    ]],
    FOO_LADY: [[
        FOO_CONTRACT,
        LADY_CONTRACT
    ]]
}

export default function Swap() {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [selectedInputToken, setSelectedInputToken] = useState(defaultFuse);

    const [selectedOutputToken, setSelectedOutputToken] = useState(defaultCeus);

    const [selectFocus, setSelectFocus] = useState('input');

    const [routeOptions, setRouteOptions] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState([]);

    const [amountIn, setAmountIn] = useState(0);
    const [amountOut, setAmountOut] = useState(0);
    const [slippage, setSlippage] = useState(0.1);
    const [pending, setPending] = useState(false);
    const [amtPending, setAmtPending] = useState(false);
    const [tokenAllowance, setTokenAllowance] = useState(0);

    const [amtSent, setAmtSent] = useState(undefined);
    const [amtRecieved, setAmtRecieved] = useState(undefined);
    const [txHash, setTxHash] = useState(undefined);
    const [txCost, setTxCost] = useState(undefined);

    const getRoute = () => {

        if (selectedInputToken.symbol === selectedOutputToken.symbol) return;

        const route = selectedInputToken.symbol + "_" + selectedOutputToken.symbol;
        setRouteOptions(PATHS[route])
        setSelectedRoute(PATHS[route][0])
        return PATHS[route][0];
    }

    const getAmountsOut = () => {
        if (toWei(amountIn) === 'NaN') return;
        if (selectedInputToken.symbol === selectedOutputToken.symbol) return;
        if (selectFocus === 'output') return;

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(router_abi, routerAddress);

        setAmtPending(true);

        contract.methods.getAmountsOut(toWei(amountIn, selectedInputToken.decimals), selectedRoute).call((err, resp) => {
            resp ? setAmountOut((resp[resp.length - 1] / (10 ** selectedOutputToken.decimals)).toFixed(8)) : setAmountOut(0);
            setAmtPending(false);
        })
    }

    const getAmountsIn = () => {
        if (toWei(amountOut) === 'NaN') return;
        if (selectedInputToken.symbol === selectedOutputToken.symbol) return;
        if (selectFocus === 'input') return;

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(router_abi, routerAddress);

        setAmtPending(true);

        contract.methods.getAmountsIn(toWei(amountOut, selectedOutputToken.decimals), selectedRoute).call((err, resp) => {
            resp ? setAmountIn((resp[0] / (10 ** selectedInputToken.decimals)).toFixed(8)) : setAmountIn(0);
            setAmtPending(false);
        })
    }

    const getAllowance = () => {
        if (selectedInputToken.contract_address === 'native') return;
        if (!wallet.address) return;

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(ierc20_abi, selectedInputToken.contract_address);

        contract.methods.allowance(wallet.address, routerAddress)
            .call((err, resp) => {
                if (err) return;
                setTokenAllowance(resp / (10 ** selectedInputToken.decimals));
            })
    }

    const setAllowance = () => {
        if (selectedInputToken.contract_address === 'native') return;

        const web3 = new Web3(window.provider);
        const contract = new web3.eth.Contract(ierc20_abi, selectedInputToken.contract_address);

        contract
            .methods
            .approve(routerAddress, toWei(amountIn, selectedInputToken.decimals))
            .send({from: wallet.address})
            .once('transactionHash', function (tx) {
                setPending(true)
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                setPending(false);
                getAllowance();
            });
    }

    useEffect(getAmountsOut, [amountIn, selectedInputToken, selectedOutputToken, selectedRoute]);
    useEffect(getAmountsIn, [amountOut, selectedInputToken, selectedOutputToken, selectedRoute]);
    useEffect(getAllowance, [selectedInputToken]);

    useEffect(() => {
        //handle selecting same token
        if (selectedInputToken.symbol === selectedOutputToken.symbol) {
            if (selectFocus === 'input') {
                if (selectedInputToken.symbol === 'FUSE') {
                    setSelectedOutputToken(defaultCeus)
                } else {
                    setSelectedOutputToken(defaultFuse)
                }
            } else {
                if (selectedOutputToken.symbol === 'FUSE') {
                    setSelectedInputToken(defaultCeus)
                } else {
                    setSelectedInputToken(defaultFuse)
                }
            }
        }
        getRoute();
    }, [selectedInputToken, selectedOutputToken])

    const swap = async () => {

        setAmtSent(undefined);
        setAmtRecieved(undefined);
        setTxHash(undefined);
        setTxCost(undefined);

        const web3 = new Web3(window.provider);


        const contract = new web3.eth.Contract(router_abi, routerAddress);
        const gasPrice = await web3.eth.getGasPrice();
        const gas = 1000000;

        let method;

        if (selectedInputToken.symbol === 'FUSE') {
            method = contract.methods[selectFocus === 'input' ? 'swapExactETHForTokensSupportingFeeOnTransferTokens' : 'swapETHForExactTokensSupportingFeeOnTransferTokens'](
                toWei(amountOut * ((100 - slippage) / 100), selectedOutputToken.decimals),
                selectedRoute,
                wallet.address,
                (((new Date()).getTime() + 60000) / 1000).toFixed(0)
            ).send(
                {
                    from: wallet.address,
                    value: web3.utils.toWei(amountIn),
                    gas,
                    gasPrice
                }
            )

        } else if (selectedOutputToken.symbol === 'FUSE') {
            // console.log(selectFocus === 'input' ? 'swapExactTokensForETH' : 'swapTokensForExactETH');

            method = contract.methods[selectFocus === 'input' ? 'swapExactTokensForETHSupportingFeeOnTransferTokens' : 'swapTokensForExactETHSupportingFeeOnTransferTokens'](
                toWei(amountIn, selectedInputToken.decimals),
                toWei(amountOut * ((100 - slippage) / 100)),
                selectedRoute,
                wallet.address,
                (((new Date()).getTime() + 60000) / 1000).toFixed(0)
            ).send(
                {
                    from: wallet.address,
                    gas,
                    gasPrice
                }
            )

        } else {
            // console.log(selectFocus === 'input' ? 'swapExactTokensForTokens' : 'swapTokensForExactTokens');

            method = contract.methods[selectFocus === 'input' ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens' : 'swapTokensForExactTokensSupportingFeeOnTransferTokens'](
                toWei(amountIn, selectedInputToken.decimals),
                toWei(amountOut * ((100 - slippage) / 100), selectedOutputToken.decimals),
                selectedRoute,
                wallet.address,
                (((new Date()).getTime() + 60000) / 1000).toFixed(0)
            ).send(
                {
                    from: wallet.address,
                    gas,
                    gasPrice
                }
            );
        }

        method.once('transactionHash', function (tx) {
            setPending(true);
            setTxHash(tx);
        })
            .on('error', function (error) {
                setPending(false)
            })
            .on('receipt', function (receipt) {
                setAmtSent(receipt.events.Transfer[0].returnValues.value / (10 ** selectedInputToken.decimals));
                setAmtRecieved(receipt.events.Transfer[receipt.events.Transfer.length - 1].returnValues.value / (10 ** selectedOutputToken.decimals));
            })
            .then(function (receipt, a, b) {
                getUserBalances(wallet.address);
                setTxCost(receipt.gasUsed * (receipt.effectiveGasPrice / (10 ** 18)));
                setPending(false);
            });
    }

    const swapInputOutputTokens = () => {
        const inToken = {...selectedInputToken};
        const outToken = {...selectedOutputToken};
        setSelectedInputToken(outToken);
        setSelectedOutputToken(inToken);
    }

    return (<Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
            <Grid item xs={12}>
                <Paper style={{padding: 20}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} onFocus={() => setSelectFocus('input')}>
                            <Typography variant="caption"
                                        style={{float: 'right'}}>Balance: {CoineusCryptoFormat(wallet.balances.fuse[selectedInputToken.contract_address])}</Typography>
                            <SwapInput
                                value={amountIn || ""}
                                onChange={(ev) => {
                                    if (amtPending) return;
                                    setAmountIn(ev.target.value)
                                }}
                                startAdornment={<TokenList selectedToken={selectedInputToken}
                                                           setSelectedToken={setSelectedInputToken}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} alignContent="center"
                              style={{textAlign: 'center', height: 0, paddingTop: 0}}>
                            <Fab size="small" variant="contained" color="primary" style={{marginTop: -12}}
                                 onClick={swapInputOutputTokens}>
                                <SwapVertIcon/>
                            </Fab>
                        </Grid>
                        <Grid item xs={12} onFocus={() => setSelectFocus('output')}>
                            <SwapInput
                                value={amountOut || ""}
                                onChange={(ev) => {
                                    if (amtPending) return;
                                    setAmountOut(ev.target.value)
                                }}
                                startAdornment={<TokenList selectedToken={selectedOutputToken}
                                                           setSelectedToken={setSelectedOutputToken}
                                />}
                            />
                            <Typography variant="caption"
                                        style={{float: 'right'}}>Balance: {CoineusCryptoFormat(wallet.balances.fuse[selectedOutputToken.contract_address])}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <NetworkButton network={CHAIN_IDS.FUSE}>
                                {
                                    (tokenAllowance < amountIn && selectedInputToken.contract_address !== "native") ?
                                        <Button fullWidth variant="contained" onClick={setAllowance}>APPROVE</Button> :
                                        <Button fullWidth variant="contained" onClick={swap}
                                                disabled={amountIn <= 0}>SWAP</Button>
                                }
                            </NetworkButton>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper style={{padding: 20, marginTop: 10}}>
                    <Slider
                        value={slippage}
                        step={0.1}
                        min={0.1}
                        max={25}
                        onChange={(_ev, value) => {
                            setSlippage(value)
                        }}
                    />
                    <Typography>Slippage: {slippage}% </Typography>
                </Paper>

                <Grid item xs={12} style={{marginTop: 10}}><Divider>ROUTES</Divider></Grid>
                {
                    routeOptions.map((route) => {
                        return <RouteDetails
                            key={route}
                            route={route}
                            selectedRoute={selectedRoute}
                            setSelectedRoute={setSelectedRoute}
                            amountIn={amountIn}
                            selectedInputToken={selectedInputToken}
                            selectedOutputToken={selectedOutputToken}
                            setSelectFocus={setSelectFocus}
                        />
                    })
                }


                {
                    amtRecieved !== undefined && <TransactionReceipt
                        receipt={{amtSent, amtRecieved, selectedInputToken, selectedOutputToken, txCost, txHash}}/>
                }
            </Grid>
            <CoineusLoading open={pending} label="Transaction Pending"/>
        </Grid>
    )
}

function RouteDetails(props) {


    const {rpc} = useSelector(state => state.coineus);
    const {
        route,
        selectedRoute,
        setSelectedRoute,
        amountIn,
        selectedInputToken,
        selectedOutputToken,
        setSelectFocus
    } = props;

    const [amountOut, setAmountOut] = useState(0);

    useEffect(() => {
        if (toWei(amountIn) === 'NaN') return;
        if (toWei(amountIn) === 0) return;

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(router_abi, routerAddress);

        contract.methods.getAmountsOut(toWei(amountIn, selectedInputToken.decimals), route).call((err, resp) => {
            resp ? setAmountOut((resp[resp.length - 1] / (10 ** selectedOutputToken.decimals)).toFixed(8)) : setAmountOut(0);
        })
    }, [amountIn])

    return <Paper
        style={{padding: 0, marginTop: 10, border: route === selectedRoute ? '2px solid #fbca00' : '2px solid black'}}
        onClick={() => {
            setSelectFocus('input');
            setSelectedRoute(route)
        }}
    >
        <List>
            <ListItem>
                {
                    route.map(ca => <ListItemAvatar style={{minWidth: 40}}>
                        <Avatar
                            sx={{height: 30, width: 30}}
                            src={`https://coineus.app/assets/tokens/fuse/${ca}/logo.png`}
                        />
                    </ListItemAvatar>)
                }
                <ListItemText
                    primary={`${amountOut} ${selectedOutputToken.symbol}`}
                    secondary="Est. Out"
                    primaryTypographyProps={{textAlign: 'right'}}
                    secondaryTypographyProps={{textAlign: 'right'}}
                />
            </ListItem>
        </List>
    </Paper>
}