import {useSelector} from "react-redux";
import Web3 from "web3";
import farm_abi from "../abis/MultiRewards.json";
import {useEffect, useState} from "react";
import {makeBatchRequest} from "../utils/promisify";
import pair_abi from "../abis/IUniswapV2Pair.json";

import farm_config from "../utils/farmContracts.json";

export default function useFarmInfo(farm_contract_address, network) {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const web3 = new Web3(rpc[network]);

    const farm_contract = new web3.eth.Contract(farm_abi, farm_contract_address);

    const [farmInfo, setFarmInfo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getFarmInfo() {
            if (!wallet.address) return;

            const rewardTokens = farm_config[farm_contract_address].reward_tokens;

            const calls = [
                farm_contract.methods.totalSupply().call,
                farm_contract.methods.balanceOf(wallet.address).call,
                farm_contract.methods.stakingToken().call
            ]

            for(let i=0; i<rewardTokens.length; i++){
                const ca = rewardTokens[i].contract_address;
                calls.push(farm_contract.methods.rewardData(ca).call);
                calls.push(farm_contract.methods.earned(wallet.address, ca).call);
                calls.push(farm_contract.methods.getRewardForDuration(ca).call);
            }

            makeBatchRequest(web3, calls).then(resp => {

                let [totalStaked,
                    stakingToken,
                    stakedAmount] = [0,0,0];

                [
                    totalStaked,
                    stakedAmount,
                    stakingToken
                ] = resp;

                let respIndex = 3;
                let totalValue = 0;
                let rewardsDuration = 0;
                for(let i=0; i<rewardTokens.length; i++){

                    rewardsDuration = resp[respIndex].rewardsDuration;

                    const secondsLeft = calcSecsLeft(resp[respIndex].periodFinish);
                    const rewardsRemaining = secondsLeft * resp[respIndex].rewardRate / (10 ** rewardTokens[i].decimals);
                    const potentialEarnings = rewardsRemaining * (stakedAmount / totalStaked);

                    totalValue += rewardsRemaining ? resp[respIndex+2] / (10 ** rewardTokens[i].decimals) * wallet.prices[network]?.[rewardTokens[i].contract_address]?.inUSD : 0;

                    rewardTokens[i].data = {
                        rewardData: resp[respIndex],
                        earned: resp[respIndex+1] / (10 ** rewardTokens[i].decimals),
                        rewardsForDuration: resp[respIndex+2] / (10 ** rewardTokens[i].decimals),
                        potentialEarnings,
                        rewardsRemaining
                    };
                    respIndex+=3;
                }
                const valueLP = totalStaked / (10 ** 18) * wallet.prices[network]?.[stakingToken]?.inUSD

                const stakingTokenContract = new web3.eth.Contract(pair_abi, stakingToken);

                /**
                 * 2592000 = 30 days
                 * 604800 = 7 days
                 */
                let split = 52;
                if(parseInt(rewardsDuration) === 2592000){
                    split = 365/30;
                }

                const apy = (totalValue * split) / valueLP * 100;

                const stakingCalls = [
                    stakingTokenContract.methods.token0().call,
                    stakingTokenContract.methods.token1().call,
                    stakingTokenContract.methods.decimals().call,
                    stakingTokenContract.methods.balanceOf(wallet.address).call,
                    stakingTokenContract.methods.getReserves().call,
                    stakingTokenContract.methods.totalSupply().call,
                ]

                makeBatchRequest(web3, stakingCalls).then(resp => {
                    const [
                        token0,
                        token1,
                        decimals,
                        stake_token_balance,
                        reserves,
                        totalSupply
                    ] = resp;

                    const share = (totalStaked / totalSupply);

                    const tInfo0 = findTokenInfo(token0);
                    const tInfo1 = findTokenInfo(token1);

                    reserves.reserve0 = (reserves.reserve0 / (10 ** tInfo0.decimals)) * share;
                    reserves.reserve1 = (reserves.reserve1 / (10 ** tInfo1.decimals)) * share;

                    const tmp = {
                        totalStaked: totalStaked / (10 ** decimals),
                        stakingToken,
                        stakedAmount: stakedAmount / (10 ** decimals),
                        token0,
                        token1,
                        tokenInfo0: tInfo0,
                        tokenInfo1: tInfo1,
                        rewardTokens,
                        apy,
                        stake_token_balance: stake_token_balance / (10 ** decimals),
                        reserves,
                        totalSupply: totalSupply / (10 ** decimals),
                        share
                    }

                    setFarmInfo(tmp);
                    setLoading(false);
                })
            })
        }

        getFarmInfo();
        const interval = setInterval(getFarmInfo, 10000)
        return () => clearInterval(interval);
    }, [farm_contract_address, wallet.address])

    return {farmInfo, loading};
}

const calcSecsLeft = (periodFinish) => {
    const left = parseInt(((periodFinish * 1000) - new Date().getTime()) / 1000);
    return left > 0 ? left : 0;
}

function findTokenInfo(needle){
    let t = null;
    [
        {
            "name": "Lightning",
            "symbol": "LIT",
            "decimals": "18",
            "contract_address": "0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F"
        },
        {
            "name": "Wrapped FUSE",
            "symbol": "WFUSE",
            "decimals": "18",
            "contract_address": "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629"
        },
        {
            "name": "Coineus",
            "symbol": "CEUS",
            "decimals": "18",
            "contract_address": "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8"
        },
        {
            "name": "LadyBot",
            "symbol": "$LADY",
            "decimals": "9",
            "contract_address": "0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A"
        },
        {
            "name": "Fates Violent Kiss Sends Reapers Grimm",
            "symbol": "FVKSRG",
            "decimals": "18",
            "contract_address": "0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61"
        },
        {
            "name": "F00",
            "symbol": "FOO",
            "decimals": "18",
            "contract_address": "0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9"
        }
    ].map((token) => {
        if(token.contract_address.toLowerCase() === needle.toLowerCase()){
            t = token;
        }
        return token;
    });
    return t;
}