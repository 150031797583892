import {Divider, Grid, Link, Paper, Typography} from "@mui/material";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../../utils/currency_format";
import {useSelector} from "react-redux";

export default function TransactionReceipt(props) {

    const {wallet} = useSelector(state => state.coineus);
    const {amtSent, amtRecieved, selectedInputToken, selectedOutputToken, txCost, txHash} = props.receipt;

    return <>
        <Divider style={{marginTop: 40, marginBottom: 10}}>RECEIPT</Divider>
        <Paper
            style={{
                backgroundColor: 'white',
                color: "#000",
                padding: 20,
                fontFamily: 'monospace'
            }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace'}}>Spent</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                        {`${CoineusCryptoFormat(amtSent)} ${selectedInputToken.symbol}`}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace'}}>Received</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                        {`${CoineusCryptoFormat(amtRecieved)} ${selectedOutputToken.symbol}`}
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{marginTop: 20}}/>


                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace'}}>TxFee</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                        {`${CoineusCryptoFormat(txCost)} FUSE`}
                    </Typography>
                </Grid>


                <Grid item xs={6}/>
                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>
                        {`${CoineusUSDFormat(txCost * wallet.prices.fuse?.native?.inUSD)} USD`}
                    </Typography>
                </Grid>


                <Grid item xs={12} style={{marginTop: 20}}/>

                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace'}}>TxHash</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{fontFamily: 'monospace', textAlign: 'right'}}>

                        <Link
                            style={{color: "#000", textDecorationColor: '#000'}}
                            href={`https://explorer.fuse.io/tx/${txHash}`}
                            target="_blank">{`0x..${txHash.substr(-12)}`}</Link>
                    </Typography>
                </Grid>
            </Grid>

        </Paper>
    </>
}