import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const DEXScreenerApi = createApi({
    reducerPath: 'DEXScreenerApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.dexscreener.com/latest/dex/' }),
    endpoints: builder => ({
        getPair: builder.query({
            query: ({chain_id, pair_address}) => `pairs/${chain_id}/${pair_address}`
        }),
        getToken: builder.query({
            query: (token_address) => `tokens/${token_address}`
        })
    })
})

export const { useGetPairQuery, useGetTokenQuery } = DEXScreenerApi
