import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Grid, ListItem, ListItemAvatar, ListItemText, Paper, Skeleton} from "@mui/material";
import {CoineusUSDFormat} from "../utils/currency_format";

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


export default function PriceScroll() {

    const {tokens} = useSelector(state => state.coineus);

    const [list, setList] = useState([]);

    useEffect(() => {

        let tmp = [];
        Object.entries(tokens).map(([chain, tokens]) => {
            Object.entries(tokens).map(([contract_address, token_info]) => {

                const {name, symbol} = token_info;

                let priceChange;
                let priceUsd;
                try {
                    ({priceChange, priceUsd} = token_info.pair)
                } catch {
                    return[contract_address, token_info];
                }

                const t = {
                    name,
                    symbol,
                    chain,
                    contract_address,
                    priceChange: priceChange.h24 || 0,
                    priceUsd,
                }

                tmp.push(t);

                return[contract_address, token_info];
            });

            return [chain, tokens];
        });

        tmp.sort((a, b) => {

            if(a.priceChange < b.priceChange){
                return 1;
            }

            if(a.priceChange > b.priceChange){
                return -1;
            }

            return 0;
        })

        setList(tmp);
    }, [tokens])

    const getColorClass = (value) => {
        if (value === 0) return '';

        return value > 0 ? '#00FB4D' : 'red'
    }

    const getTrend = (priceChange) => {

        if(priceChange > 0) {
            return <><TrendingUpIcon style={{verticalAlign:'middle', marginRight: 5}}/>{`${priceChange}%`}</>
        }

        if(priceChange < 0) {
            return <><TrendingDownIcon style={{verticalAlign: 'middle', marginRight: 5}}/>{`${priceChange}%`}</>
        }

        return <>{`${priceChange}%`}</>
    }

    return <Grid item xs={12}>
        <div style={{
            marginTop: 10,
            display: 'grid',
            gridAutoFlow: 'column',
            gap: '1rem',
            height: 'calc(165px + 1rem)',
            overflowY: 'auto',
            overscrollBehaviorX: 'contain',
            scrollSnapType: 'x mandatory'
        }}>
            {
                list.length === 0 && <>
                {
                    [0,1,2].map(i => {
                        return <Paper style={{scrollSnapAlign: 'start', height: 115}} key={i}>
                            <ListItem style={{
                                width: 200,
                                maxWidth: 'none',
                                objectFit: 'contain'
                            }}>
                                <ListItemAvatar>
                                    <Skeleton variant="circular" width={40} height={40} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                                    secondary={<Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
                                />
                            </ListItem>
                        </Paper>
                    })
                }

                </>
            }

            {
                list.map(token => {
                    return <Paper style={{scrollSnapAlign: 'start', height: 155, marginTop: 25}} key={token.chain+"_"+token.contract_address}>

                        <img
                            src={`https://coineus.app/assets/tokens/${token.chain}/${token.contract_address === 'native' ? '' : token.contract_address + '/'}/logo.png`}
                            style={{
                                width: 50,
                                borderRadius: '50%',
                                marginTop: -25,
                                marginLeft: 'calc(50% - 25px)'
                            }}
                            alt="Token Logo"
                        />

                        <img
                            src={`https://coineus.app/assets/tokens/${token.chain}/logo.png`}
                            style={{
                                width: 20,
                                borderRadius: '50%',
                                top: -25,
                                marginLeft: 'calc(50% + 15px)',
                                position: 'relative',
                                visibility: token.contract_address === 'native' ? 'hidden' : 'visible'
                            }}
                            alt="Token Logo"
                        />

                        <ListItem style={{
                            width: 200,
                            maxWidth: 'none',
                            objectFit: 'contain',
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: -25
                        }}>
                            <ListItemText
                                primary={token.symbol}
                                primaryTypographyProps={{fontWeight: 700, variant: 'h5', textAlign: 'center'}}
                                secondary={CoineusUSDFormat(token.priceUsd)}
                                secondaryTypographyProps={{variant: 'body1', textAlign: 'center'}}
                            />
                        </ListItem>

                        <ListItem style={{
                            width: 200,
                            maxWidth: 'none',
                            objectFit: 'contain',
                            paddingTop: 0,
                            paddingBottom: 0
                        }}>
                            <ListItemText
                                primary={getTrend(token.priceChange)}
                                primaryTypographyProps={{fontWeight: 700, variant: 'h6', textAlign: 'center', color: getColorClass(token.priceChange)}}
                            />
                        </ListItem>
                    </Paper>
                })
            }
        </div>

    </Grid>
}