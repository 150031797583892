import {useEffect, useState} from "react";
import {ArcSeries, FlexibleWidthXYPlot} from "react-vis";
import {Typography} from "@mui/material";

export default function PercentageArc(props) {

    const {value, total, height, label} = props;

    const divider = 360 * .0075 * (Math.PI / 180);

    const [angle, setAngle] = useState(0);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setPercentage((value / total * 100));
        setAngle(360 * (value / total) * (Math.PI / 180));
    }, [value, total])

    return <>
        <FlexibleWidthXYPlot
            xDomain={[-5, 5]}
            yDomain={[-5, 5]}
            height={height}
            margin={{left: 0, right: 0, top: 0, bottom: 0}}
        >
            <ArcSeries
                radiusDomain={[0, 10]}
                center={{x: 0, y: 0}}
                data={[
                    {
                        angle0: 0,
                        angle: angle,
                        opacity: 1,
                        radius: 6.75,
                        radius0: 6,
                        color: '#fbca00',
                    },
                    {
                        angle0: angle + divider,
                        angle: (Math.PI * 2) - divider,
                        opacity: 1,
                        radius: 6.75,
                        radius0: 6,
                        color: '#333',
                    }
                ]}
                colorType={'literal'}
            />
        </FlexibleWidthXYPlot>

        <Typography
            style={{
                height: 0,
                width: '100%',
                textAlign: 'center',
                top: (height - 15) * -1,
                position: 'relative',
                fontSize: 13,
                fontWeight: '700'
            }}
        >
            {percentage.toFixed(0)}
        </Typography>
        <Typography
            style={{
                height: 0,
                width: '100%',
                textAlign: 'center',
                top: -5,
                position: 'relative',
                fontSize: 10
            }}
        >{label}</Typography>
    </>;
}