import {AreaSeries, FlexibleWidthXYPlot, GradientDefs, LineSeries} from "react-vis";

import '../../node_modules/react-vis/dist/style.css';
import {Tooltip} from "@mui/material";

export default function SparkChart(props, ref) {


    const {chartdata} = props;

    const marginTop = chartdata.length === 2 ? 20 : 5;

    return <Tooltip title={chartdata.length < 2 ? null : "Click to View"} style={{cursor: chartdata.length < 2 ? null : "pointer"}}>
        <div>
            <FlexibleWidthXYPlot
                height={40}
                strokeWidth={2}
                margin={{left: 5, right: 5, top: marginTop, bottom: 5}}
            >
                <GradientDefs>
                    <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="10%" stopColor="#fbca00" stopOpacity={0.3}/>
                        <stop offset="100%" stopColor="#1E1E1E" stopOpacity={0.1}/>
                    </linearGradient>
                </GradientDefs>
                <AreaSeries
                    fill={'url(#CoolGradient)'}
                    stroke={"transparent"}
                    data={chartdata}
                    // curve="curveNatural"
                />
                <LineSeries
                    color={"#fbca00"}
                    data={chartdata}
                    // curve="curveNatural"
                />
            </FlexibleWidthXYPlot>
        </div>
    </Tooltip>;
}