export function CoineusUSDFormat(number) {

    if(isNaN(number))return "$--";

    if(number < 1/(10**12)){
        return "$0";
    }

    // zeros after the decimals
    let maxDigits = parseFloat(number).toFixed(12).match(/(\.0*)/)[0].length + 1;

    //max 12
    if (maxDigits > 12) maxDigits = 12;
    // handle cases like 300.000000123
    if (number > 1) maxDigits = 2;

    // currency, at least 2 digits
    let minDigits = 2;
    // very small $ value, show at least 4
    if(number < 1) minDigits = 4;

    //sanity check, max can't be lower than min
    if(maxDigits < minDigits) maxDigits = minDigits;

    if(number < 1/(10**16)){
        minDigits = 2;
        maxDigits = 2;
    }

    const NumberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
        currency: 'USD',
        currencySign: 'standard',
        currencyDisplay: 'symbol',
        style: 'currency'
    });

    return NumberFormatter.format(number);
}

export function CoineusCryptoFormat(number) {

    if(isNaN(number))return "--";

    if(number < 1/(10**8)){
        return 0;
    }

    let maxFraction = 4;

    if (number > 1000) maxFraction = 0;

    if (number < 1) maxFraction = 4;
    if (number < .1) maxFraction = 5;
    if (number < .01) maxFraction = 5;
    if (number < .001) maxFraction = 7;
    if (number < .0001) maxFraction = 8;
    if (number < .00001) maxFraction = 9;
    if (number < .000001) maxFraction = 10;
    if (number < .0000001) maxFraction = 11;
    if (number < .00000001) maxFraction = 12;
    if (number < .000000001) maxFraction = 13;
    if (number < .0000000001) maxFraction = 14;
    if (number < .00000000001) maxFraction = 15;
    if (number < .000000000001) maxFraction = 16;

    const NumberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxFraction,
    });

    return NumberFormatter.format(number);
}