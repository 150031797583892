import {useEffect} from "react";
import tokenList from "../utils/tokenList.json";
import axios from "axios";
import {useDispatch} from "react-redux";
import {updateTokens} from "../redux/slices/coineusSlice";

export default function useDexScreener() {

    const dispatch = useDispatch();

    let tl = {...tokenList};

    useEffect(() => {

        const getDexScreener = () => {
            let calls = [];
            Object.entries(tl.tokens).map(([chain,tokens]) => {

                Object.entries(tokens).map(([contract_address, token_info]) => {

                    if(token_info.dexscreenerChain && token_info.dexscreenerPair) {
                        calls.push(axios
                            .get(`https://api.dexscreener.com/latest/dex/pairs/${token_info.dexscreenerChain}/${token_info.dexscreenerPair}`)
                            .then(resp => {
                                const {pair} = resp.data;
                                if(pair){
                                    try{
                                        token_info.pair = pair;
                                    } catch (e) {
                                        // console.error('error caught', token_info);
                                    }
                                }
                            }));
                    }

                    return [contract_address, token_info];
                })
                return [chain,tokens];
            })

            Promise.all(calls).then(() => {
                dispatch(updateTokens(tl.tokens));
            })
        }

        getDexScreener();
        const interval = setInterval(getDexScreener, 60000)
        return () => clearInterval(interval);
    }, [])

    return {
        tl
    }

}