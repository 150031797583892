import {ApolloClient, InMemoryCache, gql} from '@apollo/client';
import {useEffect, useState} from "react";
import {
    Avatar, AvatarGroup, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Paper, Typography
} from "@mui/material";
import Web3 from "web3";
import {CoineusCryptoFormat, CoineusUSDFormat} from "../utils/currency_format";
import {useSelector} from "react-redux";
import masterchef_abi from "../abis/Voltage/MasterChefVoltV3.json";
import {makeBatchRequest} from "../utils/promisify";
import CoineusLoading from "../components/CoineusLoading";
import router_abi from "../abis/IPancakeRouter.json";
import erc20_abi from "../abis/IERC20.json";
import {toWei} from "../utils/format";
import {CHAIN_IDS} from "../constants";
import {NetworkButton} from "../components/Coineus";

const client = new ApolloClient({
    // uri: 'https://api.thegraph.com/subgraphs/name/voltfinance/voltage-exchange-v2', cache: new InMemoryCache(),
    uri: "https://gateway-arbitrum.network.thegraph.com/api/bc91dc2c250c62e490cae0595ad30d67/subgraphs/id/B4BGk9itvmRXzzNRAzBWwQARHRt3ZvLz11aWNVsZPT4",
    cache: new InMemoryCache(),
});

const VOLTAGE_MASTERCHEF = "0xE3e184a7b75D0Ae6E17B58F5283b91B4E0A2604F";

export default function VoltageLiquidity() {

    const {wallet, rpc} = useSelector(state => state.coineus);

    const [pending, setPending] = useState(false);
    const [positions, setPositions] = useState([]);
    const [userPoolInfo, setUserPoolInfo] = useState({})

    useEffect(() => {
        if (!wallet.address) return;
        client
            .query({
                query: gql`{
  user(id: "${wallet.address?.toLowerCase()}") {
    liquidityPositions(where:{
      liquidityTokenBalance_gt:0
    }) {
      liquidityTokenBalance
      pair {
        id
        name
        token0 {
          id
          name
          symbol
          decimals
        }
        token1 {
          id
          name
          symbol
          decimals
        }
        reserve0
        reserve1
        totalSupply
        reserveUSD
        token0Price
        token1Price
        volumeToken0
        volumeToken1
        volumeUSD
      }
    }
  }
}`,
            })
            .then((result) => {
                if (result.data.user) setPositions(result.data.user.liquidityPositions)
            });
    }, [wallet.address]);

    const getUserInfo = () => {
        if (!wallet.address) return;

        const web3 = new Web3(rpc.fuse);
        const voltage = new web3.eth.Contract(masterchef_abi, VOLTAGE_MASTERCHEF);

        voltage.methods.poolLength().call((err, resp) => {

            const poolCount = parseInt(resp);

            const calls = []
            for (let i = 0; i < poolCount; i++) {
                calls.push(voltage.methods.poolInfo(i).call)
            }
            for (let i = 0; i < poolCount; i++) {
                calls.push(voltage.methods.userInfo(i, wallet.address).call)
            }
            for (let i = 0; i < poolCount; i++) {
                calls.push(voltage.methods.pendingTokens(i, wallet.address).call)
            }

            makeBatchRequest(web3, calls).then(resp => {

                const userData = {}

                for (let i = 0; i < poolCount; i++) {

                    const {accVoltPerShare, allocPoint, lastRewardTimestamp, lpToken, rewarder} = resp[i]
                    const {amount, rewardDebt} = resp[i + poolCount]
                    const {
                        bonusTokenAddress, bonusTokenSymbol, pendingBonusToken, pendingVolt
                    } = resp[i + (poolCount * 2)];

                    userData[lpToken] = {
                        poolIndex: i,
                        accVoltPerShare,
                        allocPoint,
                        lastRewardTimestamp,
                        lpToken,
                        rewarder,
                        amount: amount / 1,
                        rewardDebt,
                        bonusTokenAddress,
                        bonusTokenSymbol,
                        pendingBonusToken,
                        pendingVolt
                    }
                }
                setUserPoolInfo(userData);
            })

        })
    }
    useEffect(() => {
        getUserInfo();
        const interval = setInterval(getUserInfo, 10000)
        return () => clearInterval(interval);
    }, [wallet.address]);

    const claimRewards = (poolIndex) => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(masterchef_abi, VOLTAGE_MASTERCHEF);

            contract.methods
                .deposit(poolIndex, 0)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getUserInfo();
                    setPending(false);
                });
        }
    }

    const withdraw = (poolIndex, balance) => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(masterchef_abi, VOLTAGE_MASTERCHEF);

            contract.methods
                .withdraw(poolIndex, balance)
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getUserInfo();
                    setPending(false);
                });
        }
    }

    const removeLP = (lpToken, tokenA, tokenB, liquidity, amountA, amountB) => {
        console.log(lpToken, tokenA, tokenB, toWei(liquidity), toWei(amountA), toWei(amountB))

        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(router_abi, '0xE3F85aAd0c8DD7337427B9dF5d0fB741d65EEEB5');

            const lp_contract = new web3.eth.Contract(erc20_abi, lpToken);

            lp_contract.methods.allowance(wallet.address, '0xE3F85aAd0c8DD7337427B9dF5d0fB741d65EEEB5').call((err, resp) => {

                if (err) return;

                if (resp < liquidity) {
                    lp_contract
                        .methods
                        .approve('0xE3F85aAd0c8DD7337427B9dF5d0fB741d65EEEB5', toWei(liquidity))
                        .send({from: wallet.address})
                        .once('transactionHash', function (tx) {
                            setPending(true)
                        })
                        .on('error', function (error) {
                            setPending(false)
                        })
                        .then(function (receipt) {
                            setPending(false)
                            remove()
                        });
                } else {
                    remove()
                }
            })

            const remove = () => {
                contract.methods
                    .removeLiquidity(tokenA, tokenB, toWei(liquidity), toWei(amountA * .99), toWei(amountB * .99), wallet.address, 11111111111)
                    .send({from: wallet.address})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        getUserInfo();
                        setPending(false);
                    });
            }
        }
    }

    return <Grid container spacing={2} style={{marginTop: 20, paddingBottom: 80}}>

        {positions.length === 0 && <Grid item xs={12}>
            <Paper style={{padding: 20}}>
                <Typography>No Liquidity Positions Found</Typography>
            </Paper>
        </Grid>}


        {positions.map((position, i) => {

            const {liquidityTokenBalance: balance, pair} = position;

            const {
                id,
                name,
                token0,
                token1,
                reserve0,
                reserve1,
                totalSupply,
                reserveUSD,
            } = pair;

            const share = (balance / totalSupply);

            const lpToken = Web3.utils.toChecksumAddress(id);

            return <Grid item xs={12} key={id}>
                <Paper>
                    <List dense>
                        <ListItem>
                            <ListItemAvatar>
                                <AvatarGroup>
                                    <Avatar
                                        src={`https://coineus.app/assets/tokens/fuse/${Web3.utils.toChecksumAddress(token0.id)}/logo.png`}/>
                                    <Avatar
                                        src={`https://coineus.app/assets/tokens/fuse/${Web3.utils.toChecksumAddress(token1.id)}/logo.png`}/>
                                </AvatarGroup>
                            </ListItemAvatar>
                            <ListItemText
                                primary={name}
                                secondary={(share * 100).toFixed(2) + "% Pool Share"}
                                primaryTypographyProps={{marginLeft: 1, fontWeight: 700}}
                                secondaryTypographyProps={{marginLeft: 1}}
                            />
                            <ListItemText
                                primary={CoineusCryptoFormat(balance)}
                                secondary={CoineusUSDFormat(reserveUSD * share)}
                                primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                                secondaryTypographyProps={{textAlign: 'right'}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={token0.name}
                                primaryTypographyProps={{fontWeight: 700}}
                            />
                            <ListItemText
                                primary={CoineusCryptoFormat(reserve0 * share)}
                                primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={token1.name}
                                primaryTypographyProps={{fontWeight: 700}}
                            />
                            <ListItemText
                                primary={CoineusCryptoFormat(reserve1 * share)}
                                primaryTypographyProps={{textAlign: 'right', fontWeight: 700}}
                            />
                        </ListItem>
                        <ListItem>
                            <NetworkButton network={CHAIN_IDS.FUSE}>
                                <Button fullWidth variant="contained"
                                        onClick={() => removeLP(lpToken, token0.id, token1.id, balance, reserve0 * share, reserve1 * share)}>REMOVE</Button>
                            </NetworkButton>
                        </ListItem>
                        {userPoolInfo[lpToken]?.amount > 0 && <>
                            <ListSubheader>Pending Farming Rewards</ListSubheader>
                            <ListItem>
                                <ListItemText
                                    primary="Deposited"
                                />
                                <ListItemText
                                    primary={CoineusCryptoFormat(userPoolInfo[lpToken]?.amount / (10 ** 18))}
                                    primaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar style={{minWidth: 30}}>
                                    <Avatar
                                        src="https://coineus.app/assets/tokens/fuse/0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4/logo.png"
                                        sx={{width: 24, height: 24}}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="VOLT"
                                />
                                <ListItemText
                                    primary={CoineusCryptoFormat(userPoolInfo[lpToken]?.pendingVolt / (10 ** 18))}
                                    primaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar style={{minWidth: 30}}>
                                    <Avatar
                                        src={`https://coineus.app/assets/tokens/fuse/${userPoolInfo[lpToken]?.bonusTokenAddress}/logo.png`}
                                        sx={{width: 24, height: 24}}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={userPoolInfo[lpToken]?.bonusTokenSymbol}
                                />
                                <ListItemText
                                    primary={CoineusCryptoFormat(userPoolInfo[lpToken]?.pendingBonusToken / (10 ** 18))}
                                    primaryTypographyProps={{textAlign: 'right'}}
                                />
                            </ListItem>

                            <ListItem style={{marginTop: 10}}>
                                <NetworkButton network={CHAIN_IDS.FUSE}>
                                    <Button variant="contained" fullWidth size="small"
                                            onClick={() => claimRewards(userPoolInfo[lpToken]?.poolIndex)}>Claim</Button>
                                </NetworkButton>
                            </ListItem>

                            <ListItem style={{marginTop: 10}}>
                                <NetworkButton network={CHAIN_IDS.FUSE}>
                                    <Button variant="contained" fullWidth size="small"
                                            onClick={() => withdraw(userPoolInfo[lpToken]?.poolIndex, balance)}>Withdraw</Button>
                                </NetworkButton>
                            </ListItem>
                        </>}
                    </List>
                </Paper>
            </Grid>
        })}
        <CoineusLoading open={pending} label="Transaction Pending"/>
    </Grid>
}