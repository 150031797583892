import Web3 from "web3";
import {useSelector} from "react-redux";
import {Avatar, Button, Typography} from "@mui/material";
import {CHAIN_IDS_TO_LOGO, CHAIN_IDS_TO_DISPLAY_NAMES} from '../../../constants';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {useEffect, useRef, useState} from "react";

const avatarSX = {width: 24, height: 24}

const NetworkButton = (props) => {

    const {wallet} = useSelector(state => state.coineus);
    const { children, network } = props;

    const buttonParentRef = useRef(null);

    const [buttonParentWidth, setButtonParentWidth] = useState(0);

    useEffect(()=>{
        setButtonParentWidth(buttonParentRef.current?.clientWidth)
    }, [buttonParentRef.current])

    if(!network){
        throw new Error('No Network Provided');
    }

    const checkChain = () => {
        // if(!wallet.address)return false;
        return wallet.chain === network;
    }

    const changeNetwork = async () => {
        if(!wallet.address)return false;
        const web3 = new Web3(window.provider);
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: web3.utils.toHex(network)}], // chainId must be in hexadecimal numbers
        });
    }

    return <>
        {
            checkChain() ?
                children
                :
                <Button
                    {...children.props}
                    disabled={false}
                    onClick={changeNetwork}
                    ref={buttonParentRef}
                >
                    <Avatar
                        src={CHAIN_IDS_TO_LOGO[wallet.chain]}
                        sx={avatarSX} />
                    <Typography variant="body2" style={{marginLeft: 5, fontWeight: 700, display: buttonParentWidth > 100 ? '' : 'none'}}>{CHAIN_IDS_TO_DISPLAY_NAMES[wallet.chain]}</Typography>
                    <ArrowRightAltIcon style={{margin: '0 5px'}}/>
                    <Avatar
                        src={CHAIN_IDS_TO_LOGO[network]}
                        sx={avatarSX}
                    />
                    <Typography variant="body2" style={{marginLeft: 5, fontWeight: 700, display: buttonParentWidth > 100 ? '' : 'none'}}>{CHAIN_IDS_TO_DISPLAY_NAMES[network]}</Typography>
                </Button>
        }
    </>;
}

export default NetworkButton;