import {Link, List, ListItem, ListItemText, ListSubheader} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useSelector} from "react-redux";

const coineus_fund_address = "0xb80267eA7fa368374ee4d4Bf10044778232AdEFe";

export default function CoineusMenu(props) {

    const {wallet} = useSelector(state => state.coineus);

    return (<List>

        <ListSubheader>COINEUS.APP</ListSubheader>
        <ListItem button component={RouterLink} to={'/'}>
            <ListItemText primary="My Wallet" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={RouterLink} to={'/staking'}>
            <ListItemText primary="Stake FUSE" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={RouterLink} to={'/earn'}>
            <ListItemText primary="Earn" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={RouterLink} to={'/farm'}>
            <ListItemText primary="Farm" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={RouterLink} to={'/faucet'}>
            <ListItemText primary="Faucets" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={RouterLink} to={'/swap'}>
            <ListItemText primary="Swap" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={Link}
                  href={"https://debank.com/profile/0xb80267eA7fa368374ee4d4Bf10044778232AdEFe/"}
                  target="_blank">
            <ListItemText primary="Fund Holdings" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={RouterLink} to={'/voltage-liquidity'}>
            <ListItemText primary="My Voltage LP" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>

        {wallet.address?.toLowerCase() === coineus_fund_address.toLowerCase() &&
            <ListItem button component={RouterLink} to={'/admin'}>
                <ListItemText primary="Admin" primaryTypographyProps={{style: {fontWeight: 700}}}/>
            </ListItem>}


        <ListItem button component={RouterLink} to={'/liquidity-dashboard'}>
            <ListItemText primary="LP Dashboard" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>

        <ListItem button component={RouterLink} to={'/tasks'}>
            <ListItemText primary="Tasks" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>

        <ListSubheader>CEUS TOKEN</ListSubheader>
        <ListItem button component={RouterLink} to={'/stats'}>
            <ListItemText primary="Stats" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={Link}
                  href={'https://explorer.fuse.io/token/0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8'}
                  target="_blank">
            <ListItemText primary="Contract" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={Link}
                  href={'https://app.voltage.finance/swap?outputCurrency=0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8'}
                  target="_blank">
            <ListItemText primary="Buy" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={Link}
                  href={'https://dexscreener.com/fuse/0x3b46efdd411cc0db43f1be7853727e18cce8b85b'}
                  target="_blank">
            <ListItemText primary="Chart" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>

        {/*<ListSubheader>BRIDGES</ListSubheader>*/}
        {/*<ListItem button component={RouterLink} to={'/easybridge'}>*/}
        {/*    <ListItemText primary="Coineus Easy Bridge" primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem button component={Link} href={'https://router.via.exchange/avalanche/AVAX/fuse/FUSE'}*/}
        {/*          target="_blank">*/}
        {/*    <ListItemText primary="Via Exchange" primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem button component={Link} href={'https://app.voltage.finance/bridge'} target="_blank">*/}
        {/*    <ListItemText primary="Voltage Bridge" primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}

        <ListSubheader>SOCIAL</ListSubheader>
        <ListItem button component={Link} href={'https://t.me/coineusportal'} target="_blank">
            <ListItemText primary="Telegram" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        <ListItem button component={Link} href={'https://twitter.com/CoineusCrypto'} target="_blank">
            <ListItemText primary="Twitter" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        {/*<ListItem button component={Link} href={'https://discord.gg/FAsz89g5RT'} target="_blank">*/}
        {/*    <ListItemText primary="Discord" primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem button component={Link}*/}
        {/*          href={'https://www.youtube.com/channel/UCehh09nIOF-2BJ6YyIq3-RQ'} target="_blank">*/}
        {/*    <ListItemText primary="YouTube" primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}
        {/*<ListItem button component={Link} href={'https://medium.com/@coineus'} target="_blank">*/}
        {/*    <ListItemText primary="Medium" primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}
        <ListItem button component={Link} href={'https://github.com/CoineusCrypto/'} target="_blank">
            <ListItemText primary="GitHub" primaryTypographyProps={{style: {fontWeight: 700}}}/>
        </ListItem>
        {/*<ListItem button component={Link} href={'mailto:contact@coineus.app'} target="_blank">*/}
        {/*    <ListItemText primary="contact@coineus.app"*/}
        {/*                  primaryTypographyProps={{style: {fontWeight: 700}}}/>*/}
        {/*</ListItem>*/}
    </List>)
}