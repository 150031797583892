import {useEffect, useState} from "react";
const TWEEN = require('@tweenjs/tween.js')

export default function AnimatedPriceWidget(props) {

  const {price, time, fixed} = props;
  const [newPrice, setNewPrice] = useState(parseFloat(price).toFixed(fixed));
  const [prevPrice, setPrevPrice] = useState(parseFloat(price).toFixed(fixed));

  useEffect(() => {
    if(price !== prevPrice){
      const tween = new TWEEN.Tween({p: parseFloat(prevPrice).toFixed(fixed)});
      tween
        .to({p: parseFloat(price).toFixed(fixed)}, time)
        .start()
        .onUpdate(object => {
          setNewPrice((parseFloat((object.p).toFixed(fixed))).toLocaleString(navigator.language, {minimumFractionDigits: fixed}));
        })
        .onComplete(()=>{
          setPrevPrice(parseFloat(price).toFixed(fixed));
        });
    }
  }, [price, prevPrice, fixed, time]);

  return <span>{newPrice}</span>
}
