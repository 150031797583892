import {Avatar, Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuList} from "@mui/material";
import {useSelector} from "react-redux";
import {useState} from "react";
import {CHAIN_IDS} from "../constants";
import Web3 from "web3";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ChainSelector() {
    const {wallet} = useSelector(state => state.coineus);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeNetwork = async (network) => {
        if(!wallet.address)return false;
        const web3 = new Web3(window.provider);
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: web3.utils.toHex(network)}], // chainId must be in hexadecimal numbers
        });
        setAnchorEl(null)
    }

    const checkIconSize = {width: 16, height: 16}

    return <>
        <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={handleClick}
            style={{minWidth:0, display: !wallet.chain ? 'none' : ''}}
        >{getChainLogo(wallet.chain)}</Button>

        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
        >
            <MenuList>
                <MenuItem onClick={()=>changeNetwork(CHAIN_IDS.FUSE)}>
                    <ListItemIcon>
                        {getChainLogo(CHAIN_IDS.FUSE)}
                    </ListItemIcon>
                    <ListItemText>Fuse Network {wallet.chain === CHAIN_IDS.FUSE ? <CheckCircleIcon color="primary" sx={checkIconSize}/> : ''}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>changeNetwork(CHAIN_IDS.BNB)}>
                    <ListItemIcon>
                        {getChainLogo(CHAIN_IDS.BNB)}
                    </ListItemIcon>
                    <ListItemText>Binance Smart Chain {wallet.chain === CHAIN_IDS.BNB ? <CheckCircleIcon color="primary" sx={checkIconSize}/> : ''}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>changeNetwork(CHAIN_IDS.AVAX)}>
                    <ListItemIcon>
                        {getChainLogo(CHAIN_IDS.AVAX)}
                    </ListItemIcon>
                    <ListItemText>Avalance {wallet.chain === CHAIN_IDS.AVAX ? <CheckCircleIcon color="primary" sx={checkIconSize}/> : ''}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>changeNetwork(CHAIN_IDS.ETH)}>
                    <ListItemIcon>
                        {getChainLogo(CHAIN_IDS.ETH)}
                    </ListItemIcon>
                    <ListItemText>Ethereum {wallet.chain === CHAIN_IDS.ETH ? <CheckCircleIcon color="primary" sx={checkIconSize}/> : ''}</ListItemText>
                </MenuItem>
            </MenuList>
        </Menu>


    </>
}

function getChainLogo(id) {
    const size = {width: 22, height: 22}
    const style = {}
    switch (id) {
        case 1:
            return <Avatar src='https://coineus.app/assets/tokens/eth/logo.png' sx={size} style={style}/>
        case 56:
            return <Avatar src='https://coineus.app/assets/tokens/bnb/logo.png' sx={size} style={style}/>
        case 122:
            return <Avatar src='https://coineus.app/assets/tokens/fuse/logo.png' sx={size} style={style}/>
        case 43114:
            return <Avatar src='https://coineus.app/assets/tokens/avax/logo.png' sx={size} style={style}/>
        default:
            return ''
    }
}