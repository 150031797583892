import {
    Avatar,
    Button,
    Dialog, DialogContent,
    InputAdornment,
    ListItemAvatar, ListItemText,
    MenuItem,
    MenuList
} from "@mui/material";
import {useTheme} from "@mui/styles";
import {useState} from "react";
import {useSelector} from "react-redux";
import {CoineusCryptoFormat} from "../../utils/currency_format";

const tokens = [
    {
        name: 'Fuse',
        symbol: 'FUSE',
        contract_address: 'native',
        decimals: 18
    },
    {
        name: 'Coineus',
        symbol: 'CEUS',
        contract_address: '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8',
        decimals: 18
    },
    {
        name: 'Lightning',
        symbol: 'LIT',
        contract_address: '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F',
        decimals: 18
    },
    {
        name: 'Lady',
        symbol: 'LADY',
        contract_address: '0xDe4b9879B56187D13B2c41Da24c72Ff100A5AC9A',
        decimals: 9
    },
    // {
    //     name: 'BabyKrakens',
    //     symbol: 'BBKS',
    //     contract_address: '0x6e18e35997aacBF017902e63ca789d74DF1b5A2b',
    //     decimals: 9
    // },
    {
        name: 'Fates Violent Kiss',
        symbol: 'FVKSRG',
        contract_address: '0xa28a758619ba32B11F8D55eB6C0E1C7E5929bF61',
        decimals: 18
    },
    {
        name: 'Foo',
        symbol: 'FOO',
        contract_address: '0x74616164eB1892ceC5fa553D45b3e5D6dF7BC7b9',
        decimals: 18
    }
]

export default function TokenList(props) {
    const theme = useTheme();

    const {wallet} = useSelector(state => state.coineus);

    const [open, setOpen] = useState(false);
    const {selectedToken, setSelectedToken} = props;

    return <>
        <InputAdornment position="start">
            <Button
                variant="contained"
                color="primary"
                style={{
                    backgroundColor: theme.palette.background.paper,
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                    color: "#fff"
                }}
                startIcon={<Avatar
                    src={`https://coineus.app/assets/tokens/fuse/${selectedToken.contract_address === 'native' ? '' : selectedToken.contract_address + "/"}logo.png`}
                    style={{width: 24, height: 24}}/>}
                onClick={() => setOpen(true)}
            >{selectedToken.symbol}</Button>
        </InputAdornment>

        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent style={{padding: 5}}>
                <MenuList disablePadding>
                    {
                        tokens.map(token => {
                            return <MenuItem key={token.symbol} onClick={() => {
                                setSelectedToken(token);
                                setOpen(false)
                            }}>
                                <ListItemAvatar>
                                    <Avatar
                                        src={`https://coineus.app/assets/tokens/fuse/${token.contract_address === 'native' ? '' : token.contract_address + "/"}logo.png`}/>
                                </ListItemAvatar>
                                <ListItemText primary={token.symbol} secondary={token.name}/>
                                <ListItemText primary={CoineusCryptoFormat(wallet.balances.fuse?.[token.contract_address])} primaryTypographyProps={{textAlign: 'right'}} />
                            </MenuItem>
                        })
                    }

                </MenuList>
            </DialogContent>
            <Button onClick={() => setOpen(false)}>Close</Button>
        </Dialog>
    </>;
}