export const makeBatchRequest = (web3, calls, memo = "") => {
    let batch = new web3.BatchRequest();

    let promises = calls.map(call => {
        return new Promise((res, rej) => {

            if(call instanceof Promise){
                return call.then(resp=>res(resp)).catch(err=>{
                    console.log('error in _batch', memo, err, call)
                    rej(err)
                });
            }

            let req = call.request({}, (err, data) => {
                if(err) {
                    console.log('error in batch', memo, err, call)
                    rej(err);
                }
                else res(data)
            });
            batch.add(req)
        })
    })
    batch.execute()

    return Promise.all(promises);
}

