import {useSelector} from "react-redux";
import Web3 from "web3";
import {makeBatchRequest} from "../utils/promisify";
import {useEffect, useState} from "react";
import {
    Avatar, Button,
    Grid, IconButton,
    List,
    ListItem, ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import {CoineusCryptoFormat} from "../utils/currency_format";
import CoineusLoading from "../components/CoineusLoading";
import lightning_tasks_abi from "../abis/LightningTasks.json";
import {CHAIN_IDS_TO_NAMES} from "../constants";
import {formatWeiToNumber} from "../utils/format";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import router_abi from "../abis/IPancakeRouter.json";

const routerAddress = '0xE3F85aAd0c8DD7337427B9dF5d0fB741d65EEEB5';

export default function LightningTasks(props) {

    const {contract_address, description} = props;
    const {wallet, rpc} = useSelector(state => state.coineus);

    const [info, setInfo] = useState({});
    const [litOut, setLitOut] = useState(0);

    const getInfo = () => {
        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(lightning_tasks_abi, contract_address);

        if (!wallet.address) {
            return;
        }

        const calls = [
            contract.methods.getClaimableAmount().call,
            contract.methods.getClaimPaused().call,
            contract.methods.getDelegatedAmount().call
        ]

        makeBatchRequest(web3, calls).then(resp => {

            const [
                claimable,
                paused,
                delegated
            ] = resp;

            setInfo({
                claimable: claimable/1,
                paused,
                delegated: delegated/1
            });
        });
    }

    useEffect(() => {
        getInfo();
        getAmountsOut();

        const interval = setInterval(()=>{
            getInfo();
            getAmountsOut();
        }, 30000)
        return () => clearInterval(interval);
    }, [wallet.address]);

    const getAmountsOut = () => {

        if(Object.entries(info).length <= 0) return;

        if (info.claimable <= 0) return;

        const web3 = new Web3(rpc.fuse);
        const contract = new web3.eth.Contract(router_abi, routerAddress);

        const route = [
            '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629', //WFUSE
            '0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8', //CEUS
            '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F' //LIT
        ]

        contract.methods.getAmountsOut(info.claimable.toString(), route).call((err, resp) => {
            setLitOut(resp[resp.length - 1]);
        })
    }

    return (
        <Grid item xs={12}>
            <Paper>
                <FacuetDetails
                    faucet={contract_address}
                    details={info}
                    litOut={litOut}
                    description={description}
                    getInfo={getInfo}
                />
            </Paper>
        </Grid>
    )
}

function FacuetDetails(props) {

    const {wallet} = useSelector(state => state.coineus);

    const {faucet, details, description, getInfo} = props;

    const [pending, setPending] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const process = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(lightning_tasks_abi, faucet);

            contract.methods
                .process()
                .send({
                    from: wallet.address,
                    gasPrice: 10000000000
                })
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    getInfo();
                    setPending(false);
                });
        }
    }

    //update disabled status
    useEffect(() => {

        if (!wallet.address) {
            setDisabled(true)
            return;
        }

        if (CHAIN_IDS_TO_NAMES[wallet.chain] !== "fuse") {
            setDisabled(true)
            return;
        }
        if (details.paused) {
            setDisabled(true);
            return;
        }

        if (details.claimable < (10**18)) {
            setDisabled(true);
            return;
        }

        setDisabled(false);

    }, [details])

    const deposit = (amount) => {

        const web3 = new Web3(window.provider);
        const cron_contract = new web3.eth.Contract(lightning_tasks_abi, faucet);

        cron_contract.methods.donate().send(
            {
                from: wallet.address,
                value: web3.utils.toWei(amount),
                gas: 10000000
            }).on('error', function (error) {
            setPending(false)
        })
            .then(function () {
                getInfo();
                setPending(false);
            });

    }

    return <Grid item xs={12}>
        <Paper>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={`https://coineus.app/assets/tokens/fuse/logo.png`}/>
                    </ListItemAvatar>

                    <ListItemText primary={CoineusCryptoFormat(formatWeiToNumber(details.delegated, 18))}
                                  secondary="Staked"/>

                    <ListItemText
                        primary={CoineusCryptoFormat(formatWeiToNumber(details.claimable, 18))}
                        secondary={`To Process`}
                    />
                    <ListItemSecondaryAction>

                        <IconButton onClick={process} disabled={disabled} color="primary">
                            <AutoFixHighIcon/>
                        </IconButton>

                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary={description} primaryTypographyProps={{textAlign: 'center'}}/>
                </ListItem>
            </List>


            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{paddingBottom: 20}}>
                <Grid item>
                    <Typography variant="body2">Donate FUSE</Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => deposit('10')}
                        size="small"
                    >10</Button>

                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => deposit('100')}
                        size="small"
                    >100</Button>

                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={() => deposit('1000')}
                        size="small"
                    >1000</Button>

                </Grid>
            </Grid>
        </Paper>
        <CoineusLoading open={pending} label="Transaction Pending"/>
    </Grid>
}