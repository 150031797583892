import {
    Button,
    FormControl,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import Web3 from "web3";
import ceus_abi from "../abis/ceus_abi.json";
import ceusone_abi from "../abis/Coineus/CEUSONE.json";
import lit_abi from "../abis/Lightning.json";
import {NetworkButton} from "../components/Coineus";
import {CHAIN_IDS} from "../constants";
import {useSelector} from "react-redux";
import {useState} from "react";
import CoineusLoading from "../components/CoineusLoading";

const COINEUS_FUND_ADDRESS = "0xb80267eA7fa368374ee4d4Bf10044778232AdEFe";
const CEUS_CONTRACT_ADDRESS = "0x4e69Ae0CD024754655b4eF74F24A8DCB39Ba07e8";
const CEUSONE_CONTRACT_ADDRESS = "0xE27431FACb1F093BA164c9F86B3f484b39ad54CC";
const LIT_CONTRACT_ADDRESS = "0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F";


export default function Admin() {

    const {wallet} = useSelector(state => state.coineus);

    const [pending, setPending] = useState(false);
    const [amount, setAmount] = useState("");

    const burn = () => {
        const web3 = new Web3(window.provider);
        const ceus_contract = new web3.eth.Contract(ceus_abi, CEUS_CONTRACT_ADDRESS);

        ceus_contract.methods.performBurn(web3.utils.toWei(amount))
            .send({from: wallet.address})
            .once('transactionHash', function (tx) {
                setPending(true)
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                setPending(false);
            });
    }

    const burnLIT = () => {
        const web3 = new Web3(window.provider);
        const lit_contract = new web3.eth.Contract(lit_abi, LIT_CONTRACT_ADDRESS);

        lit_contract.methods.performBurn(web3.utils.toWei(amount))
            .send({from: wallet.address})
            .once('transactionHash', function (tx) {
                setPending(true)
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                setPending(false);
            });
    }

    const reflectCEUSONE = () => {
        const web3 = new Web3(window.provider);
        const ceusone_contract = new web3.eth.Contract(ceusone_abi, CEUSONE_CONTRACT_ADDRESS);

        ceusone_contract.methods.rCEUSONEToEveryone(web3.utils.toWei(amount))
            .send({from: wallet.address})
            .once('transactionHash', function (tx) {
                setPending(true)
            })
            .on('error', function (error) {
                setPending(false)
            })
            .then(function (receipt) {
                setPending(false);
            });
    }

    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        {
            wallet.address?.toLowerCase() === COINEUS_FUND_ADDRESS.toLowerCase() && <>
                <Grid item xs={12}>
                    <Paper style={{padding: 20}}>
                        <Typography variant="h6">Burn CEUS</Typography>
                        <FormControl fullWidth style={{marginBottom: 20, marginTop: 20}}>
                            <TextField
                                autoComplete="off"
                                type="number"
                                value={amount}
                                placeholder="Enter Amount"
                                onChange={(ev) => {
                                    setAmount(ev.target.value)
                                }}
                            />
                        </FormControl>
                        <NetworkButton network={CHAIN_IDS.FUSE}>
                            <Button fullWidth variant="contained"
                                    disabled={!amount}
                                    onClick={burn}>BURN CEUS</Button>
                        </NetworkButton>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{padding: 20}}>
                        <Typography variant="h6">Burn LIT</Typography>
                        <FormControl fullWidth style={{marginBottom: 20, marginTop: 20}}>
                            <TextField
                                autoComplete="off"
                                type="number"
                                value={amount}
                                placeholder="Enter Amount"
                                onChange={(ev) => {
                                    setAmount(ev.target.value)
                                }}
                            />
                        </FormControl>

                        <NetworkButton network={CHAIN_IDS.FUSE}>
                            <Button fullWidth variant="contained"
                                    disabled={!amount}
                                    onClick={burnLIT}>BURN LIT</Button>
                        </NetworkButton>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{padding: 20}}>
                        <Typography variant="h6">Reflect CEUSONE</Typography>
                        <FormControl fullWidth style={{marginBottom: 20, marginTop: 20}}>
                            <TextField
                                autoComplete="off"
                                type="number"
                                value={amount}
                                placeholder="Enter Amount"
                                onChange={(ev) => {
                                    setAmount(ev.target.value)
                                }}
                            />
                        </FormControl>
                        <NetworkButton network={CHAIN_IDS.FUSE}>
                            <Button fullWidth variant="contained"
                                    disabled={!amount}
                                    onClick={reflectCEUSONE}>REFLECT CEUSONE</Button>
                        </NetworkButton>
                    </Paper>
                </Grid>
            </>
        }
        <CoineusLoading open={pending} label="Transaction Pending"/>
    </Grid>
}