import {
    Avatar,
    Button,
    Divider,
    Grid, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper, Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import Web3 from "web3";
import faucet_abi from "../../abis/Coineus/CEUSONEFaucet.json";
import {makeBatchRequest} from "../../utils/promisify";
import {useEffect, useState} from "react";
import {CoineusCryptoFormat} from "../../utils/currency_format";
import {formatWeiToNumber} from "../../utils/format";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import CoineusLoading from "../../components/CoineusLoading";
import PaidIcon from '@mui/icons-material/Paid';
import {CHAIN_IDS_TO_NAMES} from "../../constants";
import {
    Link as RouterLink
} from "react-router-dom";


const CEUSONE_FAUCET_ADDRESS = '0x3E4a3A2459A2Fa02544E792D728917C708872922';
const CEUSONE_ADDRESS = '0xE27431FACb1F093BA164c9F86B3f484b39ad54CC';
const LIT_ADDRESS = '0xF2C6C1AA2bf8ec40F564Ea8A483F64907ea37A3F';

const useStyles = makeStyles((theme) => ({
    networkChip: {
        borderRadius: "50%",
        marginLeft: 30,
        position: 'absolute',
        top: 42,
        height: 16,
        width: 16,
        display: 'inline-block',
        backgroundSize: 'cover',
        '&.fuse': {
            backgroundImage: `url('https://coineus.app/assets/tokens/fuse/logo.png')`,
        },
        '&.avax': {
            backgroundImage: `url('https://coineus.app/assets/tokens/avax/logo.png')`,
        },
        '&.bnb': {
            backgroundImage: `url('https://coineus.app/assets/tokens/bnb/logo.png')`,
        },
        '&.eth': {
            backgroundImage: `url('https://coineus.app/assets/tokens/eth/logo.png')`,
        }
    }
}));

export default function Faucets() {

    const classes = useStyles();

    const {wallet, rpc} = useSelector(state => state.coineus);
    const web3FUSE = new Web3(rpc.fuse);
    const ceusone_faucet_contract = new web3FUSE.eth.Contract(faucet_abi, CEUSONE_FAUCET_ADDRESS);
    const [ceusoneFaucet, setCeusoneFaucet] = useState({});

    const getInfo = () => {

        if (!wallet.address) {
            return;
        }

        const calls = [
            ceusone_faucet_contract.methods.getClaimableAmount().call,
            ceusone_faucet_contract.methods.getMinAmountToClaim().call,
            ceusone_faucet_contract.methods.getMinAmountForFastpass().call,
            ceusone_faucet_contract.methods.getStatsByAddress(wallet.address).call,
            ceusone_faucet_contract.methods.getClaimPaused().call,
        ]

        makeBatchRequest(web3FUSE, calls).then(resp => {

            const [
                claimable,
                minAmountToClaim,
                minAmountFastpass,
                stats,
                paused
            ] = resp;

            setCeusoneFaucet({
                claimable,
                minAmountToClaim,
                minAmountFastpass,
                stats,
                paused
            });
        });
    }

    useEffect(() => {
        getInfo();
        const interval = setInterval(getInfo, 30000)
        return () => clearInterval(interval);
    }, [wallet.address]);


    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>

        <Grid item xs={12}>
            <Paper>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={`https://coineus.app/assets/tokens/fuse/${LIT_ADDRESS}/logo.png`} />
                        <span className={clsx(classes.networkChip, 'fuse')}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={CoineusCryptoFormat(wallet.balances.fuse[LIT_ADDRESS])}
                        secondary={`Balance`}
                    />
                </ListItem>
            </Paper>
            <Divider style={{marginTop: 10}}>Faucets</Divider>
        </Grid>


        <FacuetDetails
            faucet={CEUSONE_FAUCET_ADDRESS}
            network="fuse"
            tokenSymbol="CEUSONE"
            tokenDecimals={18}
            tokenContract={CEUSONE_ADDRESS}
            details={ceusoneFaucet}
            litAddress={LIT_ADDRESS}
            link="ceusone"
        />

    </Grid>
}

function FacuetDetails(props) {

    const classes = useStyles();
    const {wallet} = useSelector(state => state.coineus);

    const {faucet, network, tokenSymbol, tokenDecimals, tokenContract, details, litAddress, link} = props;

    const [pending, setPending] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const claim = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(faucet_abi, faucet);

            contract.methods
                .claim()
                .send({
                    from: wallet.address,
                    gasPrice: 10000000000
                })
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    setPending(false);
                });
        }
    }

    //update disabled status
    useEffect(() => {

        if (!wallet.address) {
            setDisabled(true)
            return;
        }

        if (CHAIN_IDS_TO_NAMES[wallet.chain] !== network) {
            setDisabled(true)
            return;
        }

        if (details.paused) {
            setDisabled(true);
            return;
        }

        if (wallet.balances[network][litAddress] <
            formatWeiToNumber(details.minAmountToClaim, tokenDecimals)) {

            setDisabled(true);
            return;
        }

        const now = (new Date()).getTime();
        if (details && details.stats && details.stats.timeCanClaim > parseInt(now / 1000)) {
            setDisabled(true);
            return true;
        }

        setDisabled(false);

    }, [details])



    const [claimTime, setClaimTime] = useState(null);
    useEffect(() => {

        if(!details.stats)return;

        setClaimTime(timeToNextClaim(details.stats.timeCanClaim));
        const interval = setInterval(() => {
            setClaimTime(timeToNextClaim(details.stats.timeCanClaim));
        }, 1000);
        return () => clearInterval(interval);
    }, [details])

    return <Grid item xs={12}>
        <Paper>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={`https://coineus.app/assets/tokens/${network}/${tokenContract}/logo.png`} />
                        <span className={clsx(classes.networkChip, network)}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={CoineusCryptoFormat(formatWeiToNumber(details.claimable, tokenDecimals))}
                        secondary={`${tokenSymbol} Claimable`}
                    />
                    <ListItemSecondaryAction>

                        {
                            claimTime === 0 ?
                                <IconButton onClick={claim} disabled={disabled} color="primary">
                                    <PaidIcon/>
                                </IconButton>
                                :
                                <Typography>{claimTime}</Typography>
                        }

                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={`https://coineus.app/assets/tokens/${network}/${litAddress}/logo.png`} />
                        <span className={clsx(classes.networkChip, network)}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={CoineusCryptoFormat(formatWeiToNumber(details.minAmountToClaim, tokenDecimals))}
                        secondary={`Hold to claim`}
                    />
                    <ListItemText
                        primary={CoineusCryptoFormat(formatWeiToNumber(details.minAmountFastpass, tokenDecimals))}
                        secondary={`Hold for Fastpass`}
                    />
                </ListItem>
                {
                    link && <ListItem>
                        <Button variant="contained" fullWidth to={`/faucet/${link}`} component={RouterLink}>View</Button>
                    </ListItem>
                }
            </List>
        </Paper>
        <CoineusLoading open={pending} label="Transaction Pending"/>
    </Grid>
}

function timeToNextClaim(d) {

    // time to claim is less than current
    if(d*1000 > (new Date()).getTime()){
        return msToHMS(parseInt(d*1000) - (new Date()).getTime());
    }

    return 0;

}

function msToHMS(ms) {
    let h = ms / 3.6e6 | 0;
    let m = (ms % 3.6e6) / 6e4 | 0;
    let s = (ms % 6e4) / 1e3 | 0;
    return `${h}:${('' + m).padStart(2, '0')}:${('' + s).padStart(2, '0')}`;
}