import {useSelector} from "react-redux";
import {
    Button,
    Dialog,
    Grid,
    ListItem,
    ListItemText,
    Paper,
    IconButton,
    List,
    Tabs,
    Tab,
    Box,
    FormControl,
    TextField, InputAdornment,
} from "@mui/material";

import Web3 from "web3";
import wfuse_abi from '../abis/wavax_abi.json';

import {useState} from "react";

import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';

import {toWei} from "../utils/format";
import CoineusLoading from "../components/CoineusLoading";
import {CoineusCryptoFormat} from "../utils/currency_format";

const WFUSE_CONTRACT_ADDRESS = "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629";

export default function WFUSEDialog(props) {

    const {wallet} = useSelector(state => state.coineus);

    const {onSuccess} = props;

    const [open, setOpen] = useState(false);
    const [pending, setPending] = useState(false);
    const [amount, setAmount] = useState("");
    const [action, setAction] = useState('deposit');

    const deposit = async () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(wfuse_abi, WFUSE_CONTRACT_ADDRESS);

            contract.methods.deposit()
                .send({from: wallet.address, value: toWei(amount), gasPrice: 10000000000})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setPending(false)
                })
                .then(function (receipt) {
                    onSuccess();
                    setAmount('');
                    setOpen(false);
                    setPending(false);
                });
        }
    }

    const withdraw = async () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const contract = new web3.eth.Contract(wfuse_abi, WFUSE_CONTRACT_ADDRESS);


            contract
                .methods
                .allowance(wallet.address, WFUSE_CONTRACT_ADDRESS)
                .call((err, resp) => {

                    if (err) return;

                    if ((resp / (10 ** 18)) < amount) {
                        contract
                            .methods
                            .approve(WFUSE_CONTRACT_ADDRESS, toWei(amount))
                            .send({from: wallet.address, gasPrice: 10000000000})
                            .once('transactionHash', function (tx) {
                                setPending(true)
                            })
                            .on('error', function (error) {
                                setPending(false)
                            })
                            .then(function (receipt) {
                                setPending(false)
                                withdraw()
                            });
                    } else {
                        withdraw()
                    }
                })

            const withdraw = () => {
                contract.methods.withdraw(toWei(amount))
                    .send({from: wallet.address, gasPrice: 10000000000})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setPending(false)
                    })
                    .then(function (receipt) {
                        onSuccess();
                        setAmount('');
                        setOpen(false);
                        setPending(false);
                    });
            }
        }
    }

    return <>

        <IconButton
            disabled={
                wallet.chain !== 122
            }
            edge="end"
            onClick={() => setOpen(true)}
            color="primary"
        >
            <SwapVerticalCircleIcon/>
        </IconButton>

        <Dialog open={open} fullWidth maxWidth="sm">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Tabs
                            variant="fullWidth"
                            value={action}
                            onChange={(ev, val) => {
                                setAction(val)
                                setAmount('')
                            }}
                        >
                            <Tab id="deposit" value="deposit" label="Deposit"/>
                            <Tab id="withdrawal" value="withdrawal" label="Withdrawal"/>
                        </Tabs>
                        {
                            action === "deposit" && <Box style={{padding: 20}}>
                                <List>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemText
                                            secondary="Balance"
                                        />
                                        <ListItemText
                                            secondary={`${CoineusCryptoFormat(wallet.balances.fuse.native)} FUSE`}
                                            secondaryTypographyProps={{textAlign: 'right'}}
                                        />
                                    </ListItem>
                                </List>
                                <FormControl fullWidth style={{marginBottom: 20}}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        value={amount}
                                        placeholder="Enter Amount"
                                        onChange={(ev) => {
                                            setAmount(ev.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Button variant="contained" onClick={() => {
                                                    setAmount(wallet.balances.fuse.native - .1)
                                                }
                                                }>MAX</Button>
                                            </InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <Button fullWidth variant="contained"
                                        disabled={!amount || amount > wallet.balances.fuse.native}
                                        onClick={deposit}>Deposit</Button>
                            </Box>
                        }

                        {
                            action === "withdrawal" && <Box style={{padding: 20}}>
                                <List>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemText
                                            secondary="Balance"
                                        />
                                        <ListItemText
                                            secondary={`${CoineusCryptoFormat(wallet.balances.fuse[WFUSE_CONTRACT_ADDRESS])} WFUSE`}
                                            secondaryTypographyProps={{textAlign: 'right'}}
                                        />
                                    </ListItem>
                                </List>
                                <FormControl fullWidth style={{marginBottom: 20}}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        value={amount}
                                        placeholder="Enter Amount"
                                        onChange={(ev) => {
                                            if (ev.target.value > wallet.balances.fuse[WFUSE_CONTRACT_ADDRESS]) return;
                                            setAmount(ev.target.value)
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <Button variant="contained" onClick={() => {
                                                    setAmount(wallet.balances.fuse[WFUSE_CONTRACT_ADDRESS])
                                                }
                                                }>MAX</Button>
                                            </InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                <Button fullWidth variant="contained"
                                        disabled={!amount || amount > wallet.balances.fuse[WFUSE_CONTRACT_ADDRESS]}
                                        onClick={withdraw}>Withdrawal</Button>
                            </Box>
                        }
                        <Box style={{padding: 20, paddingTop: 0}}>

                            <Button fullWidth
                                    onClick={() => setOpen(false)}>Cancel</Button>
                        </Box>

                    </Paper>
                </Grid>

            </Grid>
        </Dialog>

        <CoineusLoading open={pending} label="Transaction Pending"/>
    </>
}
