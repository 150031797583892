import Web3 from "web3";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

import farm_abi from "../abis/MultiRewards.json";
import ierc20_abi from "../abis/IERC20.json";
import {toWei} from "../utils/format";
import CoineusLoading from "../components/CoineusLoading";
import {
    Alert,
    Avatar, AvatarGroup,
    Button,
    FormControl,
    Grid, InputAdornment,
    List,
    ListItem, ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper, Skeleton, Snackbar,
    TextField, Typography
} from "@mui/material";
import {CoineusCryptoFormat} from "../utils/currency_format";
import {NetworkButton} from "../components/Coineus";
import {CHAIN_IDS} from "../constants";
import PercentageArc from "../components/PercentageArc";
import RewardDetails from "../components/RewardsDetails";
import useFarmInfo from "../hooks/useFarmInfo";
import {useParams} from "react-router-dom";
import getUserBalances from "../utils/get_balances";
import RewardsSummary from "../components/RewardsSummary";
import RewardsAllTime from "../components/RewardsAllTime";


export default function FarmDetails() {

    const {contract_address, network} = useParams();

    const {wallet, rpc} = useSelector(state => state.coineus);

    const {farmInfo, loading} = useFarmInfo(contract_address, network);

    const [pending, setPending] = useState(false);
    const [amount, setAmount] = useState("");
    const [rewards, setRewards] = useState({});

    const [snackBarError, setSnackBarError] = useState(null);

    // get rewards all-time
    useEffect(()=>{

        if(!wallet.address)return;

        const web3 = new Web3(rpc.fuse);
        const farm_contract = new web3.eth.Contract(farm_abi, contract_address);

        farm_contract
            .getPastEvents('RewardPaid', {
                fromBlock: 23985459,
                filter: {user: [wallet.address]}
            })
            .then( events => {
                let r = {};
                events.map( ev => {
                    if(!r[ev.returnValues.rewardsToken]){
                        r[ev.returnValues.rewardsToken] = 0;
                    }
                    r[ev.returnValues.rewardsToken] += (ev.returnValues.reward/1);
                    return ev;
                })
                setRewards(r)
            })

    }, [wallet.address]);

    /**
     * addRewards(rewardToken, managerAddress, duration) - add reward token
     * 2592000 = 30 days
     * 604800 = 7 days
     */
    const stake = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);

            const stake_token_contract = new web3.eth.Contract(ierc20_abi, farmInfo.stakingToken);
            const farm_contract = new web3.eth.Contract(farm_abi, contract_address);

            stake_token_contract
                .methods
                .allowance(wallet.address, contract_address)
                .call((err, resp) => {

                    if (err) {
                        setSnackBarError(err.message);
                        return;
                    }

                    if (parseInt(resp) < parseInt(toWei(amount, 18))) {
                        stake_token_contract
                            .methods
                            .approve(contract_address, toWei(amount, 18))
                            .send({from: wallet.address})
                            .once('transactionHash', function (tx) {
                                setPending(true)
                            })
                            .on('error', function (error) {
                                setSnackBarError(error.receipt.error);
                                setPending(false)
                            })
                            .then(function (receipt) {
                                setPending(false)
                                stake()
                            });
                    } else {
                        stake()
                    }
                })

            const stake = () => {
                farm_contract.methods.stake(toWei(amount, 18))
                    .send({from: wallet.address, gas: 700000})
                    .once('transactionHash', function (tx) {
                        setPending(true)
                    })
                    .on('error', function (error) {
                        setSnackBarError(error.receipt.error);
                        setPending(false)
                    })
                    .then(function (receipt) {
                        getUserBalances(wallet.address);
                        setPending(false);
                    });
            }
        }
    }

    const getReward = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const farm_contract = new web3.eth.Contract(farm_abi, contract_address);
            farm_contract.methods.getReward()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setSnackBarError(error.receipt.error);
                    setPending(false)
                })
                .then(function (receipt) {
                    getUserBalances(wallet.address);
                    setPending(false);
                });
        }
    }

    const exit = () => {
        if (wallet.address) {
            const web3 = new Web3(window.provider);
            const farm_contract = new web3.eth.Contract(farm_abi, contract_address);
            farm_contract.methods.exit()
                .send({from: wallet.address})
                .once('transactionHash', function (tx) {
                    setPending(true)
                })
                .on('error', function (error) {
                    setSnackBarError(error.receipt.error);
                    setPending(false)
                })
                .then(function (receipt) {
                    getUserBalances(wallet.address);
                    setPending(false);
                });
        }
    }

    if (loading) {
        return <Grid item xs={12}>
            <Paper style={{padding: 20}}>
                <Skeleton/>
                <Skeleton/>
            </Paper>
        </Grid>
    }

    return <Grid container spacing={2} style={{marginTop: -5, paddingBottom: 20}}>
        <Grid item xs={12}>
            <Paper>
                <List>
                    <ListItem>
                        <ListItemAvatar style={{marginRight: 10}}>
                            <AvatarGroup>
                                <Avatar
                                    src={`https://coineus.app/assets/tokens/${network}/${farmInfo.token0}/logo.png`}/>
                                <Avatar
                                    src={`https://coineus.app/assets/tokens/${network}/${farmInfo.token1}/logo.png`}/>
                            </AvatarGroup>
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                            <Button href={`https://app.voltage.finance/add/${farmInfo.token0}/${farmInfo.token1}`}
                                    target="_blank">Add LP</Button>
                        </ListItemSecondaryAction>
                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={CoineusCryptoFormat(farmInfo.totalStaked)}
                            secondary="Total Staked"
                        />
                        <ListItemText
                            primary={CoineusCryptoFormat(farmInfo.reserves.reserve0)}
                            secondary={farmInfo.tokenInfo0.symbol}
                        />
                        <ListItemText
                            primary={CoineusCryptoFormat(farmInfo.reserves.reserve1)}
                            secondary={farmInfo.tokenInfo1.symbol}
                        />

                    </ListItem>

                    <ListItem>
                        <ListItemText
                            primary={farmInfo.apy.toFixed(2) + "%"}
                            secondary="Est. APY"
                        />
                        <ListItemText
                            primary={CoineusCryptoFormat(farmInfo.stakedAmount)}
                            secondary="My Staked Amount"
                        />

                        <div style={{marginTop: -15}}>
                            <PercentageArc
                                value={farmInfo.stakedAmount}
                                total={farmInfo.totalStaked}
                                height={50}
                                label="MY STAKE SHARE"
                            />
                        </div>
                    </ListItem>

                    <ListItem>
                        <NetworkButton network={CHAIN_IDS.FUSE}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => exit()}>Withdraw</Button>
                        </NetworkButton>
                    </ListItem>

                    <ListItem>
                        <FormControl fullWidth style={{marginBottom: 20, marginTop: 20}}>
                            <TextField
                                autoComplete="off"
                                type="number"
                                value={amount}
                                placeholder="Enter Amount"
                                onChange={(ev) => {
                                    setAmount(ev.target.value)
                                }}
                                helperText={`LP Balance: ${farmInfo.stake_token_balance}`}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Button variant="contained" onClick={() => {
                                            setAmount(farmInfo.stake_token_balance)
                                        }
                                        }>MAX</Button>
                                    </InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </ListItem>

                    <ListItem>
                        <NetworkButton network={CHAIN_IDS.FUSE}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => stake()}>STAKE</Button>
                        </NetworkButton>
                    </ListItem>

                </List>
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <Paper>
                <List>
                    {
                        farmInfo.rewardTokens.map(rt => {
                            return <RewardsSummary
                                key={rt.contract_address}
                                reward_token={rt}
                                network={network}
                            />
                        })
                    }
                    <ListItem>
                        <NetworkButton network={CHAIN_IDS.FUSE}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => getReward()}>Claim Rewards</Button>
                        </NetworkButton>
                    </ListItem>
                </List>
            </Paper>
        </Grid>

        <Grid item xs={12}>
            <Paper>
                <Typography style={{fontWeight: 700, padding: '20px 0 0 20px'}}>Rewards Claimed All-time</Typography>
                <List dense>
                    {
                        farmInfo.rewardTokens.map(rt => {
                            return <RewardsAllTime
                                key={rt.contract_address}
                                reward_token={rt}
                                network={network}
                                rewards={rewards}
                            />
                        })
                    }
                </List>
            </Paper>
        </Grid>

        {
            farmInfo.rewardTokens.map(rt => {
                return <RewardDetails
                    key={rt.contract_address}
                    reward_token={rt}
                    network={network}
                    farmContract={contract_address}
                />
            })
        }


        <Snackbar
            open={snackBarError != null}
            onClose={() => setSnackBarError(null)}
            autoHideDuration={10000}
        >
            <Alert severity="error" sx={{width: '100%'}} variant="filled">{snackBarError}</Alert>
        </Snackbar>

        <CoineusLoading open={pending} label="Transaction Pending"/>
    </Grid>
}